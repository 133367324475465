<div class="container-fluid py-5 my-5" style="min-height: 180vh;">
    <div class="row d-flex justify-content-center ">
        <div class="col-md-8 col-11 text-center px-1 ">
            <h3>PLANIFICACIÓN DE CONCURSOS 2023</h3>
        </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center my-5">
        <div class="col-md-5 col-11 text-center px-1  mt-3">
            <h4>PRIMER GRUPO: AGOSTO 2023</h4>
        </div>
        <div class="col-11 my-3">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr style="background: #5b9bd5;">
                            <th class="text-center" scope="col">Nº</th>
                            <th class="text-center" scope="col">VACANTE</th>
                            <th class="text-center" scope="col">PARTIDA</th>
                            <th class="text-center" scope="col">DENOMINACION DEL PUESTO </th>
                            <th class="text-center" scope="col">UNIDAD ADMINISTRATIVA </th>
                            <th class="text-center" scope="col">GRUPO OCUPACIONAL </th>
                            <th class="text-center" scope="col">GRADO </th>
                            <th class="text-center" scope="col">RMU </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="text-center" scope="row">1</th>
                            <td class="text-center">1</td>
                            <td class="text-center">121.51.01.05</td>
                            <td class="text-center">ESPECIALISTA DE COMPRAS PUBLICAS</td>
                            <td class="text-center">DIRECCION ADMINISTRATIVA</td>
                            <td class="text-center">SP7</td>
                            <td class="text-center">13</td>
                            <td class="text-center"> $1.676,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">2</th>
                            <td class="text-center">1</td>
                            <td class="text-center">131.51.01.05</td>
                            <td class="text-center">ESPECIALISTA DE RESPONSABILIDAD SOCIAL</td>
                            <td class="text-center">DIRECCION COMERCIAL</td>
                            <td class="text-center">SP6</td>
                            <td class="text-center">12</td>
                            <td class="text-center">$1.412,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">3</th>
                            <td class="text-center">1</td>
                            <td class="text-center">131.51.01.05</td>
                            <td class="text-center">ESPECIALISTA DE MEDIDORES </td>
                            <td class="text-center">DIRECCION COMERCIAL</td>
                            <td class="text-center">SP7</td>
                            <td class="text-center">13</td>
                            <td class="text-center">$1.676,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">4</th>
                            <td class="text-center">1</td>
                            <td class="text-center">121.51.01.05</td>
                            <td class="text-center">TRABAJADOR/A SOCIAL </td>
                            <td class="text-center">UNIDAD DE SEGURIDAD Y SALUD OCUPACIONAL</td>
                            <td class="text-center">SP3</td>
                            <td class="text-center">9</td>
                            <td class="text-center">$986,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">5</th>
                            <td class="text-center">2</td>
                            <td class="text-center">131.51.01.05</td>
                            <td class="text-center">PROMOTOR SOCIAL </td>
                            <td class="text-center">DIRECCION COMERCIAL</td>
                            <td class="text-center">SPA4</td>
                            <td class="text-center">6</td>
                            <td class="text-center">$733,00 </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">6</th>
                            <td class="text-center">1</td>
                            <td class="text-center">141.71.01.05</td>
                            <td class="text-center">TECNICO DE MANTENIMIENTO Y LIMPIEZA </td>
                            <td class="text-center">UNIDAD DE ALCANTARILLADO</td>
                            <td class="text-center">SP5</td>
                            <td class="text-center">11</td>
                            <td class="text-center">$1.212,00 </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">7</th>
                            <td class="text-center">1</td>
                            <td class="text-center">121.51.01.05</td>
                            <td class="text-center">ASISTENTE ADMINISTRATIVO/A 2 DE TALENTO HUMANO </td>
                            <td class="text-center">UNIDAD ADMINISTRATIVA DE TALENTO HUMANO</td>
                            <td class="text-center">SPA3</td>
                            <td class="text-center">5</td>
                            <td class="text-center">$675,00 </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">8</th>
                            <td class="text-center">1</td>
                            <td class="text-center">142.61.05.10</td>
                            <td class="text-center">TÉCNICO INDUSTRIAL </td>
                            <td class="text-center">UNIDAD DE PRODUCCION </td>
                            <td class="text-center">SP5</td>
                            <td class="text-center">11</td>
                            <td class="text-center">$1.212,00 </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center my-5">
        <div class="col-md-5 col-11 text-center px-1 mt-3">
            <h4>SEGUNDO GRUPO: SEPTIEMBRE 2023</h4>
        </div>
        <div class="col-11 my-3">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr style="background: #5b9bd5;">
                            <th class="text-center" scope="col">Nº</th>
                            <th class="text-center" scope="col">VACANTE</th>
                            <th class="text-center" scope="col">PARTIDA</th>
                            <th class="text-center" scope="col">DENOMINACION DEL PUESTO </th>
                            <th class="text-center" scope="col">UNIDAD ADMINISTRATIVA </th>
                            <th class="text-center" scope="col">GRUPO OCUPACIONAL </th>
                            <th class="text-center" scope="col">GRADO </th>
                            <th class="text-center" scope="col">RMU </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="text-center" scope="row">1</th>
                            <td class="text-center">1</td>
                            <td class="text-center">121.51.01.05</td>
                            <td class="text-center">ANALISTA 3 DE TALENTO HUMANO</td>
                            <td class="text-center">UNIDAD ADMINISTRATIVA DE TALENTO HUMANO</td>
                            <td class="text-center">SP3</td>
                            <td class="text-center">9</td>
                            <td class="text-center">$986,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">2</th>
                            <td class="text-center">1</td>
                            <td class="text-center">121.51.01.05</td>
                            <td class="text-center">ESPECIALISTA DE TECNOLOGIA DE LA INFORMACIÓN Y COMUNICACIÓN</td>
                            <td class="text-center">DIRECCION ADMINISTRATIVA</td>
                            <td class="text-center">SP7</td>
                            <td class="text-center">13</td>
                            <td class="text-center"> $1.676,00 </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">3</th>
                            <td class="text-center">3</td>
                            <td class="text-center">141.71.01.05</td>
                            <td class="text-center">TECNICO DE AGUA POTABLE</td>
                            <td class="text-center">UNIDAD DE AGUA POTABLE</td>
                            <td class="text-center">SP5</td>
                            <td class="text-center">11</td>
                            <td class="text-center">$1.212,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">4</th>
                            <td class="text-center">1</td>
                            <td class="text-center">142.61.01.05</td>
                            <td class="text-center">TÉCNICO ELECTRICO</td>
                            <td class="text-center">UNIDAD DE PRODUCCION </td>
                            <td class="text-center">SP5</td>
                            <td class="text-center">11</td>
                            <td class="text-center">$1.212,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">5</th>
                            <td class="text-center">1</td>
                            <td class="text-center">141.71.01.05</td>
                            <td class="text-center">ESPECIALISTA DE GESTIÓN AMBIENTAL</td>
                            <td class="text-center">DIRECCION DE OPERACIÓN Y MANTENIMIETO</td>
                            <td class="text-center">SP7</td>
                            <td class="text-center">13</td>
                            <td class="text-center">$1.676,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">6</th>
                            <td class="text-center">2</td>
                            <td class="text-center">151.51.01.05</td>
                            <td class="text-center">CAJERO/A</td>
                            <td class="text-center">UNIDAD DE TESORERIA</td>
                            <td class="text-center">SPA3</td>
                            <td class="text-center">5</td>
                            <td class="text-center">$675,00 </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">7</th>
                            <td class="text-center">1</td>
                            <td class="text-center">131.51.05.10</td>
                            <td class="text-center">TECNICO DE MEDIDORES</td>
                            <td class="text-center">UNIDAD DE MEDIDORES</td>
                            <td class="text-center">SP5</td>
                            <td class="text-center">11</td>
                            <td class="text-center">$1.212,00 </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">8</th>
                            <td class="text-center">1</td>
                            <td class="text-center">121.51.01.05</td>
                            <td class="text-center">MEDICO OCUPACIONAL</td>
                            <td class="text-center">UNIDAD DE SEGURIDAD Y SALUD OCUPACIONAL</td>
                            <td class="text-center">SP6</td>
                            <td class="text-center">12</td>
                            <td class="text-center">$1.412,00</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>


    
    <div class="row d-flex justify-content-center align-items-center my-5">
        <div class="col-md-8 col-11 text-center px-1 mt-3">
            <h4>SEGUNDO GRUPO REPLANIFICADO NOVIEMBRE 2023</h4>
        </div>
        <div class="col-11 my-3">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr style="background: #5b9bd5;">
                            <th class="text-center" scope="col">Nº</th>
                            <th class="text-center" scope="col">VACANTE</th>
                            <th class="text-center" scope="col">PARTIDA</th>
                            <th class="text-center" scope="col">DENOMINACION DEL PUESTO </th>
                            <th class="text-center" scope="col">UNIDAD ADMINISTRATIVA </th>
                            <th class="text-center" scope="col">GRUPO OCUPACIONAL </th>
                            <th class="text-center" scope="col">GRADO </th>
                            <th class="text-center" scope="col">RMU </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="text-center" scope="row">1</th>
                            <td class="text-center">1</td>
                            <td class="text-center">131.51.05.10</td>
                            <td class="text-center">ESPECIALISTA DE CARTERA Y COBRANZA</td>
                            <td class="text-center">DIRECCIÓN COMERCIAL</td>
                            <td class="text-center">SP7</td>
                            <td class="text-center">13</td>
                            <td class="text-center">$1.676,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">2</th>
                            <td class="text-center">1</td>
                            <td class="text-center">121.51.01.05</td>
                            <td class="text-center">MEDICO OCUPACIONAL</td>
                            <td class="text-center">UNIDAD DE SEGURIDAD Y SALUD OCUPACIONAL</td>
                            <td class="text-center">SP6</td>
                            <td class="text-center">12</td>
                            <td class="text-center"> $1.412,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">3</th>
                            <td class="text-center">1</td>
                            <td class="text-center">141.71.05.10</td>
                            <td class="text-center">TECNICO DE AGUAS RESIDUALES</td>
                            <td class="text-center">UNIDAD DE ALCANTARILLADO</td>
                            <td class="text-center">SP5</td>
                            <td class="text-center">11</td>
                            <td class="text-center">$1.212,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">4</th>
                            <td class="text-center">1</td>
                            <td class="text-center">142.61.01.05</td>
                            <td class="text-center">TÉCNICO ELECTRICO</td>
                            <td class="text-center">UNIDAD DE PRODUCCIÓN</td>
                            <td class="text-center">SP5</td>
                            <td class="text-center">11</td>
                            <td class="text-center">$1.212,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">5</th>
                            <td class="text-center">2</td>
                            <td class="text-center">141.71.01.05</td>
                            <td class="text-center">TECNICO DE ALCANTARILLADO</td>
                            <td class="text-center">UNIDAD DE ALCANTARILLADO</td>
                            <td class="text-center">SP5</td>
                            <td class="text-center">11</td>
                            <td class="text-center">$1.212,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">6</th>
                            <td class="text-center">1</td>
                            <td class="text-center">121.51.01.05</td>
                            <td class="text-center">ANALISTA 3 DE TALENTO HUMANO</td>
                            <td class="text-center">UNIDAD ADMINISTRATIVA DE TALENTO HUMANO</td>
                            <td class="text-center">SP3</td>
                            <td class="text-center">9</td>
                            <td class="text-center">$986,00 </td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">7</th>
                            <td class="text-center">1</td>
                            <td class="text-center">141.71.01.05</td>
                            <td class="text-center">ASISTENTE 4 DE GESTIÓN AMBIENTAL</td>
                            <td class="text-center">UNIDAD DE GESTIÓN AMBIENTAL</td>
                            <td class="text-center">SP1</td>
                            <td class="text-center">7</td>
                            <td class="text-center">$817,00</td>
                        </tr>
                        <tr>
                            <th class="text-center" scope="row">8</th>
                            <td class="text-center">1</td>
                            <td class="text-center">151.5.1.05.10</td>
                            <td class="text-center">ASISTENTE 4 DE CONTABILIDAD</td>
                            <td class="text-center">UNIDAD DE CONTABILIDAD</td>
                            <td class="text-center">SP1</td>
                            <td class="text-center">7</td>
                            <td class="text-center">$817,00</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>