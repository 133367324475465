<owl-carousel-o [options]="customOptionsEmpresas" class="ttm-client clients-slide mt-5" *ngIf="customOptionsEmpresas">
    <ng-template carouselSlide>
        <div class="client-box ttm-box-view-boxed-logo box-shadow2">
            <div class="client">
                <a href="https://www.machala.gob.ec/" target="_blank" class="ttm-client-logo-tooltip" data-tooltip="Alcaldía de Machala EP">
                    <img loading="lazy" class="img-fluid" src="/assets/images/optimizadas_machalaep/logos_ep/gad-img.webp" alt="image">
                </a>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="client-box ttm-box-view-boxed-logo box-shadow2">
            <div class="client">
                <a href="http://www.emamep.gob.ec/portal/node/1" target="_blank" class="ttm-client-logo-tooltip" data-tooltip="Aseo Machala EP">
                    <img loading="lazy" class="img-fluid" src="/assets/images/optimizadas_machalaep/logos_ep/aseo-img.webp" alt="image">
                </a>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="client-box ttm-box-view-boxed-logo box-shadow2">
            <div class="client">
                <a href="http://www.mercadosmachala.gob.ec/" target="_blank" class="ttm-client-logo-tooltip" data-tooltip="Mercados Machala EP">
                    <img loading="lazy" class="img-fluid" src="/assets/images/optimizadas_machalaep/logos_ep/mercados-img.webp" alt="image">
                </a>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="client-box ttm-box-view-boxed-logo box-shadow2">
            <div class="client">
                <a href="http://www.movilidadmachala.gob.ec/" target="_blank" class="ttm-client-logo-tooltip" data-tooltip="Movilidad Machala EP">
                    <img loading="lazy" class="img-fluid" src="/assets/images/optimizadas_machalaep/logos_ep/movilidad-img.webp" alt="image">
                </a>
            </div>
        </div>
    </ng-template>

    <ng-template carouselSlide>
        <div class="client-box ttm-box-view-boxed-logo box-shadow2">
            <div class="client">
                <a href="https://saludmachala.gob.ec/" target="_blank" class="ttm-client-logo-tooltip" data-tooltip="Salud Red Municipal EP">
                    <img loading="lazy" class="img-fluid" src="/assets/images/optimizadas_machalaep/logos_ep/salud-img.webp" alt="image">
                </a>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="client-box ttm-box-view-boxed-logo box-shadow2">
            <div class="client">
                <a href="https://www.bomberosmachala.gob.ec/" target="_blank" class="ttm-client-logo-tooltip" data-tooltip="Bomberos Machala EP">
                    <img loading="lazy" class="img-fluid" src="/assets/images/optimizadas_machalaep/logos_ep/bomberos-img.webp" alt="image">
                </a>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="client-box ttm-box-view-boxed-logo box-shadow2">
            <div class="client">
                <a href="https://www.terminalterrestremachala.gob.ec/" target="_blank" class="ttm-client-logo-tooltip" data-tooltip="Terminal Municipal EP">
                    <img loading="lazy" class="img-fluid" src="/assets/images/optimizadas_machalaep/logos_ep/terminal-img.webp" alt="image">
                </a>
            </div>
        </div>
    </ng-template>
</owl-carousel-o>
