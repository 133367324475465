import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataPayment } from 'src/app/model/data-payment';
interface StructureQuery {
  name: string;
  value: string;
}
@Injectable({
  providedIn: 'root',
})
export class DatafastService {
  BASE_URL = 'https://oppwa.com';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    })
  };



  constructor(private http: HttpClient) {}

  concatURL(subURL: string): string {
    return this.BASE_URL + subURL;
  }

  makePostRequest(
    URL: string,
    data: any,
    headers = this.httpOptions
  ): Observable<any> {
    return this.http.post(URL, data, headers);
  }

  makeGetRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.http.get(URL, headers);
  }

  makeDelRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.http.delete(URL, headers);
  }

  makePutRequest(
    URL: string,
    data: any,
    headers = this.httpOptions
  ): Observable<any> {
    return this.http.put(URL, data, headers);
  }

  getCheckoutId(dataPayment: DataPayment): Observable<any> {
    // let body = new URLSearchParams();
    // body.set('entityId', this.dataTest.entityId);
    // body.set('amount', this.dataTest.amount);
    // body.set('currency', this.dataTest.currency);
    // body.set('paymentType', this.dataTest.paymentType);
    this.httpOptions.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer OGFjOWE0Y2Q3ZTcxOGE3YjAxN2U3MzRlZjkwODIwZjR8eUhaRmpGOThlNw==`
    });


    let data =
      this.createQueryString(dataPayment, [
        { name: 'risk', value: '.parameters' },
        { name: 'customParameters', value: '' },
      ]);
    data = data.substring(0, data.length - 1);


    const PATH = this.concatURL('/v1/checkouts');


    /* console.log(PATH);
    console.log(data); */


    // return of([]);
    return this.makePostRequest(PATH, data);
  }

  getResourcePath(id: string): Observable<any> {
    this.httpOptions.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const PATH = this.concatURL(`/v1/checkouts/${id}/payment`);
    return this.makeGetRequest(PATH);
  }

  public getIPAddress(): Observable<any>
  {
    return this.http.get("http://api.ipify.org/?format=json");
  }
  createQueryString(
    object: any,
    structureQuery: StructureQuery[],
    query = '',
    father = ''
  ) {
    Object.keys(object).forEach((key) => {
      if (Array.isArray(object[key])) {
        object[key].forEach((value, i) => {
          query = this.createQueryString(
            value,
            structureQuery,
            query,
            `${father ? father + '.' : ''}${key}[${i}]`
          );
        });
      } else if (typeof object[key] === 'object') {
        query = this.createQueryString(
          object[key],
          structureQuery,
          query,
          `${father ? father + '.' : ''}${key}`
        );
      } else {
        const addValue =  structureQuery.find((value) => value.name === father);
        if(addValue) {
          query += `${father ? father + `${addValue.value}[` : ''}${key}]=${encodeURI(
            object[key]
          )}&`;
        } else {
          query += `${father ? father + '.' : ''}${key}=${encodeURI(
            object[key]
          )}&`;
        }
      }
    });
    return query;
  }
}
