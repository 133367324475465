<ngx-spinner></ngx-spinner>
<div class="ttm-page-title-row">
  <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title-box ttm-textcolor-white">
          <div class="page-title-heading">
            <h1 class="title">Consulta de reclamos</h1>
          </div>
          <div class="breadcrumb-wrapper">
            <span>
              <a title="Homepage" routerLink="home"
                ><i class="ti ti-home"></i
              ></a>
            </span>
            <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
            <span
              ><span style="color: #0095d9; font-weight: bold"
                >Consulta de reclamos</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="site-main single">
  <section
    class="ttm-row checkout-section ttm-bgcolor-grey break-991-colum clearfix"
  >
    <div class="container">
      <form name="checkout" method="post" class="checkout row" action="#">
        <div class="col-lg-12">
          <div class="billing-fields">
            <h3>Consulta de estado de reclamos</h3>
            <p class="form-row">
              <label
                >Ingresa el ID del reclamo para conocer el estado del
                mismo.&nbsp;<abbr class="required" title="required"
                  >*</abbr
                ></label
              >
            </p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="billing-fields">
            <p class="form-row">
              <select (ngModel)="(selectedOption)" id="selectIdentificadorD">
                <option *ngFor="let item of options">
                  {{ item.name }}
                </option>
              </select>
            </p>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="billing-fields">
            <p class="form-row">
              <input
                type="text"
                class="input-tex"
                name="billing_first_name"
                placeholder="ID del reclamo o número de cédula*"
                value=""
                id="ipt_identificador"
              />
            </p>
            <p>
              <small>
                ¿No recuerdas el número de cuenta?
                <a
                  (click)="guiaNroCuenta()"
                  style="text-decoration: underline; font-weight: bold"
                  >Haz click AQUÍ.</a
                >
                Te recordamos donde puedes revisarlo
              </small>
            </p>
          </div>
        </div>

        <div class="col-lg-2">
          <div class="form-row place-order">
            <button
              type="submit"
              class="button"
              name="checkout_place_order"
              id="place_order"
              value="Place order"
              data-value="Place order"
              (click)="getAllData()"
            >
              Consultar
            </button>
          </div>
        </div>
      </form>
      <div
        *ngIf="identificaciones"
        style="
          background-color: white;
          padding: 1em;
          margin-top: 1em;
          margin-bottom: 1em;
          border-radius: 15px;
        "
      >
        <ul>
          <li *ngFor="let identificacion of identificaciones">
            <h5 style="color: #0095d9">
              Estimado Usuario/a con {{ identificacion.tipoid }}:
              {{ identificacion.id }},
            </h5>
            A continuacion, te mostramos los reclamos domiciliarios y/o de
            ciudad que has realizado, tu cooperacion es muy importante para
            nosotros. El buen uso del espacio de crear reclamos nos permite
            gestionar los recursos necesarios para solventar aquellos validos y
            urgentes. <strong>¡JUNTOS HACIENDO HISTORIA!</strong>

            <div class="row checkout p-0">
              <div class="col-lg-12">
                <h3 id="order_review_heading" class="mb-2">Reclamo</h3>

                <div class="color-states">
                  <span class="bg-primary"></span> Ingresado
                  <span class="bg-success"></span> Aceptado
                  <span class="bg-danger"></span> Rechazado
                  <span class="bg-secondary"></span> Estado Actual
                </div>
                <div
                  id="order_review"
                  class="checkout-review-order table-responsive"
                >
                  <table
                    class="
                      shop_table
                      checkout-review-order-table
                      table-state-claim
                    "
                    *ngIf="reclamos && reclamos.length"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Tipo</th>
                        <th scope="col">Categoria</th>
                        <th scope="col">Observaciones</th>
                        <th scope="col">Estado Actual</th>
                        <th scope="col">Historial Estados</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let data of reclamos
                            | paginate: { itemsPerPage: 5, currentPage: page }
                        "
                      >
                        <td scope="row">{{ data.tipo }}</td>
                        <td scope="row">{{ data.categoria }}</td>
                        <td scope="row">
                          <span *ngIf="data.observaciones"
                            ><a
                              class="p-link under"
                              style="text-decoration: underline"
                              (click)="abrirObservacion(data.observaciones)"
                              >ver</a
                            ></span
                          >
                          <span></span>
                        </td>
                        <td>
                          {{ data.estados[data.estados.length - 1].estado }}
                        </td>
                        <td class="p-0">
                          <div class="d-flex" style="height: 2rem">
                            <div
                              [ngClass]="changeBackground(item.estado)"
                              style="width: 25%; height: 100%; cursor: pointer"
                              [title]="changeTitle(item.estado)"
                              *ngFor="let item of data.estados"
                            ></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h1
                    class="text-center mt-4"
                    *ngIf="!reclamos || !reclamos.length"
                  >
                    No existe registros
                  </h1>
                  <pagination-controls
                    previousLabel="Anterior"
                    nextLabel="Siguiente"
                    (pageChange)="page = $event"
                    *ngIf="reclamos && reclamos.length"
                  ></pagination-controls>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</div>
<!-- <app-sliderservices></app-sliderservices> -->
<section class="ttm-row blog2-section clearfix">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="section-title with-desc title-style-center_text clearfix">
          <div class="title-header">
            <h5>SERVICIOS ONLINE</h5>
            <h2 class="title">GESTIONES A UN CLICK DE DISTANCIA</h2>
          </div>
          <div class="title-desc">
            Realiza gestiones, consultas y pagos desde la comodidad de tu hogar.
            <br />
            Revisa nuestra bitácora de servicios en línea.
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="">
      <app-slidertramites></app-slidertramites>
    </div>
  </div>
</section>
<section class="ttm-row services2-section bg-img4 clearfix">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="section-title with-desc title-style-center_text clearfix">
          <div class="title-header">
            <h5>NECESITO HACER UN TRÁMITE PRESENCIAL</h5>
            <h2 class="title">PROCESOS INTERNOS</h2>
          </div>
          <div class="title-desc">
            Encuentra en esta sección los pasos a seguir en procesos internos de
            la empresa, te indicaremos como crear una cuenta, como pedir una
            instalación de medidor, como actualizar datos, entre otros procesos.
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="">
      <app-sliderservices></app-sliderservices>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="mt-40 text-center">
          <h6 class="ttm-textcolor-grey">
            Revisa todos los procesos que puedes realizar
            <strong
              ><u
                ><a class="ttm-textcolor-dark" routerLink="/servicios"
                  >en esta sección</a
                ></u
              ></strong
            >
          </h6>
        </div>
      </div>
    </div>
  </div>
</section>
