<ngx-spinner></ngx-spinner>
<div class="ttm-page-title-row">
    <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="title-box ttm-textcolor-white">
                    <div class="page-title-heading">
                        <h1 class="title">Licitación</h1>
                    </div>
                    <div class="breadcrumb-wrapper">
                        <span>
                            <a title="Homepage" routerLink="home"><i class="ti ti-home"></i></a>
                        </span>
                        <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
                        <span><span>Licitación</span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="site-main">
    <section class="ttm-row">
        <div class="container">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="section-title with-desc title-style-center_text mb-55 clearfix">
                        <div class="title-header">
                            <h2 class="title">Proceso de licitación internacional</h2>
                        </div>
                        <div class="title-desc">Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry has been the industry’s standard dummy when an unknown printer.</div>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="single-img mb-35">
                        <img loading="lazy" class="img-fluid" src="/assets/images/machalaep/licitacion_title.png" alt="">
                        <div class="ttm-video-btn text-center">
                            <a class="ttm-play-btn ttm_prettyphoto" href="https://youtu.be/rJnEf1OtgoY" target="_blank">
                                <span class="ttm-btn-play"><i class="fa fa-play"></i></span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="accordion">
                        <div class="toggle ttm-style-classic ttm-toggle-title-border">
                            <div class="toggle-title"><a data-toggle="collapse" data-parent="#accordion"
                                    href="#collapseOne">Obra “Mancomunidad La Esperanza”</a>
                            </div>
                            <div class="toggle-content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry.</p>
                            </div>
                        </div>
                        <div class="toggle ttm-style-classic ttm-toggle-title-border">
                            <div class="toggle-title"><a data-toggle="collapse" data-parent="#accordion"
                                    href="#collapseTwo">Caracteristicas de la obra</a></div>
                            <div class="toggle-content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry’s standard dummy text ever since the 1500s.
                                </p>
                            </div>
                        </div>
                        <div class="toggle ttm-style-classic ttm-toggle-title-border">
                            <div class="toggle-title"><a data-toggle="collapse" data-parent="#accordion"
                                    href="#collapseThree">Ubicaciones</a></div>
                            <div class="toggle-content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry’s standard dummy text ever since the 1500s.
                                </p>
                            </div>
                        </div>
                        <div class="toggle ttm-style-classic ttm-toggle-title-border">
                            <div class="toggle-title"><a data-toggle="collapse" data-parent="#accordion"
                                    href="#collapseThree">Presupuesto Estimado</a></div>
                            <div class="toggle-content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry’s standard dummy text ever since the 1500s.
                                </p>
                            </div>
                        </div>
                        <div class="toggle ttm-style-classic ttm-toggle-title-border">
                            <div class="toggle-title"><a data-toggle="collapse" data-parent="#accordion"
                                    href="#collapseThree">Documentos de Indicaciones</a></div>
                            <div class="toggle-content">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry’s standard dummy text ever since the 1500s.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-80">
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="featured-icon-box style4 left-icon icon-align-top mb-5 res-767-mb-15">
                        <div class="featured-icon">
                            <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                <i class="flaticon-plumber"></i>
                            </div>
                        </div>
                        <div class="featured-content pt-10">
                            <div class="featured-title">
                                <h5>Fecha de Inicio</h5>
                            </div>
                            <div class="featured-desc">
                                <p>Lorem Ipsum is simply dummy text ever since of the printing and typesetting industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="featured-icon-box style4 left-icon icon-align-top mb-5 res-767-mb-15">
                        <div class="featured-icon">
                            <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                <i class="flaticon-plumber-working"></i>
                            </div>
                        </div>
                        <div class="featured-content pt-10">
                            <div class="featured-title">
                                <h5>Fecha de Fin</h5>
                            </div>
                            <div class="featured-desc">
                                <p>Lorem Ipsum is simply dummy text ever since of the printing and typesetting industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="featured-icon-box style4 left-icon icon-align-top mb-5 res-767-mb-15">
                        <div class="featured-icon">
                            <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                <i class="flaticon-man-painting-wall"></i>
                            </div>
                        </div>
                        <div class="featured-content pt-10">
                            <div class="featured-title">
                                <h5>Propuestas recibidas</h5>
                            </div>
                            <div class="featured-desc">
                                <p>Lorem Ipsum is simply dummy text ever since of the printing and typesetting industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="ttm-row services2-section bg-img4 clearfix">
        <div class="container">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="section-title with-desc title-style-center_text clearfix">
                        <div class="title-header">
                            <h5>Fotos de la Obra</h5>
                            <h2 class="title">Revisa el registro<br>fotográfico de la Licitación</h2>
                        </div>

                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="">
                <owl-carousel-o [options]="customOptionsObras" class="blog-slide mt-10">
                    <ng-template carouselSlide>
                        <div class="featured-imagebox ttm-box-view-top-image">
                            <div class="featured-thumbnail">
                                <img loading="lazy" class="img-fluid" src="/assets/images/machalaep/obra_carousel_m.png" alt="">
                            </div>
                            <div class="ttm-box-bottom-content box-shadow2">
                                <div class="featured-title featured-title">
                                    <h5><a routerLink="">Ubicación</a></h5>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="featured-imagebox ttm-box-view-top-image">
                            <div class="featured-thumbnail">
                                <img loading="lazy" class="img-fluid" src="/assets/images/machalaep/obra_carousel_1.png" alt="">
                            </div>
                            <div class="ttm-box-bottom-content box-shadow2">
                                <div class="featured-title featured-title">
                                    <h5><a routerLink="">Diseño</a></h5>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="featured-imagebox ttm-box-view-top-image">
                            <div class="featured-thumbnail">
                                <img loading="lazy" class="img-fluid" src="/assets/images/machalaep/obra_carousel_2.png" alt="">
                            </div>
                            <div class="ttm-box-bottom-content box-shadow2">
                                <div class="featured-title featured-title">
                                    <h5><a routerLink="">Comunidades Aledañas</a></h5>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="featured-imagebox ttm-box-view-top-image">
                            <div class="featured-thumbnail">
                                <img loading="lazy" class="img-fluid" src="/assets/images/machalaep/obra_carousel_3.png" alt="">
                            </div>
                            <div class="ttm-box-bottom-content box-shadow2">
                                <div class="featured-title featured-title">
                                    <h5><a routerLink="">Proyectos a futuro</a></h5>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="featured-imagebox ttm-box-view-top-image">
                            <div class="featured-thumbnail">
                                <img loading="lazy" class="img-fluid" src="/assets/images/machalaep/obra_carousel_4.png" alt="">
                            </div>
                            <div class="ttm-box-bottom-content box-shadow2">
                                <div class="featured-title featured-title">
                                    <h5><a routerLink="">Personal en planta</a></h5>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </section>

    <div class="">
        <owl-carousel-o [options]="customOptionsEmpresas" class="ttm-client clients-slide mt-5">
            <ng-template carouselSlide>
                <div class="client-box ttm-box-view-boxed-logo box-shadow2">
                    <div class="client">
                        <div class="ttm-client-logo-tooltip" data-tooltip="Aseo Machala EP">
                            <img loading="lazy" class="img-fluid" src="/assets/images/machalaep/aseo-img.png" alt="image">
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="client-box ttm-box-view-boxed-logo box-shadow2">
                    <div class="client">
                        <div class="ttm-client-logo-tooltip" data-tooltip="Mercados Machala EP">
                            <img loading="lazy" class="img-fluid" src="/assets/images/machalaep/mercados-img.png" alt="image">
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="client-box ttm-box-view-boxed-logo box-shadow2">
                    <div class="client">
                        <div class="ttm-client-logo-tooltip" data-tooltip="Movilidad Machala EP">
                            <img loading="lazy" class="img-fluid" src="/assets/images/machalaep/movilidad-img.png" alt="image">
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="client-box ttm-box-view-boxed-logo box-shadow2">
                    <div class="client">
                        <div class="ttm-client-logo-tooltip" data-tooltip="Salud Red Municipal EP">
                            <img loading="lazy" class="img-fluid" src="/assets/images/machalaep/salud-img.png" alt="image">
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>

    <section
        class="ttm-row actionbox-section ttm-bgcolor-skincolor ttm-textcolor-white ttm-reset-content-center-991 clearfix">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="mt-5 text-right ttm-reset-content-center-991">
                        <h4>¿Tienes alguna pregunta del proceso de licitación?</h4>
                    </div>
                </div>
                <div class="col-lg-4">
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-white"
                        href="/contacto">Contactémonos</a>
                </div>
            </div>
        </div>
    </section>
</div>