<!-- <ngx-spinner></ngx-spinner>

<owl-carousel-o [options]="customNews" *ngIf="customNews">
  <ng-container *ngFor="let noticia of noticias">
    <ng-template carouselSlide>
      <div
        class="featured-imagebox featured-imagebox-event mb-20"
        routerLink="/noticia/{{ noticia.id }}"
      >
        <div class="featured-thumbnail">
          <owl-carousel-o [options]="customImgsNews">
            <ng-container *ngFor="let url of urls_imagenes">
              <ng-template carouselSlide>
                <img loading="lazy" [src]="url.url" class="img-fluid" />
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
        <div class="featured-content featured-content-event box-shadow2">
          <div class="featured-title pt-10">
            <h5>
              <a
                routerLink="/noticia/{{ noticia.id }}"
                class="cortar-resumen"
                >{{ noticia.titulo }}</a
              >
            </h5>
          </div>
          <div class="post-meta">
            <span class="ttm-meta-line"
              ><i class="fa fa-calendar"></i><a> {{ noticia.fecha }}</a></span
            >
          </div>
          <div class="ttm-box-view-left-image">
            <div class="featured-content ttm-box-desc">
              <span class="category">
                <owl-carousel-o [options]="customTagsNews">
                  <ng-container *ngFor="let categoria of categorias">
                    <ng-template carouselSlide>
                      <span class="category">
                        <a>{{ categoria.nombre }}</a>
                      </span>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </span>
            </div>
          </div>
          <p>
            <strong class="cortar-resumen">{{ noticia.subtitulo }}</strong>
          </p>

          <div class="ttm-btn-box pr-20 pb-20">
            <a
              class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-black"
              routerLink="/noticia/{{ noticia.id }}"
              title=""
              >LEER MÁS<i class="ti ti-angle-double-right"></i
            ></a>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o> -->

<section class="row">
  <div
    class="col-12 col-sm-6"
    *ngFor="
      let noticia of noticias
        | paginate
          : {
              id: 'paginationNews',
              itemsPerPage: 6,
              currentPage: page
            }
    "
  >
    <div
      class="featured-imagebox featured-imagebox-event mb-20"
      routerLink="/noticia/{{ noticia.id }}"
    >
      <!-- <div class="featured-thumbnail">
            <owl-carousel-o [options]="customImgsNews">
              <ng-container *ngFor="let url of urls_imagenes">
                <ng-template carouselSlide>
                  <img loading="lazy" [src]="url.url" class="img-fluid" />
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div> -->
      <div class="featured-content featured-content-event box-shadow2">
        <div class="featured-title pt-10">
          <h5>
            <a routerLink="/noticia/{{ noticia.id }}" class="cortar-resumen">{{
              noticia.titulo
            }}</a>
          </h5>
        </div>
        <div class="post-meta">
          <span class="ttm-meta-line"
            ><i class="fa fa-calendar"></i
            ><a> {{ noticia.fecha | date }}</a></span
          >
        </div>

        <p>
          <strong class="cortar-resumen">{{ noticia.subtitulo }}</strong>
        </p>

        <div class="ttm-btn-box pr-20 pb-20">
          <a
            class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-black"
            routerLink="/noticia/{{ noticia.id }}"
            title=""
            >LEER MÁS<i class="ti ti-angle-double-right"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</section>

<pagination-controls
  id="paginationNews"
  previousLabel="Anterior"
  nextLabel="Siguiente"
  (pageChange)="page = $event"
></pagination-controls>
