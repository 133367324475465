import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NewMensaje } from '../../model/new-mensaje/new-mensaje.model';
import { NewMensajeService } from 'src/app/service/new-mensaje/new-mensaje.service';
import Swal from 'sweetalert2';
import * as L from 'leaflet';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public map;

  constructor(
    private newMensajeService: NewMensajeService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
    this.map = L.map('mapContact').setView(
      [-3.2603791539737292, -79.9598640203476],
      13
    );
    var machalaIcon = L.icon({
      iconUrl: '/assets/images/favicon.png',
      iconSize: [35, 35],
      iconAnchor: [35, 35],
    });

    var marker_BM1 = L.marker([-3.2619437296626894, -79.96180865567194], {
      icon: machalaIcon,
    }).addTo(this.map);
    var bMachala = '<b>Aguas Machala EP</b><br>Oficina Central';
    marker_BM1.bindPopup(bMachala).openPopup();

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
  }
  public btnEnviarContacto() {
    var ipt_email = (<HTMLInputElement>document.getElementById('ipt_email'))
      .value;
    var ipt_autor = (<HTMLInputElement>document.getElementById('ipt_autor'))
      .value;
    var ipt_mensaje = (<HTMLInputElement>document.getElementById('ipt_mensaje'))
      .value;
    let EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      ipt_email != '' &&
      (ipt_email.length <= 5 || !EMAIL_REGEXP.test(ipt_email))
    ) {
      (<HTMLInputElement>document.getElementById('ipt_email')).value = '';
      this.alertsErrores('Ingresa un correo válido');
    } else {
      if (ipt_autor == '' || ipt_mensaje == '') {
        this.alertsErrores('Debes ingresar un nombre y mensaje válido');
      } else {
        this.postCorreo(ipt_email, ipt_autor, ipt_mensaje);
        (<HTMLInputElement>document.getElementById('ipt_email')).value = '';
        (<HTMLInputElement>document.getElementById('ipt_autor')).value = '';
        (<HTMLInputElement>document.getElementById('ipt_mensaje')).value = '';
      }
    }
  }

  public postCorreo(correo: string, nombres: string, mensaje: string) {
    var newMensaje = new NewMensaje(correo, nombres, mensaje);
    this.spinner.show();
    this.newMensajeService.postNewMensaje(newMensaje).subscribe((data) => {
      this.spinner.hide();
      if (data['message'] == 'Email enviado') {
        this.alertsSuccess('Tu mensaje se ha guardado exitosamente');
      } else {
        this.alertsErrores(
          'Tu mensaje no se pudo procesar, intenta seleccionando nuevamente el boton enviar, o recarga la página'
        );
      }
    });
  }
  public alertsErrores(mensaje: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: mensaje,
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
    });
  }
  public alertsSuccess(mensaje: string) {
    Swal.fire({
      icon: 'success',
      text: mensaje,
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
    });
  }
}
