<br>
<br>
<br>
<owl-carousel-o [options]="customOptionsServicios" class="blog-slide mt-15" *ngIf="customOptionsServicios">
    <ng-template carouselSlide>
        <div class="slide featured-imagebox featured-imagebox-post" routerLink="/servicio/0">
            <div class="featured-thumbnail center-image">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/actualizacion_datos.webp"
                    alt="Actualización de datos">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Actualización de datos</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la actualización de datos como cambio de propietario, beneficiario o correo
                        electrónico. Encuentra más información en el siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/0">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/1">
            <div class="featured-thumbnail center-image">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/copias.jpeg"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Certificaciones</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de copias. Encuentra más información en el siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/1">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/2">
            <div class="featured-thumbnail center-image">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/corte_servicio.webp"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Suspensión de Servicio Definitivo</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de suspensión de servicio definitivo. Encuentra más información en el
                        siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/2">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/3">
            <div class="featured-thumbnail">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/corte_servicio_provisional.webp"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Suspensión de Servicio Provisional</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de suspensión de servicio provisional. Encuentra más información en el
                        siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/3">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/4">
            <div class="featured-thumbnail center-image">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/creacion_cuenta.webp"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Creación de Cuenta</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de creación de cuenta en la empresa. Encuentra más información en
                        el siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/4">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/5">
            <div class="featured-thumbnail center-image">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/especificaciones_tecnicas.webp"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Especificaciones técnicas</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de Especificaciones Técnicas App y All, Factibilidad de Servicio y
                        Fiscalización de Proyectos. Encuentra más información en el siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/5">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/6">
            <div class="featured-thumbnail center-image">
                <img loading="lazy"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/pozo.jpeg"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Extracción de Pozo Séptico</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de Extracción de Pozo Séptico Comercial, Industrial, Residencial,
                        Rural. Encuentra más información en el siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/6">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/7">
            <div class="featured-thumbnail center-image">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/instalacion_guia.webp"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Instalación de guía</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de Instalación de guía en terreno natural, en pavimento y en
                        acera. Encuentra más información en el siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/7">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <!-- <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/8">
            <div class="featured-thumbnail">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/trampa_grasa_chifas_asaderos.webp"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Limpieza de trampa de grasa en chifas y asaderos</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de Limpieza de trampa de grasa en chifas y asaderos. Encuentra más
                        información en el siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/8">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template> -->
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/9">
            <div class="featured-thumbnail center-image">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/trampa_grasa_restaurante.webp"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Limpieza de trampas de grasas y sedimentos.</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de limpieza de trampas de grasas y sedimentos. Encuentra más
                        información en el siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/9">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/10">
            <div class="featured-thumbnail center-image">
                <img loading="lazy"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/redterciaria.jpeg"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Mantenimiento de red terciaria de alcantarillado</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de mantenimiento de red terciaria de alcantarillado. Encuentra más
                        información en el siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/10">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/11">
            <div class="featured-thumbnail center-image">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/reconexion_servicio_provisional.webp"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Re-conexión de servicio por suspensión provisional</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de re-conexión de servicio por suspensión provisional. Encuentra más
                        información en el siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/11">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/12">
            <div class="featured-thumbnail center-image">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/servicio_alcantarillado.webp"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Solicitud de servicio de alcantarillado</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de servicio de alcantarillado. Encuentra más información en el
                        siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/12">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/13">
            <div class="featured-thumbnail center-image">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/limpieza_alcantarillado.webp"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Solicitud de limpieza de alcantarillado</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de limpieza de alcantarillado. Encuentra más información en el
                        siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/13">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/14">
            <div class="featured-thumbnail center-image">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/instalacion_medidor.webp"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Instalación de medidor</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de instalación de medidor. Encuentra más información en el
                        siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/14">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="featured-imagebox featured-imagebox-post" routerLink="/servicio/15">
            <div class="featured-thumbnail center-image">
                <img loading="lazy" class="img-fluid"
                    src="/assets/images/optimizadas_machalaep/procesos_internos/exoneracion_tercera_edad_discapacidad.webp"
                    alt="Consulta de deudas por servicios">
            </div>
            <div class="featured-content featured-content-post">
                <div class="post-title featured-title">
                    <h5><a>Exoneración por tercera edad o discapacidad</a></h5>
                </div>
                <div class="featured-desc">
                    <p>Hoja de Ruta para la solicitud de exoneración por tercera edad o discapacidad. Encuentra más
                        información en el siguiente link.</p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                        routerLink="/servicio/15">MÁS DETALLES<i class="ti ti-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </ng-template>

</owl-carousel-o>
<br>
<br>
<br>
