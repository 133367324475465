<div class="ttm-page-title-row">
  <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title-box ttm-textcolor-white">
          <div class="page-title-heading">
            <h1 class="title">Términos y Condiciones</h1>
          </div>
          <div class="breadcrumb-wrapper">
            <span>
              <a title="Homepage" routerLink="home"
                ><i class="ti ti-home"></i
              ></a>
            </span>
            <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
            <span
              ><span style="color: #0095d9; font-weight: bold"
                >Vivienda</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="site-main">
  <section class="ttm-row">
    <div class="container p-4" style="margin-bottom: 2rem">
      <h4 class="text-center" style="margin-bottom: 1rem">
        Términos y Condiciones para la utilización del canal botón de pagos de
        Aguas Machala
      </h4>
      <h6 class="font-weight-bold">
        1. ACEPTACIÓN DEL SERVICIO BOTON DE PAGOS
      </h6>
      <p>
        Aguas Machala, a través de su tecnología de información y comunicación,
        ha implementado un servicio de pago de deudas en línea canales
        electrónicos, para beneficio exclusivo de sus usuarios. Estos servicios
        representan un canal de comunicación adicional para el Cliente y han
        sido creados con el propósito de facilitar la realización de consultas,
        operaciones y transacciones establecidas en un menú de opciones.
        <br />
        <br />
        El servicio “Botón de Pagos” permite realizar consultas y/o pagos de los
        valores totales de sus planillas y pago de convenios, los mismos que son
        ofrecidos por la Empresa.
        <br />
        <br />
        El servicio de recaudación a través de este canal será prestado por
        Aguas Machala que para motivos del presente documento será llamado el
        “BOTÓN DE PAGOS”, que será brindado a usted usuario del servicio, en
        adelante denominado “CLIENTE” bajo los términos y condiciones previstos
        en el presente contrato.
        <br />
        <br />
        Al ingresar y usar el servicio “BOTÓN DE PAGOS”, publicado en este sitio
        Web, el “CLIENTE” expresa su voluntad y acepta los términos y
        condiciones establecidos pulsando sobre el recuadro “Acepto todos los
        términos y condiciones del servicio”, si el “CLIENTE” no está de acuerdo
        con estos términos y condiciones no haga uso de este sitio, por cuanto
        se entiende que existe un acuerdo en común entre las partes tanto del
        “BOTÓN DE PAGOS” como por parte del “CLIENTE” para realizar sus
        consultas y/o pagos de sus planillas de los servicios antes mencionados.
        <br />
        <br />
      </p>
      <h6 class="font-weight-bold">2. MODIFICACIONES</h6>
      <p>
        El “BOTÓN DE PAGOS” podrá modificar cuantas veces sea necesario los
        términos y condiciones establecidas en este contrato con el fin de
        aclararlos sin necesidad de formalidad alguna, cada vez que el “BOTÓN DE
        PAGOS” realice un cambio a estos términos y condiciones serán informados
        en la página principal debiendo ser responsabilidad del “CLIENTE” el
        revisarlos y pulsar nuevamente sobre el recuadro “Acepto todos los
        términos y condiciones del servicio”, si el cliente no pulsara sobre el
        recuadro antes mencionado se dará por concluido en ese mismo momento y
        no se activará el servicio de pago a través del Botón de Pagos, sin
        incurrir por ello en responsabilidad alguna para el “BOTÓN DE PAGOS”.
        <br />
        <br />
      </p>

      <h6 class="font-weight-bold">3. COSTOS</h6>
      <p>
        El “BOTÓN DE PAGOS” en la actualidad ofrece este servicio de manera
        gratuita, sin que ello limite en un futuro a su modificación, la misma
        que será notificada a través de esta página Web.
        <br />
        <br />
      </p>

      <h6 class="font-weight-bold">4. SERVICIOS</h6>
      <p>
        Los servicios disponibles son los que se encuentran habilitados al
        momento de su acceso en el sitio Web disponible por el “BOTÓN DE PAGOS”.
        <br />
        <br />
      </p>

      <h6 class="font-weight-bold">5. TERMINACION</h6>
      <p>
        El “CLIENTE” tiene el derecho A finalizar la utilización de este
        servicio de consultas y/o pagos de sus planillas de consumo de los
        servicios antes indicados en cualquier momento.
        <br />
        <br />
        El servicio de recaudación a través de la página Web de AGUAS MACHALA
        “BOTÓN DE PAGOS” estará disponible de forma permanente 24 horas, los 7
        días de la semana y durante los 365 días del año. No obstante, el
        servicio se podrá interrumpir cuando resulte necesario o conveniente
        realizar las operaciones de mantenimiento en la red.
        <br />
        <br />
      </p>
      <h6 class="font-weight-bold">6. INDEMNIDAD</h6>
      <p>
        El “CLIENTE” se obliga a mantener indemnes de todo reclamo o demanda a
        el “BOTÓN DE PAGOS”, sus accionistas, funcionarios, agentes y empleados,
        incluyendo honorarios de abogados, hincado por algún tercero debido a, o
        que surja como consecuencia del uso por parte suya del servicio que se
        le ha proporcionado, de su violación de los términos y condiciones, o de
        la violación de algún derecho de un tercero.
        <br />
        <br />
      </p>
      <h6 class="font-weight-bold">7. COMERCIAL</h6>
      <p>
        El “CLIENTE” tiene derecho a usar el servicio de consultas y/o pagos de
        sus planillas de servicio como un servicio personal, el “CLIENTE” se
        obliga además a utilizar responsablemente cada uno de los servicios
        habilitados.
        <br />
        <br />
      </p>
      <h6 class="font-weight-bold">8. PROHIBICIONES</h6>
      <p>
        El “CLIENTE” se obliga a no realizar cualquiera de los siguientes actos.
      </p>
      <ul class="list-terms">
        <li>Fingir ser una persona;</li>
        <li>Violar alguna ley nacional a través del, o en el servicio;</li>
        <li>
          Causar molestias o perturbar a terceros a través del, o en el
          servicio;
        </li>
        <li>Recopilar o almacenar datos de terceros o acerca de ellos;</li>
        <li>
          Utilizar el servicio de alguna forma que no sea lo expresamente
          permitido;
        </li>
        <li>
          Reproducir, duplicar, copiar vender o explotar con algún fin comercial
          alguna parte que por uso o acceso se tiene al servicio;
        </li>
        <li>
          Utilizar términos o expresiones injuriosas, intimidantes, calumniantes
          o contrarias a las buenas costumbres.
        </li>
      </ul>
      <br />

      <h6 class="font-weight-bold">9. JURISDICCIÓN Y COMPETENCIA</h6>
      <p>
        La legislación aplicable a este contrato es la ecuatoriana. Para el caso
        de reclamo con respecto a la relación o ejecución en cumplimiento de las
        condiciones establecidas en este documento o de la utilización del
        servicio, incluyendo sin limitación, el reclamo sobre la validez,
        interpretación, exigibilidad o incumplimiento de dichas condiciones,
        finalmente será resuelto por los jueces competentes del Cantón Machala,
        Provincia del Oro.
      </p>
    </div>
  </section>
</div>
