<content class="welcome-cont">
  <section class="container">
    <div class="logo">
      <img
        loading="lazy"
        id="footer-logo-img"
        src="/assets/images/logo.png"
        alt="logo de aguas de machala"
      />
    </div>

    <div class="video-news">
      <iframe
        src="https://www.youtube.com/embed/6D2-kRIHTPw?rel=0;&autoplay=1&loop=1&playlist=6D2-kRIHTPw"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="text-center mt-4">
      <button type="button" routerLink="/home" class="btn btn-light">
        Continuar
      </button>
    </div>
  </section>
</content>
