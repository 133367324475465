<ngx-spinner></ngx-spinner>

<div class="ttm-page-title-row">
  <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title-box ttm-textcolor-white">
          <div class="page-title-heading">
            <h1 class="title">Servicios</h1>
          </div>
          <div class="breadcrumb-wrapper">
            <span>
              <a title="Homepage" routerLink="home"
                ><i class="ti ti-home"></i
              ></a>
            </span>
            <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
            <span
              ><span style="color: #0095d9; font-weight: bold"
                >Servicios</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="site-main">
  <section class="ttm-row blog2-section clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="section-title with-desc title-style-center_text clearfix">
            <div class="title-header">
              <h5>SERVICIOS ONLINE</h5>
              <h2 class="title">GESTIONES A UN CLICK DE DISTANCIA</h2>
            </div>
            <div class="title-desc">
              Realiza gestiones, consultas y pagos desde la comodidad de tu
              hogar. <br />
              Revisa nuestra bitácora de servicios en línea.
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="">
        <app-slidertramites></app-slidertramites>
      </div>
    </div>
  </section>
  <section class="ttm-row services2-section bg-img4 clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="section-title with-desc title-style-center_text clearfix">
            <div class="title-header">
              <h5>NECESITO HACER UN TRÁMITE PRESENCIAL</h5>
              <h2 class="title">PROCESOS INTERNOS</h2>
            </div>
            <div class="title-desc">
              Encuentra en esta sección los pasos a seguir en procesos internos
              de la empresa, te indicaremos como crear una cuenta, como pedir
              una instalación de medidor, como actualizar datos, entre otros
              procesos.
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="">
        <app-sliderservices></app-sliderservices>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="mt-40 text-center">
            <h6 class="ttm-textcolor-grey">
              Revisa todos los procesos que puedes realizar
              <strong
                ><u
                  ><a class="ttm-textcolor-dark" routerLink="/servicios"
                    >en esta sección</a
                  ></u
                ></strong
              >
            </h6>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="ttm-row pb-110 ttm-bgcolor-grey clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div
            #canalesPago
            class="section-title with-desc title-style-center_text clearfix"
            id="canales-pagos"
            ng-init="triggerScrollTo()"
          >
            <div class="title-header">
              <h5>PUNTOS DE PAGO</h5>
              <h2 class="title">BANCOS, COOPERATIVAS Y PUNTOS DE PAGO</h2>
            </div>
            <div class="title-desc">
              Aquí puedes conocer las instituciones en las cuales puedes hacer
              tus gestiones.
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div
            class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row"
          >
            <div class="col-lg-5 col-md-12 ttm-featured-img-left">
              <div class="featured-thumbnail">
                <img
                  loading="lazy"
                  class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/puntos_pago/banco_machala.webp"
                  alt="BANCO DE MACHALA"
                />
              </div>
            </div>
            <div
              class="col-lg-7 col-md-12 featured-content featured-content-post"
            >
              <div class="post-title featured-title">
                <h5>
                  <a
                    target="_blank"
                    style="text-decoration: underline"
                    href="https://www.bancomachala.com"
                    >BANCO DE MACHALA</a
                  >
                </h5>
              </div>
              <div class="featured-desc ttm-box-desc">
                <div class="toggle-content">
                  <h6>Servicios preferenciales</h6>
                  <span class="category">
                    <a>Oronet</a>
                    <a>Cuenta de Ahorros</a>
                    <a
                      >Tarjeta de Crédito Visa Internacional, Oro, Empresarial,
                      Infinite</a
                    >
                    <a>Tarjeta de Crédito Mastercard Gold, Black</a>
                    <a>Bizbank</a>
                    <a>Pagos en línea Oronet</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div
            class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row"
          >
            <div class="col-lg-5 col-md-12 ttm-featured-img-left">
              <div class="featured-thumbnail">
                <img
                  loading="lazy"
                  class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/puntos_pago/banco_guayaquil.webp"
                  alt="BANCO GUAYAQUIL"
                />
              </div>
            </div>
            <div
              class="col-lg-7 col-md-12 featured-content featured-content-post"
            >
              <div class="post-title featured-title">
                <h5>
                  <a
                    target="_blank"
                    style="text-decoration: underline"
                    href="https://www.bancoguayaquil.com"
                    >BANCO GUAYAQUIL</a
                  >
                </h5>
              </div>
              <div class="featured-desc ttm-box-desc">
                <div class="toggle-content">
                  <h6>Servicios preferenciales</h6>
                  <span class="category">
                    <a>Banco del Barrio</a>
                    <a>Débitos programados</a>
                    <a>Cuentas ahorro y corriente </a>
                    <a>Banca Virtual</a>
                    <a>Tarjetas de crédito Visa y Mastercard</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div
            class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row"
          >
            <div class="col-lg-5 col-md-12 ttm-featured-img-left">
              <div class="featured-thumbnail">
                <img
                  loading="lazy"
                  class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/puntos_pago/banco_pichincha.webp"
                  alt="BANCO PICHINCHA"
                />
              </div>
            </div>
            <div
              class="col-lg-7 col-md-12 featured-content featured-content-post"
            >
              <div class="post-title featured-title">
                <h5>
                  <a
                    target="_blank"
                    style="text-decoration: underline"
                    href="https://www.pichincha.com/portal/inicio"
                    >BANCO PICHINCHA</a
                  >
                </h5>
                <h6><a>Grupo Pichincha</a></h6>
              </div>
              <div class="featured-desc ttm-box-desc">
                <div class="toggle-content">
                  <h6>Servicios preferenciales</h6>
                  <span class="category">
                    <a>Pichincha Mi Vecino</a>
                    <a>Pagos en línea</a>
                    <a>Tarjetas de crédito Visa y Mastercard</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div
            class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row"
          >
            <div class="col-lg-5 col-md-12 ttm-featured-img-left">
              <div class="featured-thumbnail">
                <img
                  loading="lazy"
                  class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/puntos_pago/pacifico.webp"
                  alt="BANCO DEL PACÍFICO"
                />
              </div>
            </div>
            <div
              class="col-lg-7 col-md-12 featured-content featured-content-post"
            >
              <div class="post-title featured-title">
                <h5>
                  <a
                    target="_blank"
                    style="text-decoration: underline"
                    href="https://www.bancodelpacifico.com/personas/inicio"
                    >BANCO DEL PACÍFICO</a
                  >
                </h5>
                <h6><a>Banco</a></h6>
              </div>
              <div class="featured-desc ttm-box-desc">
                <div class="toggle-content">
                  <h6>Servicios preferenciales</h6>
                  <span class="category">
                    <a>Tarjeta de Crédito</a>
                    <a>Debito programado</a>
                    <a>Banca Virtual</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div
            class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row"
          >
            <div class="col-lg-5 col-md-12 ttm-featured-img-left">
              <div class="featured-thumbnail">
                <img
                  loading="lazy"
                  class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/puntos_pago/produbanco.webp"
                  alt="PRODUBANCO"
                />
              </div>
            </div>
            <div
              class="col-lg-7 col-md-12 featured-content featured-content-post"
            >
              <div class="post-title featured-title">
                <h5>
                  <a
                    target="_blank"
                    style="text-decoration: underline"
                    href="https://www.produbanco.com.ec"
                    >PRODUBANCO</a
                  >
                </h5>
                <h6><a>Banco - Grupo Promerica</a></h6>
              </div>
              <div class="featured-desc ttm-box-desc">
                <div class="toggle-content">
                  <h6>Servicios preferenciales</h6>
                  <span class="category">
                    <a>Tarjeta de Crédito</a>
                    <a>Debito programado</a>
                    <a>Banca Virtual</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div
            class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row"
          >
            <div class="col-lg-5 col-md-12 ttm-featured-img-left">
              <div class="featured-thumbnail">
                <img
                  loading="lazy"
                  class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/puntos_pago/santa_rosa.webp"
                  alt="COOPERATIVA SANTA ROSA"
                />
              </div>
            </div>
            <div
              class="col-lg-7 col-md-12 featured-content featured-content-post"
            >
              <div class="post-title featured-title">
                <h5>
                  <a
                    target="_blank"
                    style="text-decoration: underline"
                    href="https://www.coopacs.fin.ec"
                    >COOPERATIVA "SANTA ROSA"</a
                  >
                </h5>
                <h6><a>Cooperativa de Ahorro y Crédito</a></h6>
              </div>
              <div class="featured-desc ttm-box-desc">
                <div class="toggle-content">
                  <h6>Servicios preferenciales</h6>
                  <span class="category">
                    <a>Tarjeta de Crédito</a>
                    <a>Debito programado</a>
                    <a>Banca Virtual</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                    <div class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row">
                        <div class="col-lg-5 col-md-12 ttm-featured-img-left">
                            <div class="featured-thumbnail">
                                <img loading="lazy" class="img-fluid"
                                    src="/assets/images/optimizadas_machalaep/puntos_pago/diners.webp"
                                    alt="DINERS CLUB">
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12 featured-content featured-content-post">

                            <div class="post-title featured-title">
                                <h5><a target="_blank" style="text-decoration: underline;"
                                        href="https://www.dinersclub.com.ec/portal/">DINERS CLUB</a></h5>
                                <h6><a>Tarjetas de Crédito, Débito y Prepago</a></h6>

                            </div>
                            <div class="featured-desc ttm-box-desc">
                                <div class="toggle-content">
                                    <h6>Servicios preferenciales</h6>
                                    <span class="category">
                                        <a>Tarjeta de crédito Diners</a>
                                        <a>Discover</a>
                                        <a>Débitos recurrentes</a>
                                        <a>Cash Advance</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                    <div class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row">
                        <div class="col-lg-5 col-md-12 ttm-featured-img-left">
                            <div class="featured-thumbnail">
                                <img loading="lazy" class="img-fluid"
                                    src="/assets/images/optimizadas_machalaep/puntos_pago/visa.webp" alt="VISA">
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12 featured-content featured-content-post">

                            <div class="post-title featured-title">
                                <h5><a target="_blank" style="text-decoration: underline;"
                                        href="https://www.visa.com.ec">VISA</a></h5>
                                <h6><a>Tarjetas de Crédito, Débito y Prepago</a></h6>

                            </div>
                            <div class="featured-desc ttm-box-desc">
                                <div class="toggle-content">
                                    <h6>Servicios preferenciales</h6>
                                    <span class="category">
                                        <a>Tarjeta de crédito Diners</a>
                                        <a>Discover</a>
                                        <a>Débitos recurrentes</a>
                                        <a>Cash Advance</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                    <div class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row">
                        <div class="col-lg-5 col-md-12 ttm-featured-img-left">
                            <div class="featured-thumbnail">
                                <img loading="lazy" class="img-fluid"
                                    src="/assets/images/optimizadas_machalaep/puntos_pago/mastercard.webp"
                                    alt="MASTERCARD">
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12 featured-content featured-content-post">

                            <div class="post-title featured-title">
                                <h5><a>MASTERCARD</a></h5>
                                <h6><a>Tarjetas de Crédito, Débito y Prepago</a></h6>

                            </div>
                            <div class="featured-desc ttm-box-desc">
                                <div class="toggle-content">
                                    <h6>Servicios preferenciales</h6>
                                    <span class="category">
                                        <a>Tarjeta de crédito Diners</a>
                                        <a>Discover</a>
                                        <a>Débitos recurrentes</a>
                                        <a>Cash Advance</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
        <!-- <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                    <div class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row">
                        <div class="col-lg-5 col-md-12 ttm-featured-img-left">
                            <div class="featured-thumbnail">
                                <img loading="lazy" class="img-fluid"
                                    src="/assets/images/optimizadas_machalaep/puntos_pago/discover.webp" alt="DISCOVER">
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12 featured-content featured-content-post">

                            <div class="post-title featured-title">
                                <h5><a target="_blank" style="text-decoration: underline;"
                                        href="https://www.discover.ec/portal/">DISCOVER</a></h5>
                                <h6><a>Tarjetas de Crédito, Débito y Prepago</a></h6>

                            </div>
                            <div class="featured-desc ttm-box-desc">
                                <div class="toggle-content">
                                    <h6>Servicios preferenciales</h6>
                                    <span class="category">
                                        <a>Tarjeta de crédito Diners</a>
                                        <a>Discover</a>
                                        <a>Débitos recurrentes</a>
                                        <a>Cash Advance</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div
            class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row"
          >
            <div class="col-lg-5 col-md-12 ttm-featured-img-left">
              <div class="featured-thumbnail">
                <img
                  loading="lazy"
                  class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/puntos_pago/facilito.webp"
                  alt="FACILITO"
                />
              </div>
            </div>
            <div
              class="col-lg-7 col-md-12 featured-content featured-content-post"
            >
              <div class="post-title featured-title">
                <h5>
                  <a
                    target="_blank"
                    style="text-decoration: underline"
                    href="https://www.facilito.com.ec/facilitoweb/"
                    >FACILITO</a
                  >
                </h5>
                <h6><a>Red de Servicios</a></h6>
              </div>
              <div class="featured-desc ttm-box-desc">
                <div class="toggle-content">
                  <h6>Servicios preferenciales</h6>
                  <span class="category">
                    <a>Puntos autorizados</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div
            class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row"
          >
            <div class="col-lg-5 col-md-12 ttm-featured-img-left">
              <div class="featured-thumbnail">
                <img
                  loading="lazy"
                  class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/puntos_pago/pago agil.webp"
                  alt="Pago Ágil"
                />
              </div>
            </div>
            <div
              class="col-lg-7 col-md-12 featured-content featured-content-post"
            >
              <div class="post-title featured-title">
                <h5>
                  <a
                    target="_blank"
                    style="text-decoration: underline"
                    href="https://www.produbanco.com.ec/red-comercial/agencias-servipagos/transacciones-pagoágil/"
                    >Pago Ágil</a
                  >
                </h5>
                <h6><a>Red de Servicios</a></h6>
              </div>
              <div class="featured-desc ttm-box-desc">
                <div class="toggle-content">
                  <h6>Servicios preferenciales</h6>
                  <span class="category">
                    <a>Puntos autorizados</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div
            class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row"
          >
            <div class="col-lg-5 col-md-12 ttm-featured-img-left">
              <div class="featured-thumbnail">
                <img
                  loading="lazy"
                  class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/puntos_pago/banco barrio.webp"
                  alt="BANCO DEL BARRIO"
                />
              </div>
            </div>
            <div
              class="col-lg-7 col-md-12 featured-content featured-content-post"
            >
              <div class="post-title featured-title">
                <h5>
                  <a
                    target="_blank"
                    style="text-decoration: underline"
                    href="https://www.bancoguayaquil.com/banco-del-barrio/"
                    >BANCO DEL BARRIO</a
                  >
                </h5>
                <h6><a>Red de Servicios</a></h6>
              </div>
              <div class="featured-desc ttm-box-desc">
                <div class="toggle-content">
                  <h6>Servicios preferenciales</h6>
                  <span class="category">
                    <a>Puntos autorizados</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div
            class="featured-imagebox featured-imagebox-post ttm-box-view-left-image row"
          >
            <div class="col-lg-5 col-md-12 ttm-featured-img-left">
              <div class="featured-thumbnail">
                <img
                  loading="lazy"
                  class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/puntos_pago/mi vecino.webp"
                  alt="Mi vecino"
                />
              </div>
            </div>
            <div
              class="col-lg-7 col-md-12 featured-content featured-content-post"
            >
              <div class="post-title featured-title">
                <h5>
                  <a
                    target="_blank"
                    style="text-decoration: underline"
                    href="https://www.pichincha.com/portal/canales-de-atencion/mi-vecino/mi-vecino"
                    >MI VECINO</a
                  >
                </h5>
                <h6><a>Red de Servicios</a></h6>
              </div>
              <div class="featured-desc ttm-box-desc">
                <div class="toggle-content">
                  <h6>Servicios preferenciales</h6>
                  <span class="category">
                    <a>Puntos autorizados</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section
    class="ttm-row services-section home4-services-section bg-layer bg-layer-equal-height break-991-colum res-991-mt-0 clearfix"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-12">
          <div
            class="about-content ttm-bg ttm-col-bgcolor-yes ttm-left-span ttm-bgcolor-darkgrey spacing-9"
          >
            <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
            <div class="layer-content">
              <div class="section-title with-desc clearfix">
                <div class="title-header">
                  <h5>UBICACIÓN</h5>
                  <h2 class="title">Encuentrános cerca de ti</h2>
                </div>
                <div class="title-desc mb-40">
                  Nos encuentras en toda nuestra querida Machala, en el
                  siguiente mapa te mostramos todos los puntos de pago.
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div
                    class="featured-icon-box style7 left-icon icon-align-top mb-15"
                  >
                    <div class="featured-content">
                      <div class="featured-title">
                        <h5>Casco Histórico de Machala</h5>
                      </div>
                      <div class="featured-desc">
                        <p>
                          Disponemos de varios puntos de pago, ademas de
                          nuestras oficinas en el caso urbano de nuestra ciudad.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div
                    class="featured-icon-box style7 left-icon icon-align-top mb-15"
                  >
                    <div class="featured-content">
                      <div class="featured-title">
                        <h5>Parroquias urbanas y rurales de Machala</h5>
                      </div>
                      <div class="featured-desc">
                        <p>
                          Puedes encontrarnos en varias parroquias urbanas y
                          rurales de Machala. Consulta nuestros puntos de pago
                          en el siguiente mapa interactivo.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12">
          <div
            class="col-bg-img-six ttm-col-bgimage-yes ttm-bg ttm-right-span mt_60 res-991-mt-0"
          >
            <div class="ttm-col-wrapper-bg-layer ttm-bg-layer">
              <div class="col-bg-img-thirteen"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    style="
      padding-top: 5%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 10%;
    "
  >
    <div id="mapService" style="height: 100vh"></div>
  </section>

  <section
    class="ttm-row broken-section bg-layer break-1199-colum res-991-mt-0 mt_70 clearfix"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div
            class="about-content ttm-col-bgcolor-yes ttm-bg ttm-left-span ttm-bgcolor-skincolor spacing-1"
          >
            <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
            <div class="layer-content">
              <div class="section-title with-desc mb-40 clearfix">
                <div class="title-header">
                  <h5>Innovamos nuestros procesos</h5>
                  <h2 class="title">Facturación Electrónica</h2>
                </div>
                <div class="title-desc">
                  Creamos un sistema de Facturación Electrónica SEGURO para tu
                  comodidad.
                </div>
                <div class="title-desc">
                  Accede a este sistema para revisar tus transacciones
                  recientes.
                </div>
              </div>
              <div class="featured-icon-box style3 left-icon icon-align-top">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md ttm-icon_element-style-square"
                  >
                    <i class="ti ti-home"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>Seguridad</h5>
                  </div>
                  <div class="featured-desc">
                    <p>
                      Nuestro sistema informático te garantiza el correcto uso
                      de tus datos personales. Trabajamos diariamente en pro de
                      la seguridad de manejo de tus datos.
                    </p>
                  </div>
                </div>
              </div>
              <div class="separator">
                <div class="sep-line mt_5 mb-35"></div>
              </div>
              <div class="featured-icon-box style3 left-icon icon-align-top">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md ttm-icon_element-style-square"
                  >
                    <i class="ti ti-light-bulb"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>Confiabilidad</h5>
                  </div>
                  <div class="featured-desc">
                    <p>
                      Al navegar por nuestros servicios en línea te ofrecemos
                      siempre información veraz. Si tienes algún problema puedes
                      usar nuestros servicios de
                      <a
                        routerLink="/reclamo"
                        target="_blank"
                        style="text-decoration: underline"
                        >reclamos</a
                      >
                      o
                      <a
                        routerLink="/denunciaciudad"
                        target="_blank"
                        style="text-decoration: underline"
                        >denuncias</a
                      >, o
                      <a
                        routerLink="/contacto"
                        target="_blank"
                        style="text-decoration: underline"
                        >comunicarte con nosotros</a
                      >.
                    </p>
                  </div>
                </div>
              </div>
              <div class="ttm-btn-box pr-20 pb-20">
                <a
                  class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-black"
                  href="http://177.234.239.179:82/cw_client_login"
                  target="_blank"
                  title=""
                  >ACCEDER FACTURACION ELECTRÓNICA
                  <i class="ti ti-angle-double-right"></i
                ></a>
              </div>
            </div>
            <br />
            <br />
            <br />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="col-bg-img-one ttm-col-bgimage-yes ttm-bg ttm-right-span">
            <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
            <div class="layer-content"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
