export class NewCorreo {
    correo: string;

    constructor(correo:string){
        this.correo=correo;
    }
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
