<ngx-spinner></ngx-spinner>
<div class="ttm-page-title-row">
  <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title-box ttm-textcolor-white">
          <div class="page-title-heading">
            <h1 class="title">Transparencia</h1>
          </div>
          <div class="breadcrumb-wrapper">
            <span>
              <a title="Homepage" routerLink="home"><i class="ti ti-home"></i></a>
            </span>
            <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
            <span><span style="color: #0095d9; font-weight: bold">Transparencia</span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="site-main">
  <section class="ttm-row">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="section-title with-desc title-style-center_text mb-55 clearfix">
            <div class="title-header">
              <h2 class="title" id="lotaip">
                Ley Orgánica de Transparencia y Acceso a la Información Pública
              </h2>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="row mt-15">
        <div class="col-md-6">
          <p>
            ​La​
            <strong>Ley Orgánica de Transparencia y Acceso a la Información Pública
              (LOTAIP)</strong>
            plantea la participación ciudadana y el derecho de acceso a la
            información relacionada con asuntos p​úblicos, para ejercer un
            efectivo control y exigir la rendición de cuentas a las
            instituciones gubernamentales o aquellas que perciben recursos
            estatales. ​​
          </p>
          <p>
            En cumplimiento de la Ley Orgánica de Transparencia y Acceso a la
            Información Pública (LOTAIP), todas las entidades poseedoras de
            información pública están obligadas a difundir a través de su portal
            institucional, la siguiente información mínima actualizada.​​
          </p>
        </div>
        <div class="col-md-6">
          <div class="single-img" style="height: 100%">
            <img loading="lazy" style="
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
              " src="/assets/images/transparencia.jpg" alt="" />
          </div>
        </div>

        <div class="row col-12">
          <div class="col-12">
            <h5>Información pública</h5>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-12">
            <div class="section-title clearfix">
              <h5 class="title">Año</h5>
              <select (ngModel)="(selectedOptionA)" id="selectAnioD" (change)="getMesTransparencia()">
                <option *ngFor="let item of anios">
                  {{ item.anio }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-12">
            <div class="section-title clearfix">
              <h5 class="title">Mes</h5>
              <select (ngModel)="(selectedOptionM)" id="selectMesD">
                <option *ngFor="let item of meses">
                  {{ item.mes }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12">
            <div class="section-title clearfix">
              <h5 class="title" style="color: #f8f9fa">ACCION</h5>
              <a class="ttm-btn ttm-btn-size-sm ttm-btn-shape-round ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-black"
                title="" (click)="buscarDocumentos()">BUSCAR <i class="ti ti-search"></i></a>
            </div>
          </div>
        </div>
        <div *ngIf="verificarArray(transparencias)" class="col-12 mt-5">
          <div class="accordion mt-5" id="accordionFilterCSV">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                  <button class="btn btn-block text-left title" type="button" data-toggle="collapse"
                    data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    Artículo 19
                  </button>
                </h2>
              </div>

              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                data-parent="#accordionFilterCSV">
                <div class="card-body py-0">
                  <ul>
                    <li>
                      1.1 Estructura orgánica
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoA(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      1.2-1.3 Base Legal Regulaciones Procedimientos Internos
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoB(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      1.4 Metas y objetivos unidades
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoC(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      2.1-2.2 Directorio y distributivo personal de la entidad
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoD(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      3. Remuneraciones ingresos adicionales
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoE(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      4. Detalle licencia comisiones
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoF(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      5-22 Servicios formularios formatos trámites
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoG(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      6. Presupuesto de la institución
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoH(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      7. Resultados de las auditorías internas y gubernamentales
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoI(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      8. Procesos de contratación publica
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoJ(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      9. Listado de empresas y personas que han incumplido contratos
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoK(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      10. Planes y programas
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoL(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      11. Contratos de crédito externos o internos
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoM(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      12. Mecanismos rendición cuentas
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoO(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      13. Viáticos informes de trabajo y justificativos de movilización
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoP(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      14. Responsables del acceso de información publica
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoQ(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      15. Texto íntegro de los contratos colectivos vigentes y reformas
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoR(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      16. Listado índice formación reservada
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoS(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      17. Detalle de las audiencias y reuniones de autoridades
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoT(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      18. Detalle de convenios nacionales e internacionales
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoU(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      19. Detalle donativos oficiales y protocolares
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoV(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      20. Registro de activos de información frecuente y complementaria
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoW(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      21. Políticas públicas o información grupo específico
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoX(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      23. Detalle personas servidoras públicas con acciones afirmativas
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoY(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                    <li>
                      24. Información relevante para el ejercicio de derechos ODS
                    </li>
                    <ul class="mt-0" *ngFor="let data of transparencias">
                      <li *ngIf="verificarPrefijoZ(data.nombre) == true" role="button" (click)="openPDFFile(data.url)">
                        <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                      </li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h2 class="mb-0">
                  <button class="btn  btn-block text-left collapsed" type="button" data-toggle="collapse"
                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Artículo 29
                  </button>
                </h2>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionFilterCSV">
                <ul>
                  <li>
                    Art. 29.1 Información Pública Empresas Públicas
                  </li>
                  <ul class="mt-0" *ngFor="let data of transparencias">
                    <li *ngIf="verificarPrefijoArt29A(data.nombre) == true" role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                    </li>
                  </ul>
                  <li>
                    Art. 29.2 Información Pública Empresas Públicas
                  </li>
                  <ul class="mt-0" *ngFor="let data of transparencias">
                    <li *ngIf="verificarPrefijoArt29B(data.nombre) == true" role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                    </li>
                  </ul>
                  <li>
                    Art. 29.3 Información Pública Empresas Públicas
                  </li>
                  <ul class="mt-0" *ngFor="let data of transparencias">
                    <li *ngIf="verificarPrefijoArt29C(data.nombre) == true" role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> {{data.nombre}}
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                  <button class="btn  btn-block text-left collapsed" type="button" data-toggle="collapse"
                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Artículo 23
                  </button>
                </h2>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionFilterCSV">
                <ul>
                 <!--  <li>
                    Transparencia activa
                  </li> -->
                  <ul class="mt-0" *ngFor="let data of filterArt23(transparencias, '1-Act-conjunto_de_datos.csv')">
                    <li  role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> 1-conjunto_de_datos.csv
                    </li>
                  </ul>
                  <ul class="mt-0" *ngFor="let data of filterArt23(transparencias, '2-Act-metadatos.csv')">
                    <li  role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> 1-metadatos.csv
                    </li>
                  </ul>
                  <ul class="mt-0" *ngFor="let data of filterArt23(transparencias, '3-Act-diccionario.csv')">
                    <li  role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> 1-diccionario.csv
                    </li>
                  </ul>
                  <li>
                    Transparencia pasiva
                  </li>
                  <ul class="mt-0" *ngFor="let data of filterArt23(transparencias, '1-Pas-conjunto_de_datos.csv')">
                    <li role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> 1-conjunto_de_datos.csv
                    </li>
                  </ul>
                  <ul class="mt-0" *ngFor="let data of filterArt23(transparencias, '2-Pas-metadatos.csv')">
                    <li  role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> 2-metadatos.csv
                    </li>
                  </ul>
                  <ul class="mt-0" *ngFor="let data of filterArt23(transparencias, '3-Pas-diccionario.csv')">
                    <li role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> 3-diccionario.csv
                    </li>
                  </ul>

                  <li>
                    Transparencia Focalizada
                  </li>
                  <ul class="mt-0" *ngFor="let data of filterArt23(transparencias, '1-Foc-conjunto_de_datos.csv')">
                    <li role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> 1-conjunto_de_datos.csv
                    </li>
                  </ul>
                  <ul class="mt-0" *ngFor="let data of filterArt23(transparencias, '2-Foc-metadatos.csv')">
                    <li  role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> 2-metadatos.csv
                    </li>
                  </ul>
                  <ul class="mt-0" *ngFor="let data of filterArt23(transparencias, '3-Foc-diccionario.csv')">
                    <li  role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> 3-diccionario.csv
                    </li>
                  </ul>
                  <li>
                    Transparencia Colaborativa
                  </li>
                  <ul class="mt-0" *ngFor="let data of filterArt23(transparencias, '1-Col-conjunto_de_datos.csv')">
                    <li role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> 1-conjunto_de_datos.csv
                    </li>
                  </ul>
                  <ul class="mt-0" *ngFor="let data of filterArt23(transparencias, '2-Col-metadatos.csv')">
                    <li role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> 2-metadatos.csv
                    </li>
                  </ul>
                  <ul class="mt-0" *ngFor="let data of filterArt23(transparencias, '3-Col-diccionario.csv')">
                    <li role="button"
                      (click)="openPDFFile(data.url)">
                      <i class="fas fa-file-csv me-2"></i> 3-diccionario.csv
                    </li>
                  </ul>
                
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3 col-12" id="tabla">
          <table style="width: 100%">
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr *ngFor="let data of transparencias">
              <td *ngIf="verificarPrefijoA(data.nombre) == false 
              && verificarPrefijoB(data.nombre) == false
              && verificarPrefijoC(data.nombre) == false
              && verificarPrefijoD(data.nombre) == false
              && verificarPrefijoE(data.nombre) == false
              && verificarPrefijoF(data.nombre) == false
              && verificarPrefijoG(data.nombre) == false
              && verificarPrefijoH(data.nombre) == false
              && verificarPrefijoI(data.nombre) == false
              && verificarPrefijoJ(data.nombre) == false
              && verificarPrefijoK(data.nombre) == false
              && verificarPrefijoL(data.nombre) == false
              && verificarPrefijoM(data.nombre) == false
              && verificarPrefijoO(data.nombre) == false
              && verificarPrefijoP(data.nombre) == false
              && verificarPrefijoQ(data.nombre) == false
              && verificarPrefijoR(data.nombre) == false
              && verificarPrefijoS(data.nombre) == false
              && verificarPrefijoT(data.nombre) == false
              && verificarPrefijoU(data.nombre) == false
              && verificarPrefijoV(data.nombre) == false
              && verificarPrefijoW(data.nombre) == false
              && verificarPrefijoX(data.nombre) == false
              && verificarPrefijoY(data.nombre) == false
              && verificarPrefijoZ(data.nombre) == false
              && verificarPrefijoArt29A(data.nombre) == false
              && verificarPrefijoArt29B(data.nombre) == false
              && verificarPrefijoArt29C(data.nombre) == false
              && verificarPrefijoArt23A(data.nombre) == false
              && verificarPrefijoArt23B(data.nombre) == false
              && verificarPrefijoArt23C(data.nombre) == false
              && verificarPrefijoArt23D(data.nombre) == false

              ">{{ data.nombre }}</td>
              <td *ngIf="verificarPrefijoA(data.nombre) == false
                                        && verificarPrefijoB(data.nombre) == false
              && verificarPrefijoC(data.nombre) == false
              && verificarPrefijoD(data.nombre) == false
              && verificarPrefijoE(data.nombre) == false
              && verificarPrefijoF(data.nombre) == false
              && verificarPrefijoG(data.nombre) == false
              && verificarPrefijoH(data.nombre) == false
              && verificarPrefijoI(data.nombre) == false
              && verificarPrefijoJ(data.nombre) == false
              && verificarPrefijoK(data.nombre) == false
              && verificarPrefijoL(data.nombre) == false
              && verificarPrefijoM(data.nombre) == false
              && verificarPrefijoO(data.nombre) == false
              && verificarPrefijoP(data.nombre) == false
              && verificarPrefijoQ(data.nombre) == false
              && verificarPrefijoR(data.nombre) == false
              && verificarPrefijoS(data.nombre) == false
              && verificarPrefijoT(data.nombre) == false
              && verificarPrefijoU(data.nombre) == false
              && verificarPrefijoV(data.nombre) == false
              && verificarPrefijoW(data.nombre) == false
              && verificarPrefijoX(data.nombre) == false
              && verificarPrefijoY(data.nombre) == false
              && verificarPrefijoZ(data.nombre) == false
              && verificarPrefijoArt29A(data.nombre) == false
              && verificarPrefijoArt29B(data.nombre) == false
              && verificarPrefijoArt29C(data.nombre) == false
              && verificarPrefijoArt23A(data.nombre) == false
              && verificarPrefijoArt23B(data.nombre) == false
              && verificarPrefijoArt23C(data.nombre) == false
              && verificarPrefijoArt23D(data.nombre) == false
              " style="padding-top: 1rem">
                <a class="ttm-btn ttm-btn-size-sm ttm-btn-shape-round ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-black"
                  (click)="openPDFFile(data.url)" target="_blank" title="">Descargar<i class="ti ti-eye"></i></a>
              </td>
              <td *ngIf="verificarPrefijoA(data.nombre) == false
                            && verificarPrefijoB(data.nombre) == false
              && verificarPrefijoC(data.nombre) == false
              && verificarPrefijoD(data.nombre) == false
              && verificarPrefijoE(data.nombre) == false
              && verificarPrefijoF(data.nombre) == false
              && verificarPrefijoG(data.nombre) == false
              && verificarPrefijoH(data.nombre) == false
              && verificarPrefijoI(data.nombre) == false
              && verificarPrefijoJ(data.nombre) == false
              && verificarPrefijoK(data.nombre) == false
              && verificarPrefijoL(data.nombre) == false
              && verificarPrefijoM(data.nombre) == false
              && verificarPrefijoO(data.nombre) == false
              && verificarPrefijoP(data.nombre) == false
              && verificarPrefijoQ(data.nombre) == false
              && verificarPrefijoR(data.nombre) == false
              && verificarPrefijoS(data.nombre) == false
              && verificarPrefijoT(data.nombre) == false
              && verificarPrefijoU(data.nombre) == false
              && verificarPrefijoV(data.nombre) == false
              && verificarPrefijoW(data.nombre) == false
              && verificarPrefijoX(data.nombre) == false
              && verificarPrefijoY(data.nombre) == false
              && verificarPrefijoZ(data.nombre) == false
              && verificarPrefijoArt29A(data.nombre) == false
              && verificarPrefijoArt29B(data.nombre) == false
              && verificarPrefijoArt29C(data.nombre) == false
              && verificarPrefijoArt23A(data.nombre) == false
              && verificarPrefijoArt23B(data.nombre) == false
              && verificarPrefijoArt23C(data.nombre) == false
              && verificarPrefijoArt23D(data.nombre) == false
              " style="padding-top: 1rem">
                <!--  <a class="ttm-btn ttm-btn-size-sm ttm-btn-shape-round ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                                (click)="downloadPDFFile(data.url)" target="_blank" title="" download>Descargar <i
                                    class="ti ti-download"></i></a> -->
              </td>
            </tr>
          </table>
        </div>

      </div>
    </div>
  </section>

  <!-- <section class="ttm-row carrer-pos-section clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div
            class="section-title with-desc title-style-center_text mb-55 clearfix"
          >
            <div class="title-header">
              <h2 class="title">
                Ley Orgánica de Transparencia y Acceso a la Información Pública
              </h2>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="row"></div>
      <div class="row">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <ul class="ttm-list ttm-list-style-icon">
                <li>
                  <i
                    class="ttm-textcolor-skincolor fa fa-arrow-circle-right"
                  ></i>
                  Art. 7 Difusión de Información Pública
                </li>
              </ul>
              Por la transparencia en la gestión administrativa que están
              obligadas a observar todas las instituciones del Estado que
              conforman el sector público en los términos del artículo 118 de la
              Constitución Política de la República y demás entes señalados en
              el artículo 1 de la presente Ley, difundirán a través de un portal
              de información o página web, así como de los medios necesarios a
              disposición del público, implementados en la misma institución, la
              siguiente información mínima actualizada, que para efectos de esta
              Ley se la considera de naturaleza obligatoria:
              <ul class="ttm-list ttm-list-style-icon">
                <li>
                  <i
                    class="ttm-textcolor-skincolor fa fa-arrow-circle-right"
                  ></i>
                  A. Organización Interna
                </li>
              </ul>
              Estructura orgánica funcional, base legal que la rige,
              regulaciones y procedimientos internos aplicables a la entidad;
              las metas y objetivos de las unidades administrativas de
              conformidad con sus programas operativos.
            </div>
          </div>
        </div>
        <br /><br />
        <br /><br />
      </div>
    </div>
  </section> -->
</div>
<section class="ttm-bgcolor-grey" style="padding-top: 3rem; padding-bottom: 10rem">
  <div class="container" id="cuentas-ren">
    <h4 class="title">Rendición de Cuentas</h4>
    <!-- <p>
      En el siguiente apartado puedes encontrar todos los documentos referentes
      a divulgación de información pública. Selecciona el año y mes a consultar
      y se desplegara todos los documentos relacionados, puedes verlos online o
      descargarlos.
    </p>
    <p>
      Puedes encontrar los informes anuales de rendición de cuentas a
      continuación:
    </p> -->

    <!-- <ul>
      <li>
        -
        <a
          target="_blank"
          href="https://aguasmachala.gob.ec/wp-content/uploads/2020/11/RENDICION-2019.pdf"
          style="font-weight: bold; text-decoration: underline"
          >Rendicion de Cuentas Año 2019</a
        >
      </li>
      <li>
        -
        <a
          target="_blank"
          href="https://aguasmachala.gob.ec/wp-content/uploads/2019/04/INFORME-DE-RENDICION-DE-CUENTAS-2018-AGUAS-MACHALA-1.pdf"
          style="font-weight: bold; text-decoration: underline"
          >Rendicion de Cuentas Año 2018
        </a>
      </li>
      <li>
        -
        <a
          target="_blank"
          href="https://aguasmachala.gob.ec/wp-content/uploads/2018/06/Rendicion-de-Cuentas-2017B.pdf"
          style="font-weight: bold; text-decoration: underline"
          >Rendicion de Cuentas Año 2017</a
        >
      </li>
    </ul> -->

    <div class="documents-accountability mt-4">
      <div class="acco-container" *ngFor="let accountability of accountabilities">
        <div *ngIf="accountability.anio == 2023">
          <div class="acco-year">
            <h5 class="border-title mb-0">
              <span>{{ accountability.anio }}</span>
            </h5>
          </div>
          <div>
            <ul class="rc_ul">
              <li>
                <h5>
                  FASE 1
                </h5>
                <ol class="num_ul">
                  <div *ngFor="let document of ordenarArray(filter2023(accountability.documentos))">

                    <li class="acco-doc">
                      <a [href]="document.url" target="_blank" style="text-decoration: underline">{{ document.nombre
                        }}</a>
                    </li>


                  </div>
                </ol>
              </li>
              <li>
                <h5>
                  FASE 2
                </h5>
                <ol class="num_ul">
                  <div *ngFor="let document of ordenarArray(filter2023B(accountability.documentos))">
                    <li class="acco-doc">
                      <a [href]="document.url" target="_blank" style="text-decoration: underline">{{ document.nombre
                        }}</a>
                    </li>
                  </div>
                </ol>
              </li>
              <li>
                <h5>
                  FASE 3
                </h5>
                <ol class="num_ul">
                  <div *ngFor="let document of ordenarArray(filter2023C(accountability.documentos))">
                    <li class="acco-doc">
                      <a [href]="document.url" target="_blank" style="text-decoration: underline">{{ document.nombre
                        }}</a>
                    </li>
                  </div>
                  <div *ngFor="let document of ordenarArray(filterArt23(accountability.documentos, 'Acto Deliberación Pública'))">
                    <li class="acco-doc">
                      <a [href]="document.url" target="_blank" style="text-decoration: underline">
                        Deliberación Pública <a href="https://fb.watch/sfqttpkzko/" target="_blank" title="Ir al sitio web" class="btn btn-sm btn-primary"><i class="fa fa-share" aria-hidden="true"></i></a>
                      </a>
                    </li>
                  </div>
                
                </ol>
              </li>
              <li>
                <h5>
                  FASE 4
                </h5>
<!--                 <ul>
                  <li class="acco-doc" style="color: #182333;">
                    Deliberación Pública <a href="https://fb.watch/sfqttpkzko/" target="_blank" title="Ir al sitio web" class="btn btn-sm btn-primary"><i class="fa fa-share" aria-hidden="true"></i></a>
                  </li>
                </ul> -->
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="accountability.anio == 2022; else anotherYears">
          <div class="acco-year">
            <h5 class="border-title mb-0">
              <span>{{ accountability.anio }}</span>
            </h5>
          </div>
          <div>

            <ul class="rc_ul">
              <li>
                <h5>INSUMOS SOBRE QUE RENDIR CUENTAS</h5>
                <ul class="rc_ul">
                  <div *ngFor="let document of filterAnios(accountability.documentos)">
                    <li class="acco-doc">
                      <a [href]="document.url" target="_blank" style="text-decoration: underline">{{ document.nombre
                        }}</a>
                    </li>
                  </div>
                </ul>
              </li>
              <div *ngFor="let document of onlyDocuments(accountability.documentos)">
                <li class="acco-doc">
                  <a [href]="document.url" target="_blank" style="text-decoration: underline">{{ document.nombre }}</a>
                </li>
              </div>
              <li class="acco-doc">
                <a style="text-decoration: underline; color:#182333">
                  Video del informe de rendición de cuentas 2022
                </a>
              </li>
              <li class="acco-doc">
                <a style="text-decoration: underline; color:#182333">
                  Formulario de rendición de cuentas 2022
                </a>
              </li>
              <li>
                <h5 class="mt-4">MEDIOS DE VERIFICACIÓN</h5>
                <ul class="rc_ul">
                  <li class="acco-doc">
                    INFORMACIÓN FINANCIERA
                    <ul class="rc_ul">
                      <ul class="rc_ul">
                        <li class="acco-doc">
                          <a style="text-decoration: underline; color:#182333">
                            Balance general
                          </a>
                        </li>
                        <li class="acco-doc">
                          <a style="text-decoration: underline; color:#182333">
                            Ejecución de presupuesto
                          </a>
                        </li>
                        <div *ngFor="let document of filterVerification(accountability.documentos)">
                          <li class="acco-doc">
                            <a [href]="document.url" target="_blank" style="text-decoration: underline">{{
                              document.nombre
                              }}</a>
                          </li>

                        </div>
                        <li class="acco-doc">
                          CUMPLIMIENTO DE OBLIGACIONES
                          <ul class="rc_ul">
                            <li class="acco-doc">
                              <a style="text-decoration: underline; color:#182333">
                                LABORALES
                              </a>
                            </li>
                            <li class="acco-doc">
                              <a style="text-decoration: underline; color:#182333">
                                TRIBUTARIAS
                              </a>
                            </li>
                            <!--  <div *ngFor="let document of filterInformation(accountability.documentos)">
                              <li class="acco-doc">
                                <a [href]="document.url" target="_blank" style="text-decoration: underline">{{
                                  document.nombre
                                  }}</a>
                              </li>

                            </div> -->
                          </ul>
                        </li>
                      </ul>
                    </ul>
                  </li>
                  <li class="acco-doc">
                    <a style="text-decoration: underline; color:#182333">
                      Mecanismos de participación ciudadana y control social
                    </a>
                  </li>
                  <li class="acco-doc">
                    PROCESO DE RENDICIÓN DE CUENTAS

                  </li>
                  <!--   <div *ngFor="let document of filterOnly(accountability.documentos)">
                    <li class="acco-doc">
                      <a [href]="document.url" target="_blank" style="text-decoration: underline">{{
                        document.nombre
                        }}</a>
                    </li>

                  </div> -->

                  <ul class="rc_ul">
                    <ul class="rc_ul">
                      <li>
                        Fase 0:
                        <ul class="rc_ul">
                          <li class="acco-doc">
                            <a style="text-decoration: underline; color:#182333">
                              Certificación de existencia de asamblea local ciudadana
                            </a>
                          </li>
                          <div *ngFor="let document of filterFS0(accountability.documentos)">
                            <li class="acco-doc">
                              <a [href]="document.url" target="_blank" style="text-decoration: underline">{{
                                document.nombre
                                }}</a>
                            </li>

                          </div>
                        </ul>
                      </li>
                    </ul>
                  </ul>
                  <ul class="rc_ul">
                    <ul class="rc_ul">
                      <li>
                        Fase 1:
                        <ul class="rc_ul">
                          <li class="acco-doc">
                            <a style="text-decoration: underline; color:#182333">
                              Diseño de la propuesta de rendición de cuentas
                            </a>
                          </li>
                          <li class="acco-doc">
                            <a style="text-decoration: underline; color:#182333">
                              Desarrollo de la consulta ciudadana
                            </a>
                          </li>
                          <li class="acco-doc">
                            <a style="text-decoration: underline; color:#182333">
                              Coordinación del mecanismo para la convocatoria de la participación ciudadana
                            </a>
                          </li>
                          <div *ngFor="let document of filterFS1(accountability.documentos)">
                            <li class="acco-doc">
                              <a [href]="document.url" target="_blank" style="text-decoration: underline">{{
                                document.nombre
                                }}</a>
                            </li>

                          </div>
                        </ul>
                      </li>
                    </ul>
                  </ul>
                  <ul class="rc_ul">
                    <ul class="rc_ul">
                      <li>
                        Fase 2:
                        <ul class="rc_ul">
                          <li class="acco-doc">
                            <a style="text-decoration: underline; color:#182333">
                              Redacción y socialización del informe rendición de cuentas
                            </a>
                          </li>
                          <li class="acco-doc">
                            <a style="text-decoration: underline; color:#182333">
                              Llenado de formulario
                            </a>
                          </li>
                          <div *ngFor="let document of filterFS2(accountability.documentos)">
                            <li class="acco-doc">
                              <a [href]="document.url" target="_blank" style="text-decoration: underline">{{
                                document.nombre
                                }}</a>
                            </li>

                          </div>
                        </ul>
                      </li>
                    </ul>
                  </ul>

                  <ul class="rc_ul">
                    <ul class="rc_ul">
                      <li>
                        Fase 3:
                        <ul class="rc_ul">
                          <li class="acco-doc">
                            <a style="text-decoration: underline; color:#182333">
                              Video de la deliberación pública
                            </a>
                          </li>
                          <li class="acco-doc">
                            <a style="text-decoration: underline; color:#182333">
                              Acta de acuerdos y compromisos
                            </a>
                          </li>
                          <div *ngFor="let document of filterFS3(accountability.documentos)">
                            <li class="acco-doc">
                              <a [href]="document.url" target="_blank" style="text-decoration: underline">{{
                                document.nombre
                                }}</a>
                            </li>

                          </div>
                        </ul>
                      </li>
                    </ul>
                  </ul>

                  <ul class="rc_ul">
                    <ul class="rc_ul">
                      <li>
                        Fase 4:
                        <ul class="rc_ul">

                          <div *ngFor="let document of filterFS4(accountability.documentos)">
                            <li class="acco-doc">
                              <a [href]="document.url" target="_blank" style="text-decoration: underline">{{
                                document.nombre
                                }}</a>
                            </li>

                          </div>
                         
                        </ul>
                      </li>
                    </ul>
                  </ul>

                </ul>

              </li>
              <!--  <div *ngFor="let document of onlyDocuments(accountability.documentos)">
                <li class="acco-doc">
                  <a [href]="document.url" target="_blank" style="text-decoration: underline">{{ document.nombre }}</a>
                </li>
              </div> -->

              <!-- <li class="acco-doc">
                <div *ngIf="document.nombre == 'POA'">
                  <h6>
                    <a [href]="document.url" target="_blank" style="text-decoration: underline">{{ document.nombre }}</a>
                  </h6>
                </div>
              </li> -->

            </ul>
          </div>
        </div>

        <ng-template #anotherYears>
          <div *ngIf="accountability.anio != 2023">
            <div class="acco-year">
              <h5 class="border-title mb-0">
                <span>{{ accountability.anio }}</span>
              </h5>
            </div>
            <div>
              <ul class="rc_ul">
                <li class="acco-doc" *ngFor="let document of accountability.documentos">
                  <h6>
                    <a [href]="document.url" target="_blank" style="text-decoration: underline">{{ document.nombre
                      }}</a>
                  </h6>
                </li>
              </ul>
            </div>
          </div>
        </ng-template>



      </div>
    </div>
  </div>
</section>