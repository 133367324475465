import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NewReclamoExternoService } from 'src/app/service/new-reclamo-externo/new-reclamo-externo.service';
import { NewReclamoExterno } from 'src/app/model/new-reclamo-externo/new-reclamo-externo.model';
import { exportarPDFReclamoExterno } from 'src/app/js-recursos/export-pdf-denuncias';
import * as L from 'leaflet';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MachalaEpService } from 'src/app/service/service/machala-ep.service';
import { Denuncia } from 'src/app/controller/denuncia-externa.inteface';

@Component({
  selector: 'app-denunciaciudad',
  templateUrl: './denunciaciudad.component.html',
  styleUrls: ['./denunciaciudad.component.scss'],
})
export class DenunciaciudadComponent implements OnInit {
  form: FormGroup;
  public reclamoExterno: NewReclamoExterno;
  beneficiario = 'B';
  public tipoId = [
    { nombre: 'CED', value: 1 },
    { nombre: 'RUC', value: 2 },
  ];
  tipoReclamo = [
    { id: '6', tipo: 'Fuga de agua' },
    { id: '7', tipo: 'Servicios de agua potable en parques y zonas públicas' },
    { id: '8', tipo: 'Colapso Alcantarilla' },
    { id: '9', tipo: 'Alcantarilla sin tapa' },
    { id: '10', tipo: 'Conexiones clandestinas' },
    { id: '11', tipo: 'Otros' },
  ];
  public latlong: string = '';
  // public latlongtemp: L.LatLng;
  public popup = L.popup();

  selectedOptionTI: string;
  map: any = null;

  constructor(
    private newReclamoExternoService: NewReclamoExternoService,
    private spinner: NgxSpinnerService,
    private machalaEp: MachalaEpService,
    private formBuilder: FormBuilder
  ) {}

  private initMap = (): void => {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      typeId: ['CED', Validators.required],
      id: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      phonePri: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      details: ['', [Validators.required]],
      address: ['', [Validators.required]],
      typeClaim: ['1', Validators.required],
      latlong: ['', Validators.required],
      petitioner: ['B', Validators.required],
      reference: ['', Validators.required],
    });
    document.getElementById('InsertMap').innerHTML =
      "<div id='map' style='height: 70vh;''></div>";
    document.getElementById('map').style.cursor = 'auto';
    this.map = L.map('map', {
      center: [-3.25861, -79.96053],
      zoom: 13,
      keyboard: false,
      boxZoom: false,
      touchZoom: false,
      dragging: true,
      scrollWheelZoom: true,
      doubleClickZoom: true,
    });
    const tiles = L.tileLayer(
      'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );
    tiles.addTo(this.map);
    this.map.on('click', (e) => {
      this.setCoordenadas(e.latlng);
      this.setDireccion(e.latlng);
      this.popup
        .setLatLng(e.latlng)
        .setContent('Ubicacion seleccionada')
        .openOn(this.map);
    });
  };

  ngOnInit(): void {
    if (document.getElementById('map')) document.getElementById('map').remove();
    this.initMap();
  }
  customOptionsServices: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 2500,
    loop: true,
    dots: false,
    margin: 25,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  public setCoordenadas(latlng) {
    this.latlong = latlng.lat + ' , ' + latlng.lng;
  }

  public setDireccion(latlng) {
    this.newReclamoExternoService
      .reverseGeocoding(latlng.lat, latlng.lng)
      .then((result) => {
        let addess = result['display_name'] || '';
        this.form.get('latlong').setValue(latlng.lat + ' , ' + latlng.lng);
        this.form
          .get('address')
          .setValue(
            `Latitud: ${latlng.lat},\nLongitud: ${latlng.lng},\nDireccion: ${addess}`
          );
      });
  }

  customOptionsServicios: OwlOptions = {
    dots: false,
    margin: 25,
    loop: true,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  public postNewDenunciaCiudad(
    nombres: string,
    id_usuario: string,
    tipo_id_usuario: string,
    email: string,
    telefono: string,
    categoria: string,
    direccion: string,
    latlong: string,
    descripcion: string,
    solicitante: string
  ) {
    var newReclamoEE = new NewReclamoExterno(
      nombres,
      tipo_id_usuario,
      id_usuario,
      email,
      telefono,
      direccion,
      descripcion,
      latlong,
      categoria,
      '2',
      solicitante
    );
    this.spinner.show();
    this.newReclamoExternoService
      .postNewReclamoExt(newReclamoEE)
      .subscribe((data) => {
        this.spinner.hide();
        if (data['message'] == 'Reclamo creado') {
          // ID: ' + data["reclamoid"]
          // alert('Reclamo creado');
          this.alertsSuccess('Reclamo creado');
          // exportarPDFReclamoExterno(data["reclamoid"], newReclamoEE);
          // location.reload();
        } else {
          // alert('El registro no se puede procesar, intenta seleccionando nuevamente el boton enviar, o recarga la pagina');
          this.alertsErrores(
            'El registro no se puede procesar, intenta seleccionando nuevamente el boton enviar, o recarga la página'
          );
        }
      });
  }
  public alertsErrores(mensaje: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: mensaje,
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
    });
  }
  public alertsSuccess(mensaje: string) {
    Swal.fire({
      icon: 'success',
      text: mensaje,
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
    }).then((result) => {
      if (result.isConfirmed) {
        location.reload();
      }
    });
  }
  public btnEnviarForm() {
    var ipt_identificador = (<HTMLInputElement>(
      document.getElementById('ipt_identificador')
    )).value;
    var slt_identificadord = (<HTMLInputElement>(
      document.getElementById('selectTID')
    )).value;
    var rbSeleccionado = this.getRadioButtonSelect('opcionServicio');
    var ipt_descripcion = (<HTMLInputElement>(
      document.getElementById('iptDescripcion')
    )).value;
    var ipt_telefono = (<HTMLInputElement>(
      document.getElementById('iptTelefono')
    )).value;
    //no necesitan validacion
    var ipt_nombres = (<HTMLInputElement>document.getElementById('iptNombres'))
      .value;
    var ipt_email = (<HTMLInputElement>document.getElementById('iptEmail'))
      .value;
    var ipt_direccion = (<HTMLInputElement>(
      document.getElementById('direccion_buscar')
    )).value;
    var solicitante = (<HTMLInputElement>(
      document.getElementById('iptSolicitante')
    )).value;

    var ipt_latlong = this.latlong;

    if (slt_identificadord == 'CED') {
      if (this.validarCedula(ipt_identificador)) {
        if (rbSeleccionado != '') {
          if (this.validarTelefonos(ipt_telefono)) {
            if (ipt_nombres.length > 0 && /\s/.test(ipt_nombres)) {
              if (this.validateEmail(ipt_email)) {
                if (ipt_descripcion.length != 0) {
                  if (ipt_latlong.length > 0) {
                    this.postNewDenunciaCiudad(
                      ipt_nombres,
                      ipt_identificador,
                      slt_identificadord,
                      ipt_email,
                      ipt_telefono,
                      rbSeleccionado,
                      ipt_direccion,
                      ipt_latlong,
                      ipt_descripcion,
                      solicitante
                    );
                  } else {
                    this.alertsErrores(
                      'Seleccione la ubicación con un solo click en el mapa, esto nos permitirá gestionar pertinentemente tu pedido'
                    );
                  }
                } else {
                  this.alertsErrores(
                    'Tu reclamo debe contener la sección de descripción, esto nos permitirá gestionar pertinentemente tu pedido'
                  );
                }
              } else {
                this.alertsErrores(
                  'Tu reclamo debe contener un correo electrónico válido, esto nos permitirá gestionar pertinentemente tu pedido'
                );
              }
            } else {
              this.alertsErrores(
                'Tu reclamo debe contener tu(s) nombre(s) y apellido(s), esto nos permitirá gestionar pertinentemente tu pedido'
              );
            }
          } else {
            this.alertsErrores(
              'Debes proveer un número de contacto (teléfono fijo o móvil)'
            );
          }
        } else {
          this.alertsErrores('Debes seleccionar una categoría de reclamo');
        }
      } else {
        this.alertsErrores('La ' + slt_identificadord + ' no es válida');

        (<HTMLInputElement>document.getElementById('ipt_identificador')).value =
          '';
      }
    } else if (slt_identificadord == 'RUC') {
      if (this.validarRUC(ipt_identificador)) {
        if (rbSeleccionado != '') {
          if (this.validarTelefonos(ipt_telefono)) {
            if (ipt_nombres.length > 0 && /\s/.test(ipt_nombres)) {
              if (this.validateEmail(ipt_email)) {
                if (ipt_descripcion.length != 0) {
                  if (ipt_latlong.length > 0) {
                    this.postNewDenunciaCiudad(
                      ipt_nombres,
                      ipt_identificador,
                      slt_identificadord,
                      ipt_email,
                      ipt_telefono,
                      rbSeleccionado,
                      ipt_direccion,
                      ipt_latlong,
                      ipt_descripcion,
                      solicitante
                    );
                  } else {
                    this.alertsErrores(
                      'Seleccione la ubicación con un solo click en el mapa, esto nos permitirá gestionar pertinentemente tu pedido'
                    );
                  }
                } else {
                  this.alertsErrores(
                    'Tu reclamo debe contener la sección de descripción, esto nos permitirá gestionar pertinentemente tu pedido'
                  );
                }
              } else {
                this.alertsErrores(
                  'Tu reclamo debe contener un correo electrónico válido, esto nos permitirá gestionar pertinentemente tu pedido'
                );
              }
            } else {
              this.alertsErrores(
                'Tu reclamo debe contener tu(s) nombre(s) y apellido(s), esto nos permitirá gestionar pertinentemente tu pedido'
              );
            }
          } else {
            this.alertsErrores(
              'Debes proveer un número de contacto (teléfono fijo o móvil)'
            );
          }
        } else {
          this.alertsErrores('Debes seleccionar una categoría de reclamo');
        }
      } else {
        this.alertsErrores('El ' + slt_identificadord + ' no es válido');
        (<HTMLInputElement>document.getElementById('ipt_identificador')).value =
          '';
      }
    } else {
      (<HTMLInputElement>document.getElementById('ipt_identificador')).value =
        '';
      this.alertsErrores('Prueba refrescando la página');
    }
  }

  public validarTelefonos(ipt_Telefono: string) {
    if (ipt_Telefono.length == 0) {
      return false;
    } else {
      return true;
    }
  }
  public getRadioButtonSelect(name: string) {
    var off_payment_method = document.getElementsByName(name);
    var ischecked_method = '';
    for (var i = 0; i < off_payment_method.length; i++) {
      const temp = off_payment_method[i] as HTMLInputElement;
      if (temp.checked) {
        ischecked_method = temp.value;
        break;
      }
    }
    return ischecked_method;
  }

  public validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }

  public validarCedula(cedula: string) {
    if (cedula.length === 10) {
      const digitoRegion = cedula.substring(0, 2);
      if (digitoRegion >= String(0) && digitoRegion <= String(24)) {
        const ultimoDigito = Number(cedula.substring(9, 10));
        const pares =
          Number(cedula.substring(1, 2)) +
          Number(cedula.substring(3, 4)) +
          Number(cedula.substring(5, 6)) +
          Number(cedula.substring(7, 8));
        let numeroUno: any = cedula.substring(0, 1);
        numeroUno = numeroUno * 2;
        if (numeroUno > 9) {
          numeroUno = numeroUno - 9;
        }
        let numeroTres: any = cedula.substring(2, 3);
        numeroTres = numeroTres * 2;
        if (numeroTres > 9) {
          numeroTres = numeroTres - 9;
        }
        let numeroCinco: any = cedula.substring(4, 5);
        numeroCinco = numeroCinco * 2;
        if (numeroCinco > 9) {
          numeroCinco = numeroCinco - 9;
        }
        let numeroSiete: any = cedula.substring(6, 7);
        numeroSiete = numeroSiete * 2;
        if (numeroSiete > 9) {
          numeroSiete = numeroSiete - 9;
        }
        let numeroNueve: any = cedula.substring(8, 9);
        numeroNueve = numeroNueve * 2;
        if (numeroNueve > 9) {
          numeroNueve = numeroNueve - 9;
        }
        const impares =
          numeroUno + numeroTres + numeroCinco + numeroSiete + numeroNueve;
        const sumaTotal = pares + impares;
        const primerDigitoSuma = String(sumaTotal).substring(0, 1);
        const decena = (Number(primerDigitoSuma) + 1) * 10;
        let digitoValidador = decena - sumaTotal;
        if (digitoValidador === 10) {
          digitoValidador = 0;
        }
        if (digitoValidador === ultimoDigito) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  public validarRUC(ruc: string) {
    if (ruc.length == 13) {
      return true;
    } else {
      return false;
    }
  }

  public inputNumberValidator(event: any) {
    //console.log(event.target.value);
    const pattern = /^[0-9]*$/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
      // invalid character, prevent input
    }
  }

  guardarReclamo() {
    if (this.form.valid) {
      const reclamo: Denuncia = {
        nombre: this.form.get('name').value,
        tipoid: this.form.get('typeId').value,
        id: this.form.get('id').value,
        email: this.form.get('email').value,
        telefono1: this.form.get('phonePri').value,
        direccion: this.form.get('address').value,
        referencia: this.form.get('reference').value,
        latlong: this.form.get('latlong').value,
        descripcion: this.form.get('details').value,
        categoria: this.form.get('typeClaim').value,
        tiporeclamo: 2,
        tiposolicitante: this.form.get('petitioner').value,
      };

      this.machalaEp.guardarDenuncia(reclamo).subscribe(
        (resp) => {
          this.alertsSuccess('Reclamo creado exitosamente');
        },
        (err) => {
          this.alertsErrores(err.error.message);
        }
      );
    } else {
      this.validForm();
    }
  }

  get invalidName(): boolean {
    return this.form.get('name').invalid && this.form.get('name').touched;
  }

  get invalidEmail(): boolean {
    return this.form.get('email').invalid && this.form.get('email').touched;
  }
  get invalidId(): boolean {
    return this.form.get('id').invalid && this.form.get('id').touched;
  }
  get invalidPriPhone(): boolean {
    return (
      this.form.get('phonePri').invalid && this.form.get('phonePri').touched
    );
  }

  get invaliPhoneAdd(): boolean {
    return (
      this.form.get('phoneAdd').invalid && this.form.get('phoneAdd').touched
    );
  }

  get invalidDetails(): boolean {
    return this.form.get('details').invalid && this.form.get('details').touched;
  }

  get invalidTypeClaim(): boolean {
    return (
      this.form.get('typeClaim').invalid && this.form.get('typeClaim').touched
    );
  }
  get invalidPetitioner(): boolean {
    return (
      this.form.get('petitioner').invalid && this.form.get('petitioner').touched
    );
  }

  get invalidAddress(): boolean {
    return this.form.get('address').invalid && this.form.get('address').touched;
  }

  get invalidReference(): boolean {
    return (
      this.form.get('reference').invalid && this.form.get('reference').touched
    );
  }

  validForm(): void {

    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }
  }
}
