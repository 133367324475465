import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';
import { ConsultaDeuda } from '../../model/consulta-deuda/consulta-deuda.model';

@Injectable({
  providedIn: 'root'
})
export class ConsultaDeudaService {
  httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };
  URL = 'https://www.aguasmachala.com:3000';
  constructor(private httpClient: HttpClient) { }
  concatURL(subURL: string): string {
    return this.URL + subURL;
  }

  makePostRequest(
    URL: string,
    data: any,
    headers = this.httpOptions
  ): Observable<any> {
    return this.httpClient.post(URL, data, headers);
  }

  makeGetRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.httpClient.get(URL, headers);
  }

  makeDelRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.httpClient.delete(URL, headers);
  }

  makePutRequest(URL: string, data: any, headers = this.httpOptions): Observable<any> {
    return this.httpClient.put(URL, data, headers);
  }


  public getDeudaClienteId(tipoId: string, id: string): Observable<any> {
    const URL = this.concatURL(`/api/v1/oracle/deudas?tipoid=${tipoId}&id=${id}`);
    return this.makeGetRequest(URL);
  }


  public getAllDeudas(tipoId: string, id: string): Observable<any> {
    // // var api_uri = environment.API_URL.toString() + `get_consulta_deudas/${tipoid}/${id}`;
    // var api_uri ='https://machalaep-server.herokuapp.com/deudas';
    // // var api_uri ='/deudas';
    // return this.httpClient.get<ConsultaDeuda[]>(api_uri).pipe(
    //   map(data => data.map(data => new ConsultaDeuda().deserialize(data)))
    // );'

    const URL = this.concatURL(`/api/v1/oracle/deudas?tipoid=${tipoId}&id=${id}`);
    return this.makeGetRequest(URL);
  }
  public getLastPlanillas(tipoid:String, id:String): Observable<ConsultaDeuda[]> {
    // var api_uri = environment.API_URL.toString() + `get_consulta_planillas/${tipoid}/${id}`;
    var api_uri ='https://www.aguasmachala.com:3000/planillas';
    // var api_uri ='/planillas';
    return this.httpClient.get<ConsultaDeuda[]>(api_uri).pipe(
      map(data => data.map(data => new ConsultaDeuda().deserialize(data)))
    );
  }
}

