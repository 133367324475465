import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Logro } from '../../model/logro/logro.model';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public logros: Logro[];
  public anioActual: string;
  public diferenciaAnios: string;
  customOptionsAutoridades: OwlOptions;
  customOptionsMain: OwlOptions;
  customOptionsNews: OwlOptions;
  customOptionsServices: OwlOptions;
  customOptionsTramites: OwlOptions;
  customOptionsTestimonios: OwlOptions;
  constructor(private spinner: NgxSpinnerService) {
    this.anioActual = new Date().getFullYear().toString();
    this.diferenciaAnios = (new Date().getFullYear() - 2015 - 1).toString();

    /**Swal.fire({
      title: 'Aguas Machala EP',
      confirmButtonText: `Saltar Bienvenida`,
      confirmButtonColor: '#182333',
      html:
        '<iframe width="100%" height="300" src="https://www.youtube.com/embed/6D2-kRIHTPw" frameborder="0"></iframe>',
    });
    */
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
    setTimeout(() => {
      this.customOptionsAutoridades = {
        autoplay: true,
        autoplayTimeout: 7000,
        loop: true,
        dots: true,
        margin: 25,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 1,
          },
        },
      };
      this.customOptionsMain = {
        autoplay: true,
        autoplayTimeout: 7000,
        dots: true,
        loop: true,
        lazyLoad: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 1,
          },
        },
      };

      this.customOptionsNews = {
        autoplay: true,
        autoplayTimeout: 5000,
        dots: true,
        loop: true,
        margin: 25,
        navSpeed: 700,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 3,
          },
        },
      };

      this.customOptionsServices = {
        autoplay: true,
        autoplayTimeout: 5500,
        loop: true,
        dots: true,
        margin: 25,
        responsive: {
          0: {
            items: 1,
          },
          480: {
            items: 1,
          },
          768: {
            items: 3,
          },
          1200: {
            items: 3,
          },
        },
      };

      this.customOptionsTramites = {
        autoplay: true,
        autoplayTimeout: 5500,
        loop: true,
        dots: true,
        margin: 25,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      };

      this.customOptionsTestimonios = {
        autoplay: true,
        autoplayTimeout: 7500,
        loop: true,
        margin: 25,
        dots: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 4,
          },
        },
      };
    }, 300);
  }

  ngAfterViewInit(): void {}

  public abrirVideo(url: string) {
    var htmlV =
      '<iframe width="100%" height="300" src="' +
      url +
      '" frameborder="0"></iframe>';
    Swal.fire({
      title: 'Aguas Machala EP',
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
      html: htmlV,
      footer:
        '<p style="text-align: center;">¿Quieres ver más videos? <br><a style="font-weight:bold; text-decoration:underline;" target="_blank" href="https://www.youtube.com/channel/UCZjLjxrrb6OGH-BUP9PESdw">Revisa nuestro canal de Youtube</a></p>',
    });
  }

  public abrirImagen(url: string) {
    Swal.fire({
      title: 'Aguas Machala EP',
      confirmButtonText: `Cerrar`,
      confirmButtonColor: '#182333',
      html: `<div><img class="img-fluid" src="${url}"></div>`,
    });
  }
}
