import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ConsultaReclamoService } from 'src/app/service/consulta-reclamo/consulta-reclamo.service';
import { ConsultaReclamo } from 'src/app/model/consulta-reclamo/consulta-reclamo.model';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-consult-claims',
  templateUrl: './consult-claims.component.html',
  styleUrls: ['./consult-claims.component.scss'],
})
export class ConsultClaimsComponent implements OnInit {
  public reclamos: ConsultaReclamo[];
  public consulta_reclamo: ConsultaReclamo;
  selectedOption: string;
  printedOption: string;
  identificaciones = [];
  options = [
    { name: 'CI', value: 1 },
    { name: 'Nro. Cuenta', value: 2 },
    { name: 'RUC', value: 3 },
  ];
  page: number = 1;
  constructor(
    private consultaReclamoService: ConsultaReclamoService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
  }

  customOptionsServicios: OwlOptions = {
    dots: false,
    margin: 25,
    loop: true,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  public getAllData() {
    this.reclamos = [];
    var ipt_identificador = (<HTMLInputElement>(
      document.getElementById('ipt_identificador')
    )).value;
    var slt_identificadord = (<HTMLInputElement>(
      document.getElementById('selectIdentificadorD')
    )).value;
    if (slt_identificadord == 'CI') {
      slt_identificadord = 'CED';
      if (this.validarCedula(ipt_identificador)) {
        this.spinner.show();
        this.consultaReclamoService
          .getAllReclamos(slt_identificadord, ipt_identificador)
          .subscribe((reclamos) => {
            reclamos.forEach((reclamo) => {
              var fechaTemp = new Date(reclamo.fecha);
              reclamo.fecha =
                fechaTemp.getDate().toString() +
                '/' +
                fechaTemp.getMonth().toString() +
                '/' +
                fechaTemp.getFullYear().toString();
              var tipoTemp = reclamo.tipo;
              reclamo.tipo = tipoTemp;
            });
            this.reclamos = reclamos;
          });

        (<HTMLInputElement>document.getElementById('ipt_identificador')).value =
          '';
        this.identificaciones = [];
        this.identificaciones.push({
          tipoid: slt_identificadord,
          id: ipt_identificador,
        });
        setTimeout(() => {
          this.spinner.hide();
        }, 1500);
      } else {
        this.alertsErrores('La cédula no es válida');
        (<HTMLInputElement>document.getElementById('ipt_identificador')).value =
          '';
      }
    } else if (slt_identificadord == 'Nro. Cuenta') {

      if (this.validarNroCuenta(ipt_identificador)) {
        this.spinner.show();
        this.consultaReclamoService
          .getAllReclamos('NRO', ipt_identificador)
          .subscribe((reclamos) => {
            reclamos.forEach((reclamo) => {
              var fechaTemp = new Date(reclamo.fecha);
              reclamo.fecha =
                fechaTemp.getDate().toString() +
                '/' +
                fechaTemp.getMonth().toString() +
                '/' +
                fechaTemp.getFullYear().toString();
              var tipoTemp = reclamo.tipo;
              reclamo.tipo = tipoTemp;
            });
            this.reclamos = reclamos;
          });
        (<HTMLInputElement>document.getElementById('ipt_identificador')).value =
          '';
        this.identificaciones = [];
        this.identificaciones.push({ tipoid: 'NRO', id: ipt_identificador });
        setTimeout(() => {
          this.spinner.hide();
        }, 1500);
      } else {
        this.alertsErrores('El Nro. Cuenta no es válido');
        (<HTMLInputElement>document.getElementById('ipt_identificador')).value =
          '';
      }
    } else if (slt_identificadord == 'RUC') {
      this.spinner.show();
      this.consultaReclamoService
        .getAllReclamos(slt_identificadord, ipt_identificador)
        .subscribe((reclamos) => {
          reclamos.forEach((reclamo) => {
            var fechaTemp = new Date(reclamo.fecha);
            reclamo.fecha =
              fechaTemp.getDate().toString() +
              '/' +
              fechaTemp.getMonth().toString() +
              '/' +
              fechaTemp.getFullYear().toString();
            var tipoTemp = reclamo.tipo;
            reclamo.tipo = tipoTemp;
          });
          this.reclamos = reclamos;
        });
      (<HTMLInputElement>document.getElementById('ipt_identificador')).value =
        '';
      this.identificaciones = [];
      this.identificaciones.push({
        tipoid: slt_identificadord,
        id: ipt_identificador,
      });
      setTimeout(() => {
        this.spinner.hide();
      }, 1500);
    } else {
      (<HTMLInputElement>document.getElementById('ipt_identificador')).value =
        '';
      this.alertsErrores('Prueba refrescando la página');
    }
  }
  public guiaNroCuenta() {
    Swal.fire({
      title: '¿Dónde veo mi número de cuenta?',
      text: 'Revisa tu factura/guía de pago, encuentras tu Nro. Cuenta en la parte superior derecha.',
      imageUrl: '/assets/images/optimizadas_machalaep/nrocuenta.webp',
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
      imageAlt: 'Ver número de cuenta',
    });
  }
  public alertsErrores(mensaje: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: mensaje,
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
    });
  }

  public validarCedula(cedula: string) {
    if (cedula.length === 10) {
      const digitoRegion = cedula.substring(0, 2);
      if (digitoRegion >= String(0) && digitoRegion <= String(24)) {
        const ultimoDigito = Number(cedula.substring(9, 10));
        const pares =
          Number(cedula.substring(1, 2)) +
          Number(cedula.substring(3, 4)) +
          Number(cedula.substring(5, 6)) +
          Number(cedula.substring(7, 8));
        let numeroUno: any = cedula.substring(0, 1);
        numeroUno = numeroUno * 2;
        if (numeroUno > 9) {
          numeroUno = numeroUno - 9;
        }
        let numeroTres: any = cedula.substring(2, 3);
        numeroTres = numeroTres * 2;
        if (numeroTres > 9) {
          numeroTres = numeroTres - 9;
        }
        let numeroCinco: any = cedula.substring(4, 5);
        numeroCinco = numeroCinco * 2;
        if (numeroCinco > 9) {
          numeroCinco = numeroCinco - 9;
        }
        let numeroSiete: any = cedula.substring(6, 7);
        numeroSiete = numeroSiete * 2;
        if (numeroSiete > 9) {
          numeroSiete = numeroSiete - 9;
        }
        let numeroNueve: any = cedula.substring(8, 9);
        numeroNueve = numeroNueve * 2;
        if (numeroNueve > 9) {
          numeroNueve = numeroNueve - 9;
        }
        const impares =
          numeroUno + numeroTres + numeroCinco + numeroSiete + numeroNueve;
        const sumaTotal = pares + impares;
        const primerDigitoSuma = String(sumaTotal).substring(0, 1);
        const decena = (Number(primerDigitoSuma) + 1) * 10;
        let digitoValidador = decena - sumaTotal;
        if (digitoValidador === 10) {
          digitoValidador = 0;
        }
        if (digitoValidador === ultimoDigito) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  public validarNroCuenta(nrocuenta: string) {
    return true;
  }

  changeBackground(state: string) {
    switch (state) {
      case 'Ingresado':
        return 'bg-primary';
      case 'Aceptado':
        return 'bg-success';
      case 'Rechazado':
        return 'bg-danger';
      default:
        return 'bg-secondary';
    }
  }

  changeTitle(state: string) {
    switch (state) {
      case 'Ingresado':
        return 'Ingresado';
      case 'Aceptado':
        return 'Aceptado';
      case 'Rechazado':
        return 'Rechazado';
      default:
        return state;
    }
  }

  public abrirObservacion(content: string) {
    Swal.fire({
      title: 'Aguas Machala EP',
      confirmButtonText: `Cerrar`,
      confirmButtonColor: '#182333',
      html: ` <div class="info-claim">
      <strong>Observaciones</strong><br>
      <p>${content}</p>
    </div>`,
    });
  }
}
