import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-slidertramites',
  templateUrl: './slidertramites.component.html',
  styleUrls: ['./slidertramites.component.scss']
})
export class SlidertramitesComponent implements OnInit {
  customOptionsServices: OwlOptions;
  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {

      this.customOptionsServices = {
        autoplay: true,
        autoplayTimeout: 5500,
        loop: true,
        dots: true,
        margin: 25,
        responsive: {
          0: {
            items: 1,
          },
          480: {
            items: 1,
          },
          768: {
            items: 3
          },
          1200: {
            items: 3
          }
        }
      }
    })

  }


}
