import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Announcement } from 'src/app/controller/announcemet.interface';

@Component({
  selector: 'app-announcemet',
  templateUrl: './announcemet.component.html',
  styleUrls: ['./announcemet.component.scss']
})
export class AnnouncemetComponent implements OnInit {
  announcement: Announcement;

  constructor(private readonly route: ActivatedRoute) { }

  ngOnInit(): void {
    const data: any = this.route.snapshot.data;
    if ('announcemet' in data) {
      const announcemet = data['announcemet'] as Announcement;
      this.announcement = announcemet;
    }
  }

  public verifyTitle(title: string) {
    const aux = title.split("/")[1];
    if (aux === " Convocatoria pruebas psicométricas"|| aux === " Convocatoria a Pruebas Técnicas") {
      return true;
    } else {
      return false;
    }
  }


  public deleteLetter(title: string) {
   return title.slice(2);
  }

  public viewTitle(title: string) {
   return title.split("/")[0].slice(2);
  }

  public viewSubTitle(title: string) {
   const letter = title.split("/")
   if(letter.length > 1){
     return title.split("/")[1];
   }else{
    return title;
   }
  }
}
