import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { AnnouncemetHttpService } from '../../service/announcemet/announcemet.http.service';
import { Announcement } from 'src/app/controller/announcemet.interface';
import { BehaviorSubject } from 'rxjs';
declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  announData$: BehaviorSubject<Announcement[]>;

  constructor( 
    private router: Router,
    private scrollToService: ScrollToService,
    private announcemetService: AnnouncemetHttpService,

    ) { 
      this.announData$ = new BehaviorSubject<Announcement[]>([]);
    }

  ngOnInit(): void {
    this.announcemetService.getAll()
    .subscribe((next) => {
      this.announData$.next(next.announcements);
      console.log(this.announData$.value);
      
    })
  }

  viewHeader(title:string):boolean{
    if(title === "PLANIFICACIÓN DE CONCURSOS 2023"){
      return true;
    }else{
      return false;
    }
  }

  verLotaip(): void {
    if(this.router.url.includes('/transparencia')) {
      this.triggerScrollTo();
    } else {
      this.router.navigate(["/transparencia"], {fragment: 'lotaip'})
    }

  }
  triggerScrollTo() {

    const config: ScrollToConfigOptions = {
      target: 'lotaip',
      offset: -115
    };

    this.scrollToService.scrollTo(config);
  }

  viewAnnouncemet(id: string): void {
    //this.router.navigate(['/convocatoria/ver-convocatoria', id]);
    this.router.navigateByUrl(`/convocatoria/ver-convocatoria/${id}`, { replaceUrl: true }).then(() => {
      // Forzar la recarga de la página
      window.location.reload()
    });
  }

  verCuentasRen(): void {
    if(this.router.url.includes('/transparencia')) {
      this.triggerScrollToB();
    } else {
      this.router.navigate(["/transparencia"], {fragment: 'cuentas-ren'})
    }

  }

  triggerScrollToB() {

    const config: ScrollToConfigOptions = {
      target: 'cuentas-ren',
      offset: -115
    };

    this.scrollToService.scrollTo(config);
  }
  


  ngAfterViewInit(): void {

    $(document).ready(function () {

      'use strict'

      $('ul li:has(ul)').addClass('has-submenu');
      $('ul li ul').addClass('sub-menu');

      $('ul.dropdown li').hover(function () {
        $(this).addClass('hover');

      },
        function () {
          $(this).removeClass('hover');
        });

      var $menu = $('#menu'),
      $menulink = $('#menu-toggle-form'),
      $menuitem = $('.nav-route'),
      $menuTrigger = $('.has-submenu > a');
      
      $menuitem.click(function (e) {
        // $menulink.toggleClass('active');
        $menu.toggleClass('active');
      });
      
      $menulink.click(function (e) {
          // $menulink.toggleClass('active');
          $menu.toggleClass('active');
      });

      $menuTrigger.click(function (e) {
        e.preventDefault();
        var t = $(this);
        t.toggleClass('active').next('ul').toggleClass('active');
      });

      $('ul li:has(ul)');
    });

  }

}
