<div class="ttm-page-title-row">
  <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title-box ttm-textcolor-white">
          <div class="page-title-heading">
            <h1 class="title">Plan Habitacional</h1>
          </div>
          <div class="breadcrumb-wrapper">
            <span>
              <a title="Homepage" routerLink="home"
                ><i class="ti ti-home"></i
              ></a>
            </span>
            <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
            <span
              ><span style="color: #0095d9; font-weight: bold"
                >Vivienda</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="site-main">
  <section class="ttm-row">
    <div class="container" style="margin-bottom: 2rem">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div
            class="section-title with-desc title-style-center_text mb-55 clearfix"
          >
            <div class="title-header">
              <h2 class="title">Plan Habitacional</h2>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="row mt-15">
        <div class="col-12">
          <div class="image-center-2">
            <img
              loading="lazy"
              class="img-fluid"
              src="/assets/images/vivienda.png"
              alt=""
            />
          </div>
        </div>
        <div class="col-12 mt-4 text-justify">
          <p>
            Aguas Machala EP, junto al Alcalde Darío Macas y la empresa privada
            se encuentra promoviendo la construcción de viviendas de interés
            social al alcance de todos nuestros hermanos machaleños.
            <br />
            Existen 3 tipos de viviendas a las cuales puedes aplicar en base a
            tus gastos y a tu nivel adquisitivo. Para conocer más sobre los
            requisitos debes ingresar a www.dariomacas.com o puedes acercarte a
            nuestro punto de información ubicado en las oficinas de Aguas
            Machala EP (9 De Mayo e/. Manuel Serrano y Arízaga) y en las casas
            modelos de la urbanización Miracielo (Av. Circunvalación Norte,
            ingresando por el puente San Estuardo, de la ciudad de Machala)
          </p>
          <h5>REQUISITOS PARA APLICAR AL PLAN DE VIVIENDA</h5>
          <!-- <p>
                        En cumplimiento de la Ley Orgánica de Transparencia y Acceso a la Información Pública (LOTAIP),
                        todas las entidades poseedoras de información pública están obligadas a difundir a través de su
                        portal institucional, la siguiente información mínima actualizada.​​
                    </p> -->
          <ul>
            <li style="list-style-type: square">
              Comprobar 2 años de residencia en la ciudad de Machala, mediante:
              Contrato de arrendamiento, más planillas de servicio básico,
              energía eléctrica y agua potable.
            </li>
            <li style="list-style-type: square">
              No tener vivienda, departamento o terreno en el cantón Machala.
            </li>
            <li style="list-style-type: square">
              El postulante deberá tener ingresos FAMILIARES inferiores a dos
              salarios mínimos (aplica según el tipo de vivienda).
            </li>
            <li style="list-style-type: square">
              Ser sujeto de crédito (buró de crédito).
            </li>
            <li style="list-style-type: square">
              No estar en la central de riegos.
            </li>
            <li style="list-style-type: square">
              Impresión del certificado de inscripción descargado de la página
              web.
            </li>
          </ul>

          Para obtener tu certificado de inscripción y más información
          <a
            class="text-primary"
            href="https://dariomacas.ec/plan-vivienda"
            target="_blank"
            >pulsa aquí</a
          >
        </div>
      </div>
      <div>
        <div class="video">
          <iframe
            src="https://www.youtube.com/embed/KADRBs8uXwQ"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</div>
