import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { Noticias } from 'src/app/model/noticias/noticias.model';
import { MachalaEpService } from 'src/app/service/service/machala-ep.service';
import { NoticiasService } from '../../service/noticias/noticias.service';
@Component({
  selector: 'app-slidenews',
  templateUrl: './slidenews.component.html',
  styleUrls: ['./slidenews.component.scss'],
})
export class SlidenewsComponent implements OnInit {
  public noticias: Noticias[];
  public categorias;
  public urls_imagenes;
  public urls_videos;
  page: number = 1;
  constructor(
    private noticiasService: NoticiasService,
    private spinner: NgxSpinnerService,
    private machalaEp: MachalaEpService
  ) {}

  ngOnInit(): void {
    this.getNoticias();
  }
  customUrlsNews: OwlOptions = {
    dots: false,
    margin: 25,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  customImgsNews: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 3000,
    dots: false,
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    margin: 25,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  customTagsNews: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 4000,
    dots: false,
    loop: true,
    items: 2,
    margin: 1,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  customNews: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 7000,
    dots: true,
    loop: true,
    items: 1,
    margin: 25,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };
  public getLastNoticias() {
    this.spinner.show();
    this.noticiasService.getAllNoticias().subscribe((noticias) => {
      this.noticias = noticias;
      this.noticias.forEach((not, i) => {
        not.fecha =
          new Date(this.noticias[i].fecha).getDate() +
          '/' +
          String(new Date(this.noticias[i].fecha).getMonth() + 1) +
          '/' +
          new Date(this.noticias[i].fecha).getFullYear();
        var resumenCut = not.resumen.split(' ');

        if (resumenCut.length > 43) {
          var newJoin = resumenCut.slice(0, 42);
          not.resumen = newJoin.join(' ');
        }
      });
    });
    this.spinner.hide();
  }

  public getNoticias() {
    this.machalaEp.obtenerNoticias().subscribe((resp) => {
      // console.log(resp);
      this.noticias = resp;
    });
  }
}
