import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './view/home/home.component';
import { Page404Component } from './view/page404/page404.component';
import { NewsComponent } from './view/news/news.component';
import { SingleNewsComponent } from './view/single-news/single-news.component';
import { ServicesComponent } from './view/services/services.component';
import { AboutUsComponent } from './view/about-us/about-us.component';
import { ContactComponent } from './view/contact/contact.component';
import { TransparencyComponent } from './view/transparency/transparency.component';
// import { BiddingComponent } from './view/bidding/bidding.component';
import { SingleServiceComponent } from './view/single-service/single-service.component';
import { ConsultDebtsComponent } from './view/consult-debts/consult-debts.component';
import { ConsultClaimsComponent } from './view/consult-claims/consult-claims.component';
import { ReclamoComponent } from './view/reclamo/reclamo.component';
import { DenunciaciudadComponent } from './view/denunciaciudad/denunciaciudad.component';
import { ViviendaComponent } from './view/vivienda/vivienda.component';
import { WelcomeComponent } from './view/welcome/welcome.component';
import { PostPaymentComponent } from './view/post-payment/post-payment.component';
import { TermsComponent } from './view/information/terms/terms.component';
import { PrivacyComponent } from './view/information/privacy/privacy.component';
import { AnnouncemetComponent } from './view/announcemet/announcemet.component';
import { AnnouncemetResolver } from './view/announcemet/announcemet.resolver';
import { TableInfoComponent } from './view/announcemet/table-info/table-info.component';

const routes: Routes = [
  { path: 'bienvenido', component: WelcomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'noticias', component: NewsComponent },
  { path: 'noticia/:id', component: SingleNewsComponent },
  { path: 'servicios', component: ServicesComponent },
  { path: 'servicio/:id', component: SingleServiceComponent },
  /* { path: 'consultadeudas', component: ConsultDebtsComponent }, */
  { path: 'consultareclamos', component: ConsultClaimsComponent },
  { path: 'transparencia', component: TransparencyComponent },
  { path: 'nosotros', component: AboutUsComponent },
  { path: 'vivienda', component: ViviendaComponent },
  { path: 'contacto', component: ContactComponent },
 /*  { path: 'reclamo', component: ReclamoComponent }, */
  { path: 'pagoenlinea', component: PostPaymentComponent },
  { path: 'terminos', component: TermsComponent },
  { path: 'privacidad', component: PrivacyComponent },
 /*  { path: 'denunciaciudad', component: DenunciaciudadComponent }, */
  {
    path: 'convocatoria', children: [
      {
        path: 'ver-convocatoria/:id', 
        component: AnnouncemetComponent, 
        resolve: {
          announcemet: AnnouncemetResolver
        }
      },
      {
        path: 'info-table', 
        component: TableInfoComponent, 
      }
    ]
  },
  { path: '', redirectTo: 'bienvenido', pathMatch: 'full' },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
