<ngx-spinner></ngx-spinner>
<div class="ttm-page-title-row">
  <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title-box ttm-textcolor-white">
          <div class="page-title-heading">
            <h1 class="title">Servicios</h1>
          </div>
          <div class="breadcrumb-wrapper">
            <span>
              <a title="Homepage" routerLink="/"><i class="ti ti-home"></i></a>
            </span>
            <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
            <span
              ><span style="color: #0095d9; font-weight: bold"
                >Trámites presenciales</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="site-main single">
  <div
    class="sidebar ttm-sidebar-right ttm-bgcolor-white break-991-colum clearfix"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12 content-area">
          <article class="post ttm-blog-classic">
            <div class="ttm-blog-classic-box-content">
              <div class="entry-content">
                <div class="ttm-box-desc-text">
                  <h2 class="title" style="text-align: center">
                    {{ listNames[id] }}
                  </h2>
                  <p>
                    El proceso para solicitar este servicio es presencial
                    <a
                      routerLink="/contacto"
                      style="font-weight: bold; text-decoration: underline"
                      >en nuestras oficinas</a
                    >
                    y necesitas presentar los siguientes requisitos: <br />
                  </p>

                  <ul>
                    <li *ngFor="let requisito of jsonServicio[id]">
                      {{ requisito }}
                    </li>
                    <li
                      *ngIf="
                        listNames[id] ===
                        'EXONERACIÓN POR TERCERA EDAD O DISCAPACIDAD'
                      "
                    >
                      Descarga el
                      <a
                        style="text-decoration: underline"
                        href="/assets/documents/FORMULARIO-DE-EXONERACIONES-APROBADO.xlsx"
                        >formulario de registro para exoneraciones.</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="ttm-row blog2-section clearfix">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="section-title with-desc title-style-center_text clearfix">
          <div class="title-header">
            <h5>SERVICIOS ONLINE</h5>
            <h2 class="title">GESTIONES A UN CLICK DE DISTANCIA</h2>
          </div>
          <div class="title-desc">
            Realiza gestiones, consultas y pagos desde la comodidad de tu hogar.
            <br />
            Revisa nuestra bitácora de servicios en línea.
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="">
      <app-slidertramites></app-slidertramites>
    </div>
  </div>
</section>
<section class="ttm-row services2-section bg-img4 clearfix">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="section-title with-desc title-style-center_text clearfix">
          <div class="title-header">
            <h5>NECESITO HACER UN TRÁMITE PRESENCIAL</h5>
            <h2 class="title">PROCESOS INTERNOS</h2>
          </div>
          <div class="title-desc">
            Encuentra en esta sección los pasos a seguir en procesos internos de
            la empresa, te indicaremos como crear una cuenta, como pedir una
            instalación de medidor, como actualizar datos, entre otros procesos.
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="">
      <app-sliderservices></app-sliderservices>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="mt-40 text-center">
          <h6 class="ttm-textcolor-grey">
            Revisa todos los procesos que puedes realizar
            <strong
              ><u
                ><a class="ttm-textcolor-dark" routerLink="/servicios"
                  >en esta sección</a
                ></u
              ></strong
            >
          </h6>
        </div>
      </div>
    </div>
  </div>
</section>
