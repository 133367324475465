import { PaymentService } from './../../service/service/payment.service';
import { PaymentComponent } from './../payment/payment.component';
import { DatosDeuda } from './../../model/user-data';
import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ConsultaDeuda } from 'src/app/model/consulta-deuda/consulta-deuda.model';
import { ConsultaDeudaService } from '../../service/consulta-deuda/consulta-deuda.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { DeudaData } from '../../model/consulta-deuda/deuda.interface';
import {
  ScrollToService,
  ScrollToConfigOptions,
} from '@nicky-lenaers/ngx-scroll-to';
@Component({
  selector: 'app-consult-debts',
  templateUrl: './consult-debts.component.html',
  styleUrls: ['./consult-debts.component.scss'],
})
export class ConsultDebtsComponent implements OnInit {
  @ViewChild('payment') paymentComponent: PaymentComponent;
  public deudas: DatosDeuda;
  public planillas: ConsultaDeuda[];
  public paymentList = [];
  showCloseImage = false;
  showOpenImage = false;
  cargando = false;
  options = [
    { name: 'Nro. de Cédula', value: 'CED' },
    { name: 'RUC', value: 'RUC' },
    { name: 'Pasaporte', value: 'PAS' },
    { name: 'Nro. de Cuenta', value: 'NRO' },
  ];
  identificaciones = [];
  selectedOption: string;
  valueOption = 'Nro. de Cédula';
  printedOption: string;
  pageDeudas: number = 1;
  pagePlanillas: number = 1;
  openPayment = false;
  paymentAccount = undefined;

  constructor(
    private consultaDeudaService: ConsultaDeudaService,
    private spinner: NgxSpinnerService,
    private scrollToService: ScrollToService,
    private paymentService: PaymentService,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
    // this.getAllData();
    this.checkCashOpen();
  }

  customOptionsServicios: OwlOptions = {
    dots: false,
    margin: 25,
    loop: true,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  public getAllData(): void {
    this.cargando = true;
    const ipt_identificador = (<HTMLInputElement>(
      document.getElementById('ipt_identificador')
    )).value;
    const slt_identificadord = (<HTMLInputElement>(
      document.getElementById('selectIdentificadorD')
    )).value;
    // const ipt_identificador = '0300581287';
    // const slt_identificadord = 'CED';

    this.consultaDeudaService
      .getAllDeudas(slt_identificadord, ipt_identificador)
      .subscribe(
        (deudas: DatosDeuda) => {
          // console.log(deudas);
          this.cargando = false;
          this.deudas = deudas;
          deudas.usuario.APELLIDOS = `${
            this.deudas.usuario.PRIMER_APELLIDO
              ? this.deudas.usuario.PRIMER_APELLIDO
              : this.deudas.usuario.NOMBRE
          } ${
            this.deudas.usuario.SEGUNDO_APELLIDO
              ? this.deudas.usuario.SEGUNDO_APELLIDO
              : ''
          }`.trim();

          this.deudas.usuario.IDENTIFICACION =
            deudas.usuario.NUMERO_IDENTIFICACION;
          if (deudas.usuario.NUMERO_IDENTIFICACION.length > 10) {
            this.deudas.usuario.IDENTIFICACION =
              deudas.usuario.NUMERO_IDENTIFICACION.substring(0, 10);
          } else if (deudas.usuario.NUMERO_IDENTIFICACION.length < 10) {
            const numValuesAdd =
              10 - deudas.usuario.NUMERO_IDENTIFICACION.length;
            for (let i = 0; i < numValuesAdd; i++) {
              this.deudas.usuario.IDENTIFICACION += '0';
            }
          }

          this.paymentList = this.deudas.deudas.filter(
            (value) => value.estado === 'ACTIVA'
          );

          if (!this.deudas.deudas && !this.deudas.deudas.length) {
            this.abrirImagen('/assets/images/img-no-deudas/img_nd.jpg');
          } else if (+this.deudas.deuda_total === 0) {
            this.abrirImagen('/assets/images/img-no-deudas/img_nd.jpg');
          } else {
            this.triggerScrollTo();
          }
          // this.openPaymentComponent(60765);
        },
        (err) => {
          this.cargando = false;
          this.alertsErrores('No existe información.');
        }
      );

    /* if (slt_identificadord == 'Cédula') {
      if (this.validarCedula(ipt_identificador)) {
        this.spinner.show();
        this.consultaDeudaService.getAllDeudas('tipoid', ipt_identificador)
          .subscribe(deudas => this.deudas = deudas);
        // this.consultaDeudaService.getLastPlanillas('tipoid', ipt_identificador).subscribe(planillas => this.planillas = planillas);
        (<HTMLInputElement>document.getElementById('ipt_identificador')).value = '';
        this.identificaciones = [];
        this.identificaciones.push({ tipoid: slt_identificadord, id: ipt_identificador });
        setTimeout(() => {
          this.spinner.hide();
        }, 1500);
      } else {
        this.alertsErrores("La cédula no es válida");
        (<HTMLInputElement>document.getElementById('ipt_identificador')).value = '';
      }
    } else if (slt_identificadord == 'RUC') {
      if (this.validarRUC(ipt_identificador)) {
        this.spinner.show();
        this.consultaDeudaService.getAllDeudas('2', ipt_identificador).subscribe(deudas => this.deudas = deudas);
        this.consultaDeudaService.getLastPlanillas('2', ipt_identificador).subscribe(planillas => this.planillas = planillas);
        (<HTMLInputElement>document.getElementById('ipt_identificador')).value = '';
        this.identificaciones = [];
        this.identificaciones.push({ tipoid: slt_identificadord, id: ipt_identificador });
        setTimeout(() => {
          this.spinner.hide();
        }, 1500);
      } else {
        this.alertsErrores("El RUC no es válido");
        (<HTMLInputElement>document.getElementById('ipt_identificador')).value = '';

      }
    } else if (slt_identificadord == 'Número de cuenta') {
      if (this.validarNCuenta(ipt_identificador)) {
        this.spinner.show();
        this.consultaDeudaService.getAllDeudas('3', ipt_identificador).subscribe(deudas => this.deudas = deudas);
        this.consultaDeudaService.getLastPlanillas('3', ipt_identificador).subscribe(planillas => this.planillas = planillas);
        (<HTMLInputElement>document.getElementById('ipt_identificador')).value = '';
        this.identificaciones = [];
        this.identificaciones.push({ tipoid: slt_identificadord, id: ipt_identificador });
        setTimeout(() => {
          this.spinner.hide();
        }, 1500);
      } else {
        this.alertsErrores("El número de cuenta no es válido");

        (<HTMLInputElement>document.getElementById('ipt_identificador')).value = '';
      }
    } else {
      (<HTMLInputElement>document.getElementById('ipt_identificador')).value = '';
      this.alertsErrores("Prueba refrescando la página");

    }*/
  }
  public getLastPlanillas() {
    this.consultaDeudaService
      .getLastPlanillas('tipoid', 'id')
      .subscribe((planillas) => (this.planillas = planillas));
  }
  public guiaNroCuenta() {
    Swal.fire({
      title: '¿Dónde veo mi número de cuenta?',
      text: 'Revisa tu factura/guía de pago, encuentras tu Nro. Cuenta en la parte superior derecha.',
      imageUrl: '/assets/images/optimizadas_machalaep/nrocuenta.webp',
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
      imageAlt: 'Ver número de cuenta',
    });
  }
  public alertsErrores(mensaje: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: mensaje,
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
    });
  }
  public alertsSuccess(mensaje: string): void {
    Swal.fire({
      icon: 'success',
      title: 'Info...',
      text: mensaje,
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
    });
  }
  public validarCedula(cedula: string) {
    if (cedula.length === 10) {
      const digitoRegion = cedula.substring(0, 2);
      if (digitoRegion >= String(0) && digitoRegion <= String(24)) {
        const ultimoDigito = Number(cedula.substring(9, 10));
        const pares =
          Number(cedula.substring(1, 2)) +
          Number(cedula.substring(3, 4)) +
          Number(cedula.substring(5, 6)) +
          Number(cedula.substring(7, 8));
        let numeroUno: any = cedula.substring(0, 1);
        numeroUno = numeroUno * 2;
        if (numeroUno > 9) {
          numeroUno = numeroUno - 9;
        }
        let numeroTres: any = cedula.substring(2, 3);
        numeroTres = numeroTres * 2;
        if (numeroTres > 9) {
          numeroTres = numeroTres - 9;
        }
        let numeroCinco: any = cedula.substring(4, 5);
        numeroCinco = numeroCinco * 2;
        if (numeroCinco > 9) {
          numeroCinco = numeroCinco - 9;
        }
        let numeroSiete: any = cedula.substring(6, 7);
        numeroSiete = numeroSiete * 2;
        if (numeroSiete > 9) {
          numeroSiete = numeroSiete - 9;
        }
        let numeroNueve: any = cedula.substring(8, 9);
        numeroNueve = numeroNueve * 2;
        if (numeroNueve > 9) {
          numeroNueve = numeroNueve - 9;
        }
        const impares =
          numeroUno + numeroTres + numeroCinco + numeroSiete + numeroNueve;
        const sumaTotal = pares + impares;
        const primerDigitoSuma = String(sumaTotal).substring(0, 1);
        const decena = (Number(primerDigitoSuma) + 1) * 10;
        let digitoValidador = decena - sumaTotal;
        if (digitoValidador === 10) {
          digitoValidador = 0;
        }
        if (digitoValidador === ultimoDigito) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  public validarRUC(ruc: string) {
    if (ruc.length == 13) {
      return true;
    } else {
      return false;
    }
  }
  public validarNCuenta(ncuenta: string) {
    if (ncuenta.length == 6) {
      return true;
    } else {
      return false;
    }
  }

  cambiarOpcion(value: any): void {
    const index = value.target.options.selectedIndex;
    this.valueOption = this.options[index].name;
  }

  public abrirImagen(url: string) {
    Swal.fire({
      title: 'Aguas Machala EP',
      confirmButtonText: `Cerrar`,
      confirmButtonColor: '#182333',
      html: `<div><img class="img-fluid" src="${url}"></div>`,
    });
  }

  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: 'dataDebts',
      offset: -146,
    };

    this.scrollToService.scrollTo(config);
  }

  scrollToConsultDebts() {
    const config: ScrollToConfigOptions = {
      target: 'consultDebts',
      offset: -146,
    };

    this.scrollToService.scrollTo(config);
  }

  openPaymentComponent(accountId: number) {
    const account = this.deudas.deudas.find(
      (value) => value.cuenta === accountId
    );
    if (account) {
      this.paymentAccount = account;
      this.openPayment = true;
      this.paymentComponent.setDataDebt(this.deudas.usuario, this.paymentAccount);
      this.scrollToConsultDebts();
    }
  }

  closePaymentComponent() {
    this.openPayment = false;
  }

  async checkCashOpen() {
    try {

      const {permission} = await this.paymentService.checkCashOpen();
      // console.log(permission);

      debugger
      if(permission) {
        this.showOpenImage = permission;
      } else {
        this.showCloseImage = !permission;
      }
    } catch (error) {
      console.log(error);
    }

  }
}
