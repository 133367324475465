import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';
import { Transparencia } from '../../model/transparencia/transparencia.model';
import { Mes } from '../../model/transparencia/mes.model';
import { Anio } from '../../model/transparencia/anio.model';

@Injectable({
  providedIn: 'root'
})
export class TransparenciaService {
  constructor(private httpClient: HttpClient) { }
  public getTransparencia(cedula: String): Observable<Transparencia> {
    var api_uri = environment.API_URL.toString() + `gconsultareclamo=${cedula}`;
    return this.httpClient.get<Transparencia>(api_uri).pipe(
      map(data => new Transparencia().deserialize(data)),
      catchError(() => throwError('User not found'))
    );
  }
  public getDocsTransparencia(mes:string, anio:string): Observable<Transparencia[]> {
    // var api_uri = environment.API_URL.toString() + `get_transparencia/${mes}/${anio}`;
    var api_uri = `https://www.aguasmachala.com:3000/api/v1/transparencia?anio=${anio}&mes=${mes}`;
    // var api_uri = `/api/v1/transparencia?anio=${anio}&mes=${mes}`;


    return this.httpClient.get<Transparencia[]>(api_uri).pipe(
      map(data => data.map(data => new Transparencia().deserialize(data)))
    );
  }
  public getMesTransparencia(anio:string): Observable<Mes[]> {
    // var api_uri = environment.API_URL.toString() + `get_mes_transparencia/${anio}`;
    var api_uri = `https://www.aguasmachala.com:3000/api/v1/transparencia/meses?anio=${anio}`;
    // var api_uri = `/api/v1/transparencia/meses?anio=${anio}`;

    return this.httpClient.get<Mes[]>(api_uri).pipe(
      map(data => data.map(data => new Mes().deserialize(data)))
    );
  }
  public getAnioTransparencia(): Observable<Anio[]> {
    //var api_uri = environment.API_URL.toString() + `get_anio_transparencia`;
    var api_uri = `https://www.aguasmachala.com:3000/api/v1/transparencia/anios`;
    // var api_uri = `/api/v1/transparencia/anios`;

    return this.httpClient.get<Anio[]>(api_uri).pipe(
      map(data => data.map(data => new Anio(data.anio).deserialize(data)))
    );
  }

}
