<ngx-spinner></ngx-spinner>

<div class="ttm-page-title-row">
    <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="title-box ttm-textcolor-white">
                    <div class="page-title-heading">
                        <h1 class="title">Reclamos</h1>
                    </div>
                    <div class="breadcrumb-wrapper">
                        <span>
              <a title="Homepage" routerLink="/"
                ><i class="ti ti-home"></i
              ></a>
            </span>
                        <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
                        <span><span style="color: #0095d9; font-weight: bold"
                >Denuncia en Ciudad</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="site-main single pb-20">
  <div
    class="sidebar ttm-sidebar-right ttm-bgcolor-white break-991-colum clearfix"
  >
    <div class="container">
      <p style="padding-left: 1rem; padding-right: 1 rem">
        Ingresa aquí alguna petición, reclamo o sugerencia que quieras realizar.
        Tus comentarios nos ayudan a mejorar nuestros servicios.
      </p>
    </div>
  </div>
  <section id="s-reclamos-externos">
    <h4 style="text-align: center">DENUNCIAS Y RECLAMOS EXTERNOS</h4>
    <div class="container">
      <h5>Tengo un problema ajeno a mi domicilio</h5>
      <p>
        El siguiente apartado te permite hacer un reclamo por inconvenientes de
        agua potable/alcantarillado en la ciudad. Estos problemas pueden ser:
        alcantarillas colapsadas, robo de tapas de alcantarillas, fugas de agua,
        entre otros.
        <br />
        Es importante que llenes <strong>CORRECTAMENTE</strong> tus datos y
        detalles el problema a reportar. Esto nos permite gestionar los recursos
        para atender tu petición.
        <br />
        <strong>Ayúdanos a ayudar</strong>
      </p>
      <form
        action=""
        [formGroup]="form"
        class="row"
        (ngSubmit)="guardarReclamo()"
      >
        <div class="form-group col-12">
          <label for="f-d-name" class="h6"
            >Nombre<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" name="nombre" id="f-d-name" aria-describedby="helpId" placeholder="" formControlName="name" [class.is-invalid]="invalidName" />
                        <small id="helpId1" class="form-text text-danger" *ngIf="invalidName">Ingrese su nombre</small
          >
        </div>

        <div class="col-12 col-md-6">
          <div class="d-flex flex-wrap">
            <div class="form-group mb-1" style="width: 30%">
              <label for="f-d-t-id" class="h6">Sel.</label>
              <select
                class="form-control"
                style="height: calc(1.5em + 0.75rem)"
                name="tipo-id"
                id="f-d-t-id"
                formControlName="typeId"
              >
                <option value="CED">CED</option>
                <option value="RUC">RUC</option>
                <option value="NRO">Nro. Cuenta</option>
              </select>
            </div>

            <div class="form-group mb-1" style="width: 70%">
              <label for="f-d-i-id" class="h6"
                >Ingrese su: {{this.form.get('typeId').value}}<span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                name="identificacion"
                id="f-d-i-id"
                aria-describedby="helpId"
                placeholder=""
                formControlName="id"
                [class.is-invalid]="invalidId"
              />
              <small
                id="helpId2"
                class="form-text text-danger"
                *ngIf="invalidId"
                >Ingrese su id</small
              >
            </div>
          </div>
        </div>

        <div class="form-group col-12 col-md-6">
          <label for="f-d-email" class="h6"
            >Ingrese un Email<span class="text-danger">*</span></label
          >
          <input
            type="email"
            class="form-control"
            name="correo"
            id="f-d-email"
            aria-describedby="helpId"
            placeholder=""
            formControlName="email"
            [class.is-invalid]="invalidEmail"
          />
          <small id="helpId3" class="form-text text-danger" *ngIf="invalidEmail"
            >Ingrese un Email</small
          >
        </div>

        <div class="form-group col-12 col-md-6">
          <label for="f-d-tel-f" class="h6"
            >Nro. Telefono principal<span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control"
            name="f-d-tel-f"
            id="telefono-fijo"
            aria-describedby="helpId"
            placeholder=""
            formControlName="phonePri"
            [class.is-invalid]="invalidPriPhone"
          />
          <small
            id="helpId4"
            class="form-text text-danger"
            *ngIf="invalidPriPhone"
            >Ingrese su nro telefono</small
          >
        </div>

        <div class="form-group col-12 col-md-6">
          <label for="f-d-tp-sol" class="h6">Tipo de Solicitante</label>
          <select
            class="form-control"
            style="height: calc(1.5em + 0.75rem)"
            name="solicitante"
            id="f-d-tp-sol"
            formControlName="petitioner"
            [class.is-invalid]="invalidPetitioner"
          >
            <option value="B">Beneficiario</option>
            <option value="P">Propietario</option>
          </select>
        </div>

        <div class="col-12 mb-4">
          <div class="contenedor-list">
            <label class="h6"
            >Selecciona la categoría de servicio que más se acerque a tu
            problema:</label
          >
          <div class="form-check d-flex flex-wrap">
            <label
              class="form-check-label"
              style="width: 50%; padding: 0.5rem"
              *ngFor="let tipo of tipoReclamo; let i = index"
            >
              <input
                type="radio"
                class="form-check-input"
                name="typeClaim"
                id="f-d-rad-tp-den-{{ i }}"
                [value]="tipo.id"
                [checked]="i === 0"
                formControlName="typeClaim"
              />
              {{ tipo.tipo }}
            </label>
          </div>
          <small id="f-d-rad-tp-den" class="form-text text-danger" *ngIf="invalidEmail"
            >Seleccione el servicio que desea que se le propocione.</small
          >
          </div>

        </div>

        <div class="col-12 mt-4">
          <label class="h6">
            Selecciona (click) la ubicacion exacta donde se se suscitó el
            problema, esto nos ayuda a optimizar la gestión de recursos:</label
          >
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="f-d-dir-f" class="h6">Dirección</label>
                <textarea
                  name=""
                  id="f-d-dir-f"
                  rows="5"
                  disabled
                  placeholder="Tu dirección aparecerá aquí, selecciona en el mapa."
                  formControlName="address"
                  [class.is-invalid]="invalidAddress"
                ></textarea>
                <small
                  id="helpId4"
                  class="form-text text-danger"
                  *ngIf="invalidAddress"
                  >Seleccione una dirección.</small
                >
              </div>
              <div class="form-group">
                <label for="f-d-dir-f" class="h6"
                  >Ingrese una referencia<span class="text-danger"
                    >*</span
                  ></label
                >
                <textarea
                  name=""
                  id="f-d-dir-f"
                  rows="5"
                  placeholder="ej. calle, sitio, parque cercano, etc."
                  formControlName="reference"
                  [class.is-invalid]="invalidReference"
                ></textarea>
                <small
                  id="helpId4"
                  class="form-text text-danger"
                  *ngIf="invalidReference"
                  >Ingrese una referencia, ej. calle, sitio, parque cercano,
                  etc.</small
                >
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div
                class="container"
                style="margin-top: 1rem; margin-bottom: 1rem"
              >
                <div id="InsertMap"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group col-12">
          <label for="f-d-descri" class="h6">Describe la situación.</label>
          <textarea
            class="form-control"
            name=""
            id="f-d-descri"
            rows="5"
            formControlName="details"
            [class.is-invalid]="invalidDetails"
          ></textarea>
          <small
            id="helpId5"
            class="form-text text-danger"
            *ngIf="invalidDetails"
            >Detalle el motivo de su reclamo</small
          >
        </div>

        <p class="col-12 text-warning">
          <i>
            **Para finalizar el formulario, revisa que tu Cédula, RUC o Número
            de Cuenta sea correcto, además de contener al menos un numero de
            contacto (teléfono fijo y/o móvil).
          </i>
        </p>

        <div class="col-12 text-center">
          <button
            type="submit"
            class="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-bgcolor-skincolor"
            style="min-width: 200px"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  </section>
  <section class="ttm-row blog2-section clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="section-title with-desc title-style-center_text clearfix">
            <div class="title-header">
              <h5>SERVICIOS ONLINE</h5>
              <h2 class="title">GESTIONES A UN CLICK DE DISTANCIA</h2>
            </div>
            <div class="title-desc">
              Realiza gestiones, consultas y pagos desde la comodidad de tu
              hogar. <br />
              Revisa nuestra bitácora de servicios en línea.
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="">
        <app-slidertramites></app-slidertramites>
      </div>
    </div>
  </section>
  <section class="ttm-row services2-section bg-img4 clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="section-title with-desc title-style-center_text clearfix">
            <div class="title-header">
              <h5>NECESITO HACER UN TRÁMITE PRESENCIAL</h5>
              <h2 class="title">PROCESOS INTERNOS</h2>
            </div>
            <div class="title-desc">
              Encuentra en esta sección los pasos a seguir en procesos internos
              de la empresa, te indicaremos como crear una cuenta, como pedir
              una instalación de medidor, como actualizar datos, entre otros
              procesos.
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="">
        <app-sliderservices></app-sliderservices>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="mt-40 text-center">
            <h6 class="ttm-textcolor-grey">
              Revisa todos los procesos que puedes realizar
              <strong
                ><u
                  ><a class="ttm-textcolor-dark" routerLink="/servicios"
                    >en esta sección</a
                  ></u
                ></strong
              >
            </h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- <app-sliderservices></app-sliderservices> -->