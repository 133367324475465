<ngx-spinner></ngx-spinner>
<div class="ttm-page-title-row">
  <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title-box ttm-textcolor-white">
          <div class="page-title-heading">
            <h1 class="title">Nosotros</h1>
          </div>
          <div class="breadcrumb-wrapper">
            <span>
              <a title="Homepage" routerLink="/"><i class="ti ti-home"></i></a>
            </span>
            <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
            <span><span style="color: #0095d9; font-weight: bold">Nosotros</span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="site-main">
  <section class="ttm-row about2-section break-1199-colum clearfix">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-12">
          <div class="ttm_single_image-wrapper mb-5">
            <img loading="lazy" class="img-fluid"
              src="/assets/images/optimizadas_machalaep/nosotros/single-img-three.webp" alt="" />
          </div>
        </div>
        <div class="col-lg-7 col-md-12">
          <div class="pt-20">
            <div class="row">
              <div class="col-lg-5 max-content">
                <div class="highlight-text">
                  <h2>{{ anioActual }}</h2>
                  <h4>2015</h4>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="section-title with-desc mt-10 pb-10 clearfix">
                  <div class="title-header">
                    <h5>+ {{ diferenciaAnios }} AÑOS DE</h5>
                    <h2 class="title">SERVICIO</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="section-title with-desc clearfix">
              <div class="title-desc">
                <strong>Somos una empresa pública, totalmente comprometida con el
                  servicio a la comunidad.</strong>
              </div>
            </div>
            <p>
              Bienvenido a nuestro renovado sitio web. Aguas Machala EP es una
              empresa comprometida con el servicio a los/as machaleños/as,
              revisamos y renovamos periódicamente nuestros servicios a la
              ciudadanía. Nuestro principal activo es el equipo de trabajo con
              el cual colaboramos hombro a hombro, y por el cual podemos
              garantizar un excelente servicio de agua potable y alcantarillado.
              Trabajamos por una Machala con acceso a agua potable en todos sus
              barrios y parroquitas. En este portal, puedes encontrar nuevos
              servicios en línea, hojas de ruta para tramites presenciales,
              noticias, logros e hitos, información de puntos de pago, gestionar
              reclamos o reportar daños a bienes públicos (de la categoría agua
              potable y alcantarillado) así como información sobre nuestras
              obras y proyectos.
              <br />
              <strong>¡Aguas Machala EP trabaja para ti y tu familia!</strong>
            </p>
            <div class="row ttm-fid-row-wrapper">
              <div class="col-md-4 col-sm-4">
                <div class="ttm-fid inside ttm-fid-view-lefticon">
                  <div class="ttm-fid-left">
                    <div class="ttm-fid-icon-wrapper">
                      <i class="ti ti-briefcase"></i>
                    </div>
                    <h4 class="ttm-fid-inner">
                      <span>+</span><span>350</span>
                    </h4>
                  </div>
                  <div class="ttm-fid-contents">
                    <h3 class="ttm-fid-title">Obras Ejecutadas</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="ttm-fid inside ttm-fid-view-lefticon">
                  <div class="ttm-fid-left">
                    <div class="ttm-fid-icon-wrapper">
                      <i class="ti ti-user"></i>
                    </div>
                    <h4 class="ttm-fid-inner">
                      <span>+</span><span>264</span>
                    </h4>
                  </div>
                  <div class="ttm-fid-contents">
                    <h3 class="ttm-fid-title">Colaboradores</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="ttm-fid inside ttm-fid-view-lefticon">
                  <div class="ttm-fid-left">
                    <div class="ttm-fid-icon-wrapper">
                      <i class="ti ti-cup"></i>
                    </div>
                    <h4 class="ttm-fid-inner">
                      <span>+</span> <span>280.000</span>
                    </h4>
                  </div>
                  <div class="ttm-fid-contents">
                    <h3 class="ttm-fid-title">Beneficiarios</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-title with-desc mt-10 pb-10 pr-10 pl-10 clearfix" style="text-align: center">
      <div class="title-header" style="display: inline-block">
        <h2 class="title">LOGROS</h2>
        <h5>ALCANZADOS EN NUESTRA ADMINISTRACION</h5>
      </div>
    </div>
    <div *ngIf="logros" style="background-color: white; border-radius: 15px">
      <owl-carousel-o [options]="customOptionsLogros" *ngIf="customOptionsLogros">
        <ng-container *ngFor="let logro of logros" class="container-logro">
          <ng-template carouselSlide>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="featured-icon-box style1 ttm-bgcolor-grey res-767-mb-15"
                style="background-color: #182333; border-radius: 0.5em">
                <!-- <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                    <i class="flaticon-carpenter-1"></i>
                                </div> -->
                <div class="featured-content">
                  <div class="featured-title">
                    <h3 style="color: white">
                      + {{ logro.cantidad }}
                      <span style="
                          font-size: 0.75em;
                          font-weight: 500;
                          color: #0b6097;
                        ">{{ logro.unidad }}</span>
                    </h3>
                    <h5>{{ logro.unidad }}</h5>
                  </div>
                  <div class="featured-desc">
                    <p style="font-size: 1.5em; font-weight: 400; color: #bdc0c5">
                      {{ logro.descripcion }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </section>
  <section class="ttm-row services2-section bg-img4 clearfix pb-130">
    <div class="container">
      <div class="row row-equal-height">
        <div class="row-lg-4 row-md-4 row-sm-12">
          <div class="featured-icon-box style1 top-icon text-center ttm-bgcolor-white box-shadow2">
            <div
              class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-skincolor ttm-icon_element-size-md ttm-icon_element-style-rounded">
              <i class="ti ti-flag-alt-2"></i>
            </div>
            <div class="featured-content">
              <div class="featured-title">
                <h5>Nuestra Historia</h5>
              </div>
              <div class="featured-desc">
                <p>
                  Desde el año 2015, administramos el servicio público de agua
                  potable y alcantarillado para el cantón Machala. Bajo la
                  gestión del Ing. Darío Macas Salvatierra, Alcalde del GAD
                  Machala, en el 2019, incrementamos a nuestros productos la
                  unidad de vivienda, convirtiéndonos en lo que ahora somos:
                  proveedores de soluciones habitacionales de interés social
                  para nuestra comunidad.
                  <br />
                  <br />
                  Renovamos diariamente nuestro compromiso con la ciudadanía
                  Machaleña de llevar servicios de calidad a cada uno de sus
                  hogares, conscientes de que nuestro principal activo es
                  nuestro equipo, trabajamos hombro con hombro para garantizarte
                  que el acceso al líquido vital se encuentre apto para el
                  consumo humano.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row-lg-4 row-md-4 row-sm-12">
          <div class="featured-icon-box style1 top-icon text-center ttm-bgcolor-white box-shadow2">
            <div
              class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-skincolor ttm-icon_element-size-md ttm-icon_element-style-rounded">
              <i class="ti ti-bag"></i>
            </div>
            <div class="featured-content">
              <div class="featured-title">
                <h5>Nuestra Misión</h5>
              </div>
              <div class="featured-desc">
                <p>
                  La Empresa Pública Municipal de Agua Potable y Alcantarillado del Cantón Machala, Aguas Machala – EP, es una Institución comprometida con el mejoramiento de la calidad de vida de los habitantes del Cantón Machala, a través de la prestación de servicios de agua potable y saneamiento ambiental que contribuyan al hábitat y buen vivir, así como a estructurar, gestionar y promover proyectos de vivienda, buscando aportar soluciones al déficit habitacional.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row-lg-4 row-md-4 row-sm-12">
          <div class="featured-icon-box style1 top-icon text-center ttm-bgcolor-white box-shadow2">
            <div
              class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-skincolor ttm-icon_element-size-md ttm-icon_element-style-rounded">
              <i class="ti ti-eye"></i>
            </div>
            <div class="featured-content">
              <div class="featured-title">
                <h5>Nuestra Visión</h5>
              </div>
              <div class="featured-desc">
                <p>
                  - Aguas Machala - EP para el año 2036, será un referente a nivel nacional en la gestión sostenible de servicios públicos de agua potable de calidad y saneamiento ambiental, e implementación de planes y proyectos habitacionales.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="ttm-row fid-section mt_70 ttm-bg ttm-bgcolor-skincolor clearfix">
    <div class="container pr-10 pl-10">
      <div class="section-title with-desc clearfix">
        <div class="title-header">
          <h5>MULTIMEDIA</h5>
          <h2 class="title">NUESTRO TRABAJO EN FOTOS</h2>
        </div>
        <div class="title-desc">
          Revisa nuestro registro fotográfico de obras realizadas.
        </div>
      </div>

      <section class="image-about-us">
        <div class="owl">
          <owl-carousel-o [options]="customOptionsMain" class="testimonial-slide mt_60" *ngIf="customOptionsMain">
            <ng-template carouselSlide dotContent="\f080">
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 1.webp" alt="" class="img-fluid"
                title="slider-mainbg-001" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 2.webp" alt="" class="img-fluid"
                title="slider-mainbg-002" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 3.webp" alt="" class="img-fluid"
                title="slider-mainbg-003" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 4.webp" alt="" class="img-fluid"
                title="slider-mainbg-004" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 6.webp" alt="" class="img-fluid"
                title="slider-mainbg-006" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 12.webp" alt="" class="img-fluid"
                title="slider-mainbg-007" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 11.webp" alt="" class="img-fluid"
                title="slider-mainbg-008" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 10.webp" alt="" class="img-fluid"
                title="slider-mainbg-009" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 15.webp" alt="" class="img-fluid"
                title="slider-mainbg-010" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 14.webp" alt="" class="img-fluid"
                title="slider-mainbg-011" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 13.webp" alt="" class="img-fluid"
                title="slider-mainbg-012" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 7.webp" alt="" class="img-fluid"
                title="slider-mainbg-013" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 8.webp" alt="" class="img-fluid"
                title="slider-mainbg-014" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 9.webp" alt="" class="img-fluid"
                title="slider-mainbg-015" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 16.jpeg" alt="" class="img-fluid"
                title="slider-mainbg-015" style="border-radius: 15px 50px 30px" />
            </ng-template>
            <ng-template carouselSlide>
              <img loading="lazy" src="/assets/images/optimizadas_machalaep/obras/Obra 17.jpeg" alt="" class="img-fluid"
                title="slider-mainbg-015" style="border-radius: 15px 50px 30px" />
            </ng-template>
          </owl-carousel-o>
        </div>
      </section>
    </div>
  </section>
  <section class="ttm-row about3-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-sm-12">
          <div class="featuredbox-number pt-40" id="obras-machala">
            <div class="section-title clearfix">
              <div class="title-header">
                <h5>CONOCE</h5>
                <h2 class="title">NUESTRAS OBRAS</h2>
              </div>
            </div>
            <p class="mb-35">
              Los trabajos que realizamos por y para Machala abarcan todos los
              procesos de saneamiento de agua. Estas labores las realizamos con
              sigilo para así construir una ciudad de calidad para todos los
              Machaleños. <br />
              A continuación, puedes encontrar algunos de los trabajos que
              realizamos, puedes conocer más acerca de cada uno haciendo click
              en su título.
            </p>
            <div class="featured-icon-box style4 left-icon"
              (click)="abrirVideo('https://www.youtube.com/embed/o58gkt6iG7A')">
              <div class="featured-icon">
                <div
                  class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                  <i class="ttm-num ti-info"></i>
                </div>
              </div>
              <div class="featured-content">
                <div class="featured-title">
                  <h5>
                    <a (click)="
                        abrirVideo('https://www.youtube.com/embed/o58gkt6iG7A')
                      " style="font-weight: 600; text-decoration: underline">Anillo Perimetral de Agua Potable</a>
                  </h5>
                  <p><strong>MACHALA</strong></p>
                </div>
              </div>
            </div>
            <div class="featured-icon-box style4 left-icon"
              (click)="abrirVideo('https://www.youtube.com/embed/vjgwA8QgM30')">
              <div class="featured-icon">
                <div
                  class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                  <i class="ttm-num ti-info"></i>
                </div>
              </div>
              <div class="featured-content">
                <div class="featured-title">
                  <h5>
                    <a style="font-weight: 600; text-decoration: underline" (click)="
                        abrirVideo('https://www.youtube.com/embed/vjgwA8QgM30')
                      ">
                      Optimizacion de Red Terciaria
                    </a>
                  </h5>
                  <p><strong>REDES DE ALCANTARILLADO SANITARIO</strong></p>
                </div>
              </div>
            </div>
            <div class="featured-icon-box style4 left-icon"
              (click)="abrirVideo('https://www.youtube.com/embed/keWuAb0bMyk')">
              <div class="featured-icon">
                <div
                  class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                  <i class="ttm-num ti-info"></i>
                </div>
              </div>
              <div class="featured-content">
                <div class="featured-title">
                  <h5>
                    <a style="font-weight: 600; text-decoration: underline" (click)="
                        abrirVideo('https://www.youtube.com/embed/keWuAb0bMyk')
                      ">
                      Regeneracion Hidrosanitaria
                    </a>
                  </h5>
                  <p><strong> CDLA. FANNY ESTHELA</strong></p>
                </div>
              </div>
            </div>
            <div class="featured-icon-box style4 left-icon"
              (click)="abrirVideo('https://www.youtube.com/embed/-nnyj7KsSk0')">
              <div class="featured-icon">
                <div
                  class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                  <i class="ttm-num ti-info"></i>
                </div>
              </div>
              <div class="featured-content">
                <div class="featured-title">
                  <h5>
                    <a style="font-weight: 600; text-decoration: underline" (click)="
                        abrirVideo('https://www.youtube.com/embed/-nnyj7KsSk0')
                      ">
                      Instalacion de Red Principal de Agua Potable
                    </a>
                  </h5>
                  <p><strong> BARRIO COSTA AZUL</strong></p>
                </div>
              </div>
            </div>
            <div class="featured-icon-box style4 left-icon"
              (click)="abrirVideo('https://www.youtube.com/embed/ZRlDncU4eoI')">
              <div class="featured-icon">
                <div
                  class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                  <i class="ttm-num ti-info"></i>
                </div>
              </div>
              <div class="featured-content">
                <div class="featured-title">
                  <h5>
                    <a style="font-weight: 600; text-decoration: underline" (click)="
                        abrirVideo('https://www.youtube.com/embed/ZRlDncU4eoI')
                      ">
                      Red de agua potable
                    </a>
                  </h5>
                  <p><strong>URSEZA 2 - SECTOR 1</strong></p>
                </div>
              </div>
            </div>
            <div class="featured-icon-box style4 left-icon"
              (click)="abrirVideo('https://www.youtube.com/embed/bqArLigfPYo')">
              <div class="featured-icon">
                <div
                  class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                  <i class="ttm-num ti-info"></i>
                </div>
              </div>
              <div class="featured-content">
                <div class="featured-title">
                  <h5>
                    <a style="font-weight: 600; text-decoration: underline" (click)="
                        abrirVideo('https://www.youtube.com/embed/bqArLigfPYo')
                      ">
                      Planta Potabilizadora "González Rubio"
                    </a>
                  </h5>
                  <p><strong>CONTROL DE CALIDAD DE AGUA</strong></p>
                </div>
              </div>
            </div>
            <div class="featured-icon-box style4 left-icon"
              (click)="abrirVideo('https://www.youtube.com/embed/C19ByrSaFQY')">
              <div class="featured-icon">
                <div
                  class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                  <i class="ttm-num ti-info"></i>
                </div>
              </div>
              <div class="featured-content">
                <div class="featured-title">
                  <h5>
                    <a style="font-weight: 600; text-decoration: underline" (click)="
                        abrirVideo('https://www.youtube.com/embed/C19ByrSaFQY')
                      ">
                      Planta Potabilizadora de Agua SUR
                    </a>
                  </h5>
                  <p><strong>SISTEMA DE POTABILIZACIÓN DE AGUA</strong></p>
                </div>
              </div>
            </div>
            <div class="featured-icon-box style4 left-icon"
              (click)="abrirVideo('https://www.youtube.com/embed/81fUc3QN1HI')">
              <div class="featured-icon">
                <div
                  class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                  <i class="ttm-num ti-info"></i>
                </div>
              </div>
              <div class="featured-content">
                <div class="featured-title">
                  <h5>
                    <a style="font-weight: 600; text-decoration: underline" (click)="
                        abrirVideo('https://www.youtube.com/embed/81fUc3QN1HI')
                      ">
                      Red de agua potable y guías domiciliarias
                    </a>
                  </h5>
                  <p><strong>CDLA. SANTA RITA</strong></p>
                </div>
              </div>
            </div>
            <div class="featured-icon-box style4 left-icon"
              (click)="abrirVideo('https://www.youtube.com/embed/P4m1x94AH68')">
              <div class="featured-icon">
                <div
                  class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                  <i class="ttm-num ti-info"></i>
                </div>
              </div>
              <div class="featured-content">
                <div class="featured-title">
                  <h5>
                    <a style="font-weight: 600; text-decoration: underline" (click)="
                        abrirVideo('https://www.youtube.com/embed/P4m1x94AH68')
                      ">
                      Reforestación de 3 hectáreas de Mangle
                    </a>
                  </h5>
                  <p><strong>VIRGEN DEL CISNE</strong></p>
                </div>
              </div>
            </div>
            <div class="featured-icon-box style4 left-icon"
              (click)="abrirVideo('https://www.youtube.com/embed/HEw274qY48M')">
              <div class="featured-icon">
                <div
                  class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                  <i class="ttm-num ti-info"></i>
                </div>
              </div>
              <div class="featured-content">
                <div class="featured-title">
                  <h5>
                    <a style="font-weight: 600; text-decoration: underline" (click)="
                        abrirVideo('https://www.youtube.com/embed/HEw274qY48M')
                      ">
                      Inclusión social y laboral de sectores vulnerables
                    </a>
                  </h5>
                  <p><strong>MATRIZ AGUAS MACHALA</strong></p>
                </div>
              </div>
            </div>
            <div class="featured-icon-box style4 left-icon"
              (click)="abrirVideo('https://www.youtube.com/embed/Yw3A9-SH724')">
              <div class="featured-icon">
                <div
                  class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                  <i class="ttm-num ti-info"></i>
                </div>
              </div>
              <div class="featured-content">
                <div class="featured-title">
                  <h5>
                    <a style="font-weight: 600; text-decoration: underline" (click)="
                        abrirVideo('https://www.youtube.com/embed/Yw3A9-SH724')
                      ">
                      Campañas para la eliminación de la violencia de género
                    </a>
                  </h5>
                  <p><strong>MATRIZ AGUAS MACHALA</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
          <div class="ttm_single_image-wrapper text-right">
            <div class="obras__img">
              <div class="obra__img" *ngFor="let id of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
                <img class="img-fluid" src="/assets/images/obras-flex/ob{{ id }}.jpg" alt="" (click)="
                    abrirImagen('/assets/images/obras-flex/ob' + id + '.jpg')
                  " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>