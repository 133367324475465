import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-sliderservices',
  templateUrl: './sliderservices.component.html',
  styleUrls: ['./sliderservices.component.scss']
})
export class SliderservicesComponent implements OnInit {
  customOptionsServicios: OwlOptions;
  constructor() { }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.customOptionsServicios = {
        autoplay: true,
        autoplayTimeout: 5500,
        loop: true,
        dots: true,
        margin: 25,
        responsive: {
          0: {
            items: 1,
          },
          480: {
            items: 1,
          },
          768: {
            items: 3
          },
          1200: {
            items: 3
          }
        }
      }
    });
  }

}
