import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Noticias } from '../../model/noticias/noticias.model';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class NoticiasService {

  constructor(private httpClient: HttpClient) { }
  public getAllNoticias(): Observable<Noticias[]> {
    var api_uri = 'https://www.aguasmachala.com:3000/api/v1/noticias?offset=0&limit=15';
    return this.httpClient.get<Noticias[]>(api_uri).pipe(
      map(data => data.map(data => new Noticias().deserialize(data)))
    );
  }
}
