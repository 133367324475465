import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewCorreo } from '../../model/new-correo/new-correo.model';

@Injectable({
  providedIn: 'root'
})
export class NewCorreoService {

  constructor(private httpClient: HttpClient) { }
  public postNewCorreo(newCorreo:NewCorreo){//: Observable<NewReclamoExterno>


    const headers = { 'content-type': 'application/json'}
    const body=JSON.stringify(newCorreo);
    // var api_uri = environment.API_URL.toString() + `nuevo_reclamo_ciudad`;
    var api_uri = 'https://www.aguasmachala.com:3000/api/v1/email';
    return this.httpClient.post(api_uri, body,{'headers':headers})

  }
}
