<ngx-spinner></ngx-spinner>
<div class="ttm-page-title-row" id="">
  <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title-box ttm-textcolor-white">
          <div class="page-title-heading">
            <h1 class="title">Consuta deudas</h1>
          </div>
          <div class="breadcrumb-wrapper">
            <span>
              <a title="Homepage" routerLink="home"
                ><i class="ti ti-home"></i
              ></a>
            </span>
            <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
            <span
              ><span style="color: #0095d9; font-weight: bold"
                >Consulta deudas</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="consultDebts" class="site-main single">
  <section
    class="ttm-row checkout-section ttm-bgcolor-grey break-991-colum clearfix"
    *ngIf="!openPayment"
  >
    <div class="container">
      <form name="checkout" method="post" class="checkout row" action="#">
        <div class="col-lg-12">
          <div class="billing-fields">
            <h3>Consulta de deudas por servicio</h3>
            <p class="form-row">
              <label
                >Ingresa tu cédula, RUC o número de cuenta para conocer el saldo
                de tu cuenta.&nbsp;<abbr class="required" title="required"
                  >*</abbr
                ></label
              >
            </p>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="billing-fields">
            <p class="form-row">
              <label for="selectIdentificadorD">Tipo de Identificación</label>
              <select
                id="selectIdentificadorD"
                (change)="cambiarOpcion($event)"
              >
                <option
                  *ngFor="let item of options; let i = index"
                  [value]="item.value"
                  [selected]="i === 0"
                >
                  {{ item.name }}
                </option>
              </select>
            </p>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="billing-fields">
            <p class="form-row">
              <label for="ipt_identificador"
                >Ingrese su: {{ valueOption }}</label
              >
              <input
                type="text"
                class="input-tex"
                name="billing_first_name"
                placeholder="Ingrese su: {{ valueOption }}"
                value=""
                id="ipt_identificador"
              />
            </p>
            <p>
              <small>
                ¿No recuerdas el número de cuenta?
                <a
                  (click)="guiaNroCuenta()"
                  style="text-decoration: underline; font-weight: bold"
                  >Haz click AQUÍ.</a
                >
                Te recordamos donde puedes revisarlo
              </small>
            </p>
          </div>
        </div>
        <div class="col-lg-12 text-right">
          <div class="form-row place-order">
            <button
              type="submit"
              class="button"
              name="checkout_place_order"
              id="place_order"
              value="Place order"
              data-value="Place order"
              (click)="getAllData()"
            >
              Consultar
            </button>
          </div>
        </div>

        <div class="col-12" *ngIf="cargando">
          <div class="loading-consulta">
            <div class="i-l">
              <img src="assets/images/load.gif" alt="" />
            </div>
          </div>
        </div>
      </form>
      <div id="dataDebts">
        <div
          *ngIf="deudas"
          style="
            background-color: white;
            padding: 1em;
            margin-top: 1em;
            border-radius: 15px;
          "
        >
          <div class="texto">
            <h3 class="text-center">
              {{ deudas.usuario.NOMBRE_COMPLETO }}
              {{ deudas.usuario.CODIGO_TIPO_IDENTIFICACION }}
            </h3>
            <h3 class="mt-4" style="color: #1769aa" class="text-center">
              <strong>Valor Adeudado:</strong> {{ deudas.deuda_total }} $
            </h3>

            Este es tu resumen de deudas por servicios y de las ultimas
            planillas. Recuerda que el pago puntual de los servicios de agua y
            alcantarillado nos permite mantener y mejorar el saneamiento del
            liquido vital. <strong>¡JUNTOS HACIENDO HISTORIA!</strong>
            <br />
            <!-- <div class="text-center place-order mt-3 payment-info">
              <img
                class="mb-3"
                src="assets/images/open.png"
                alt="Imagen de negocio abierto"
                *ngIf="showOpenImage"
              />
              <img
                class="mb-3"
                src="assets/images/close.png"
                alt="Imagen de negocio cerrado"
                *ngIf="showCloseImage"
              />
              <h5>
                Recuerda que puedes cancelar tus deudas pagando en línea de
                08:00 am a 17:30pm de Lun-Dom.
              </h5>
            </div> -->

          </div>
          <div *ngIf="deudas.deudas && deudas.deudas.length">
            <br />
            <h4 id="order_review_heading">Deudas por numero de cuenta</h4>
            <div class="scroll d-lg-none ">
              <img class="img-scroll" src="assets/images/scroll.png" alt="">
            </div>
            <div
              id="order_review"
              class="debts_table_container container-fluid scroll-x"
            >
              <table class="shop_table checkout-review-order-table debts_table">
                <thead>
                  <tr>
                    <th colspan="5">Información de la cuenta</th>
                    <th class="p-0"></th>
                    <th colspan="5">Datos de la última factura</th>
                  </tr>
                  <tr>
                    <th>Cuenta</th>
                    <th>Tipo de Tarifa</th>
                    <th>Facturas Pendientes</th>
                    <th>Deuda Convenio</th>
                    <th>Dirección</th>
                    <th></th>
                    <th>Consumo</th>
                    <th>Valor Ultima Factura</th>
                    <th>Fecha de Vencimiento</th>
                    <th>Valor Total</th>
                    <th>Estado</th>
                    <!-- <th>Pago en Linea</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let data of deudas.deudas
                        | paginate
                          : {
                              id: 'paginationD',
                              itemsPerPage: 10,
                              currentPage: pageDeudas
                            }
                    "
                  >
                    <td>{{ data.cuenta }}</td>
                    <td>{{ data.tipo_tarifa }}</td>
                    <td>{{ data.facturas_pendientes }}</td>
                    <td>$ {{ data.deuda_convenio }}</td>
                    <td>{{ data.direccion }}</td>
                    <th></th>
                    <td>{{ data.consumo }} m<sup>3</sup></td>
                    <td>$ {{ data.valor_ultima_factura }}</td>
                    <td>
                      {{
                        data.facturas_pendientes < 2
                          ? (data.fecha_vencimiento | date: "dd/MM/yyyy")
                          : "Pago Inmediato"
                      }}
                    </td>
                    <td>$ {{ data.valor }}</td>
                    <td>{{ data.estado }}</td>
                    <td>
                      <!-- <button
                        type="button"
                        class="btn btn-primary"
                        *ngIf="data.estado === 'ACTIVA' && showOpenImage"
                        (click)="openPaymentComponent(data.cuenta)"
                      >
                        Pagar
                      </button> -->
                      <span class="text-success" *ngIf="showCloseImage">Cerrado</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-end table-total">
                <table class="shop_table">
                  <thead>
                    <tr>
                      <th>Valor Adeudado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ deudas.deuda_total }} $</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <pagination-controls
                id="paginationD"
                previousLabel="Anterior"
                nextLabel="Siguiente"
                (pageChange)="pageDeudas = $event"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-payment
    #payment
    [ngClass]="openPayment ? '' : 'hidden'"
    (cancel)="closePaymentComponent()"
    (error)="alertsErrores($event)"
  ></app-payment>
</div>
<section class="ttm-row row-title-section bg-img5 clearfix">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="ttm-content-box text-center">
          <div class="row-title mt-25">
            <div class="section-title ttm-textcolor-white clearfix">
              <h2 class="title">Canales de Pago</h2>
              <h5>
                Disponemos de múltiples lugares y formas de pago <br />para que
                canceles tus planillas en la cercanía de tu hogar.
              </h5>
            </div>
          </div>
          <div class="ttm-btn-box pb-20">
            <a
              class="
                ttm-btn
                ttm-btn-size-lg
                ttm-btn-shape-square
                ttm-btn-style-fill
                ttm-icon-btn-right
                ttm-btn-bgcolor-skincolor
              "
              routerLink="/servicios"
              title=""
              >VER CANALES DE PAGO <i class="ti ti-arrow-circle-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <app-sliderservices></app-sliderservices> -->
<section class="ttm-row blog2-section clearfix">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="section-title with-desc title-style-center_text clearfix">
          <div class="title-header">
            <h5>SERVICIOS ONLINE</h5>
            <h2 class="title">GESTIONES A UN CLICK DE DISTANCIA</h2>
          </div>
          <div class="title-desc">
            Realiza gestiones, consultas y pagos desde la comodidad de tu hogar.
            <br />
            Revisa nuestra bitácora de servicios en línea.
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="">
      <app-slidertramites></app-slidertramites>
    </div>
  </div>
</section>
<section class="ttm-row services2-section bg-img4 clearfix">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="section-title with-desc title-style-center_text clearfix">
          <div class="title-header">
            <h5>NECESITO HACER UN TRÁMITE PRESENCIAL</h5>
            <h2 class="title">PROCESOS INTERNOS</h2>
          </div>
          <div class="title-desc">
            Encuentra en esta sección los pasos a seguir en procesos internos de
            la empresa, te indicaremos como crear una cuenta, como pedir una
            instalación de medidor, como actualizar datos, entre otros procesos.
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="">
      <app-sliderservices></app-sliderservices>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="mt-40 text-center">
          <h6 class="ttm-textcolor-grey">
            Revisa todos los procesos que puedes realizar
            <strong
              ><u
                ><a class="ttm-textcolor-dark" routerLink="/servicios"
                  >en esta sección</a
                ></u
              ></strong
            >
          </h6>
        </div>
      </div>
    </div>
  </div>
</section>
