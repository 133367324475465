<div class="ttm-page-title-row" id="">
  <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title-box ttm-textcolor-white">
          <div class="page-title-heading">
            <h1 class="title">Resultado del pago</h1>
          </div>
          <div class="breadcrumb-wrapper">
            <span>
              <a title="Homepage" routerLink="home"
                ><i class="ti ti-home"></i
              ></a>
            </span>
            <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
            <span
              ><span style="color: #0095d9; font-weight: bold"
                >Resultado del pago</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="site-main single">
  <div class="container" *ngIf="successPayment">
    <h2 class="text-success">Tu pago se realizo con éxito</h2>
    <h5>
      Revisa la sección de deudas, para confirmar la cancelación del pago.
    </h5>
    <p>
      <strong>Información del pago:</strong> <br />
      Numero de cuenta: {{ dataPayment.customer.merchantCustomerId }} <br />
      Total Pagado: {{ dataPayment.amount }}<br />
      Fecha: {{ dataPayment.timestamp | date: "short" }} <br>
      merchantTransactionId: {{ dataPayment.merchantTransactionId }} <br />
    </p>
    <p class="text-danger">
      *En caso de cualquier inconveniente, acérquese a las instalaciones de
      Aguas Machala.
    </p>
    <a
      routerLink="/consultadeudas"
      class="
        ttm-btn
        ttm-btn-size-md
        ttm-btn-shape-square
        ttm-btn-style-border
        ttm-btn-color-black
        mt-15
      "
      >Ir a Deudas</a
    >
  </div>
  <div class="container" *ngIf="errorDataPayment">
    <h2 class="text-warning">Tu pago no fue procesado</h2>
    <h5>
      {{ message }}
    </h5>
    <p>
      <strong>Información del pago:</strong> <br />
      Numero de cuenta: {{ dataPayment.customer.merchantCustomerId }} <br />
      Total Pagado: {{ dataPayment.amount }}<br />
      Fecha: {{ dataPayment.timestamp | date: "short" }} <br>
      merchantTransactionId: {{ dataPayment.merchantTransactionId }} <br />
    </p>
    <p class="text-danger">
      *En caso de cualquier inconveniente, acérquese a las instalaciones de
      Aguas Machala.
    </p>
    <a
      routerLink="/consultadeudas"
      class="
        ttm-btn
        ttm-btn-size-md
        ttm-btn-shape-square
        ttm-btn-style-border
        ttm-btn-color-black
        mt-15
      "
      >Ir a Deudas</a
    >
  </div>
  <div class="container" *ngIf="erroRegisterPayment">
    <h2 class="text-success">Tu pago se realizo con éxito.</h2>
    <h5>
      *Se está procesando su cobro, en la próximas horas podrá verse como
      pagado, en caso de cualquier inconveniente acérquese a las oficinas de
      Aguas Machala o llame al +593 (7) 3702010 de atención al cliente.
    </h5>
    <p>
      <strong>Información del pago:</strong> <br />
      Numero de cuenta: {{ dataPayment.customer.merchantCustomerId }} <br />
      Total Pagado: {{ dataPayment.amount }}<br />
      Fecha: {{ dataPayment.timestamp | date: "short" }}
    </p>
    <a
      routerLink="/consultadeudas"
      class="
        ttm-btn
        ttm-btn-size-md
        ttm-btn-shape-square
        ttm-btn-style-border
        ttm-btn-color-black
        mt-15
      "
      >Ir a Deudas</a
    >
  </div>
</div>

<section class="ttm-row row-title-section bg-img5 clearfix">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="ttm-content-box text-center">
          <div class="row-title mt-25">
            <div class="section-title ttm-textcolor-white clearfix">
              <h2 class="title">Canales de Pago</h2>
              <h5>
                Disponemos de múltiples lugares y formas de pago <br />para que
                canceles tus planillas en la cercanía de tu hogar.
              </h5>
            </div>
          </div>
          <div class="ttm-btn-box pb-20">
            <a
              class="
                ttm-btn
                ttm-btn-size-lg
                ttm-btn-shape-square
                ttm-btn-style-fill
                ttm-icon-btn-right
                ttm-btn-bgcolor-skincolor
              "
              routerLink="/servicios"
              title=""
              >VER CANALES DE PAGO <i class="ti ti-arrow-circle-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <app-sliderservices></app-sliderservices> -->
<section class="ttm-row blog2-section clearfix">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="section-title with-desc title-style-center_text clearfix">
          <div class="title-header">
            <h5>SERVICIOS ONLINE</h5>
            <h2 class="title">GESTIONES A UN CLICK DE DISTANCIA</h2>
          </div>
          <div class="title-desc">
            Realiza gestiones, consultas y pagos desde la comodidad de tu hogar.
            <br />
            Revisa nuestra bitácora de servicios en línea.
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="">
      <app-slidertramites></app-slidertramites>
    </div>
  </div>
</section>
<section class="ttm-row services2-section bg-img4 clearfix">
  <div class="container">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="section-title with-desc title-style-center_text clearfix">
          <div class="title-header">
            <h5>NECESITO HACER UN TRÁMITE PRESENCIAL</h5>
            <h2 class="title">PROCESOS INTERNOS</h2>
          </div>
          <div class="title-desc">
            Encuentra en esta sección los pasos a seguir en procesos internos de
            la empresa, te indicaremos como crear una cuenta, como pedir una
            instalación de medidor, como actualizar datos, entre otros procesos.
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="">
      <app-sliderservices></app-sliderservices>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="mt-40 text-center">
          <h6 class="ttm-textcolor-grey">
            Revisa todos los procesos que puedes realizar
            <strong
              ><u
                ><a class="ttm-textcolor-dark" routerLink="/servicios"
                  >en esta sección</a
                ></u
              ></strong
            >
          </h6>
        </div>
      </div>
    </div>
  </div>
</section>
