<ngx-spinner></ngx-spinner>
<owl-carousel-o [options]="customOptionsMain" class="testimonial-slide mt_60" style="background-color: white"
  *ngIf="customOptionsMain">
  <ng-template carouselSlide>
    <img loading="lazy" src="/assets/images/optimizadas_machalaep/slider-principal/slider-main-003.webp" alt=""
      class="img-fluid" />
  </ng-template>
  <ng-template carouselSlide>
    <img loading="lazy" src="/assets/images/optimizadas_machalaep/slider-principal/slider-main-004.webp" alt=""
      class="img-fluid" />
  </ng-template>
  <ng-template carouselSlide>
    <img loading="lazy" src="/assets/images/optimizadas_machalaep/slider-principal/slider-main-005.webp" alt=""
      class="img-fluid" />
  </ng-template>
  <ng-template carouselSlide>
    <img loading="lazy" src="/assets/images/optimizadas_machalaep/slider-principal/slider-main-001.webp" alt=""
      class="img-fluid" />
  </ng-template>
  <ng-template carouselSlide>
    <img loading="lazy" src="/assets/images/optimizadas_machalaep/slider-principal/slider-main-002.webp" alt=""
      class="img-fluid" />
  </ng-template>
</owl-carousel-o>

<div class="site-main">

  <!--services-section-->
  <section class="ttm-row clearfix" style="padding-bottom: 0;">
    <div class="container">
      <!-- row -->
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <!-- section-title -->
          <div class="section-title style2 clearfix">
            <div class="title-header">
              <h5>TRABAJAMOS POR TÍ</h5>
              <h2 class="title">Nuestros servicios</h2>
            </div>
            <div class="title-desc">Realiza gestiones, consultas y pagos desde la comodidad de tu hogar. Revisa nuestra bitácora de servicios en línea.</div>
          </div><!-- section-title end -->
        </div>
        <div class="col-lg-3 col-md-12">
          <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15 mb-30 float-right"
            routerLink="/servicios">MÁS SERVICIOS<i class="ti ti-angle-double-right"></i></a>
        </div>
      </div><!-- row end -->
      <!-- row -->
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12">
          <!-- featured-icon-box -->
          <div class="featured-icon-box style1 ttm-bgcolor-grey mb-10 mt-5">
            <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
              <i class="fas fa-file-invoice-dollar"></i>
            </div>
            <div class="featured-content">
              <div class="featured-title">

               <!--  <h5><a routerLink="/consultadeudas">Paga y consulta tus deudas</a></h5> -->
                <h5><a href="http://177.234.239.179:82/cw_client_login" target="_blank" rel="noopener noreferrer">Paga y consulta tus deudas</a></h5>
              </div>
              <div class="featured-desc">
                <p>Solo ingresa tu cédula, RUC o número de cuenta para conocer el saldo de tu cuenta</p>
              </div>
            </div>
          </div><!-- featured-icon-box end-->
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12">
          <!-- featured-icon-box -->
          <div class="featured-icon-box style1 ttm-bgcolor-grey mb-10 mt-5">
            <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
              <i class="fas fa-comments"></i>
            </div>
            <div class="featured-content">
              <div class="featured-title">
             <!--    <h5><a routerLink="/denunciaciudad">Realiza una denuncia</a></h5> -->
                <h5><a href="http://177.234.239.179:82/cw_client_login" target="_blank" rel="noopener noreferrer">Realiza una denuncia</a></h5>
              </div>
              <div class="featured-desc">
                <p>Registra tu denuncia. Tus comentarios nos ayudan a mejorar nuestros servicios.</p>
              </div>
            </div>
          </div><!-- featured-icon-box end-->
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12">
          <!-- featured-icon-box -->
          <div class="featured-icon-box style1 ttm-bgcolor-grey mb-10 mt-5">
            <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
              <i class="far fa-id-badge"></i>
            </div>
            <div class="featured-content">
              <div class="featured-title">
                <!-- <h5><a routerLink="/reclamo">Realiza un reclamo</a></h5> -->
                <h5><a href="http://177.234.239.179:82/cw_client_login" target="_blank" rel="noopener noreferrer">Realiza un reclamo</a></h5>
              </div>
              <div class="featured-desc">
                <p>Ingresa aquí alguna petición, reclamo o sugerencia que quieras realizar.</p>
              </div>
            </div>
          </div><!-- featured-icon-box end-->
        </div>
      </div><!-- row end-->
    </div>
  </section>
  <!--services-section end-->

  <section class="ttm-row about2-section break-1199-colum clearfix">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-12">
          <div class="ttm_single_image-wrapper mb-5">
            <img loading="lazy" class="img-fluid" src="/assets/images/optimizadas_machalaep/single-img-two~1.webp"
              alt="" />
          </div>
        </div>
        <div class="col-lg-7 col-md-12">
          <div class="pt-20">
            <div class="row">
              <div class="col-lg-5 max-content">
                <div class="highlight-text">
                  <h2>{{ anioActual }}</h2>
                  <h4>2015</h4>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="section-title with-desc mt-10 pb-10 clearfix">
                  <div class="title-header">
                    <h5>+ {{ diferenciaAnios }} AÑOS DE</h5>
                    <h2 class="title">SERVICIO</h2>
                  </div>
                </div>
              </div>
            </div>
            <div class="section-title with-desc clearfix">
              <div class="title-desc">
                <strong>Aguas Machala EP, empresa pública municipal de agua potable y
                  alcantarillado.</strong>
              </div>
            </div>
            <p>
              Te damos la bienvenida a nuestro renovado sitio web. En Aguas
              Machala EP, nos preocupamos por tu bienestar, y nos complace
              ofrecerte servicios tecnológicos innovadores para optimizar tu
              tiempo y que conozcas un poco más de nosotros.
              <br />
              <br />
              Ponemos a tu alcance este portal en el que encontraras nuevos
              servicios en línea, hoja de ruta para trámites presenciales,
              noticias, hitos, puntos de pago e información sobre las obras y
              proyectos que se ejecutan.
              <br />
              <br />
              Adicionalmente agregamos una sección de gestión online de
              reclamos, denuncias por daños a bienes públicos, consulta de
              saldos, entre otros.
              <br />
              <br />
              <strong>¡Bienvenido!.</strong>
            </p>
            <div class="row ttm-fid-row-wrapper">
              <div class="col-md-4 col-sm-4">
                <div class="ttm-fid inside ttm-fid-view-lefticon">
                  <div class="ttm-fid-left">
                    <div class="ttm-fid-icon-wrapper">
                      <i class="ti ti-briefcase"></i>
                    </div>
                    <h4 class="ttm-fid-inner">
                      <span>350</span><span>+</span>
                    </h4>
                  </div>
                  <div class="ttm-fid-contents">
                    <h3 class="ttm-fid-title">Obras Ejecutadas</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="ttm-fid inside ttm-fid-view-lefticon">
                  <div class="ttm-fid-left">
                    <div class="ttm-fid-icon-wrapper">
                      <i class="ti ti-user"></i>
                    </div>
                    <h4 class="ttm-fid-inner">
                      <span>264</span><span>+</span>
                    </h4>
                  </div>
                  <div class="ttm-fid-contents">
                    <h3 class="ttm-fid-title">Colaboradores</h3>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-4">
                <div class="ttm-fid inside ttm-fid-view-lefticon">
                  <div class="ttm-fid-left">
                    <div class="ttm-fid-icon-wrapper">
                      <i class="ti ti-star"></i>
                    </div>
                    <h4 class="ttm-fid-inner">
                      <span>280.000</span><span>+</span>
                    </h4>
                  </div>
                  <div class="ttm-fid-contents">
                    <h3 class="ttm-fid-title">Beneficiarios</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="ttm-row blog2-section bg-img4 clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="section-title with-desc title-style-center_text clearfix">
            <div class="title-header">
              <h5>SERVICIOS ONLINE</h5>
              <h2 class="title">GESTIONES A UN CLICK DE DISTANCIA</h2>
            </div>
            <div class="title-desc">
              Realiza gestiones, consultas y reclamos desde la comodidad de tu
              hogar. <br />
              A continuación, te mostramos nuestra bitácora de
              <strong>servicios en línea</strong>.
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="">
        <app-slidertramites></app-slidertramites>
      </div>
    </div>
  </section>

  <section class="ttm-row services2-section clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="section-title with-desc title-style-center_text clearfix">
            <div class="title-header">
              <h5>NECESITO HACER UN TRÁMITE PRESENCIAL</h5>
              <h2 class="title">PROCESOS INTERNOS</h2>
            </div>
            <div class="title-desc">
              Encuentra en esta sección los pasos a seguir en procesos internos
              de la empresa, te indicaremos cómo crear una cuenta, pedir una
              instalación de medidor, actualizar datos, entre otros procesos.
              <br />
              A continuación, te mostraremos las hojas de ruta para cada
              <strong>trámite presencial</strong>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="">
        <app-sliderservices></app-sliderservices>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="mt-40 text-center">
            <h6 class="ttm-textcolor-grey">
              Revisa todos los procesos que puedes realizar
              <strong><u><a class="ttm-textcolor-dark" routerLink="/servicios">en esta sección</a></u></strong>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="ttm-row services2-section bg-img4 clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="section-title with-desc title-style-center_text clearfix">
            <div class="title-header">
              <h5>INFÓRMATE</h5>
              <h2 class="title">ÚLTIMAS NOTICIAS</h2>
            </div>
            <div class="title-desc">
              Encuentra en esta sección las últimas noticias de nuestras obras,
              horarios, servicios, puntos de pago.
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="">
        <app-slidenews></app-slidenews>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="mt-40 text-center">
            <h6 class="ttm-textcolor-grey">
              Lee todas las noticias
              <strong><u><a class="ttm-textcolor-dark" routerLink="/noticias">en esta sección</a></u></strong>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="ttm-row row-title-section bg-img5 clearfix">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="ttm-content-box text-center">
            <div class="row-title mt-25">
              <div class="section-title ttm-textcolor-white clearfix">
                <h2 class="title">Canales de Pago</h2>
                <h5>
                  Disponemos de múltiples lugares y formas de pago <br />para
                  que canceles tus planillas en la cercanía de tu hogar.
                </h5>
              </div>
            </div>
            <div class="ttm-btn-box pb-20">
              <a class="ttm-btn ttm-btn-size-lg ttm-btn-shape-square ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                routerLink="/servicios" [fragment]="'canales-pagos'" title="">VER CANALES DE PAGO <i
                  class="ti ti-arrow-circle-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="ttm-row blog2-section clearfix" id="idObras">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="section-title with-desc title-style-center_text clearfix">
            <div class="title-header">
              <h5>REVISA TODOS NUESTROS PROYECTOS</h5>
              <h2 class="title">OBRAS PARA MACHALA</h2>
            </div>
            <div class="title-desc">
              En estos +5 años de servicio a la comunidad hemos desarrollado
              varios proyectos,
              <br />
              entre los cuales a continuación te contamos los principales.
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <div class="featuredbox-number pt-40">
              <p class="mb-35">
                Los trabajos que realizamos por y para Machala abarcan todos los
                procesos de saneamiento de agua. Estas labores las realizamos
                con sigilo para así construir una ciudad de calidad para todos
                los Machaleños.
                <br />
                A continuación, puedes encontrar listado algunos de los
                proyectos más importantes que hemos ejecutado o si deseas
                conocerlo detalladamente, puedes
                <strong>darle click en su título</strong>.
              </p>
              <div class="featured-icon-box style4 left-icon" (click)="
                  abrirVideo('https://www.youtube.com/embed/o58gkt6iG7A')
                ">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                    <i class="ttm-num ti-info"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>
                      <a (click)="
                          abrirVideo(
                            'https://www.youtube.com/embed/o58gkt6iG7A'
                          )
                        " style="font-weight: 600; text-decoration: underline">Anillo Perimetral de Agua Potable</a>
                    </h5>
                    <p><strong>MACHALA</strong></p>
                  </div>
                </div>
              </div>
              <div class="featured-icon-box style4 left-icon" (click)="
                  abrirVideo('https://www.youtube.com/embed/vjgwA8QgM30')
                ">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                    <i class="ttm-num ti-info"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>
                      <a style="font-weight: 600; text-decoration: underline" (click)="
                          abrirVideo(
                            'https://www.youtube.com/embed/vjgwA8QgM30'
                          )
                        ">
                        Optimización de Red Terciaria
                      </a>
                    </h5>
                    <p><strong>REDES DE ALCANTARILLADO SANITARIO</strong></p>
                  </div>
                </div>
              </div>
              <div class="featured-icon-box style4 left-icon" (click)="
                  abrirVideo('https://www.youtube.com/embed/keWuAb0bMyk')
                ">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                    <i class="ttm-num ti-info"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>
                      <a style="font-weight: 600; text-decoration: underline" (click)="
                          abrirVideo(
                            'https://www.youtube.com/embed/keWuAb0bMyk'
                          )
                        ">
                        Regeneración Hidrosanitaria
                      </a>
                    </h5>
                    <p><strong> CDLA. FANNY ESTHELA</strong></p>
                  </div>
                </div>
              </div>
              <div class="featured-icon-box style4 left-icon" (click)="
                  abrirVideo('https://www.youtube.com/embed/-nnyj7KsSk0')
                ">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                    <i class="ttm-num ti-info"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>
                      <a style="font-weight: 600; text-decoration: underline" (click)="
                          abrirVideo(
                            'https://www.youtube.com/embed/-nnyj7KsSk0'
                          )
                        ">
                        Instalación de Red Principal de Agua Potable
                      </a>
                    </h5>
                    <p><strong> BARRIO COSTA AZUL</strong></p>
                  </div>
                </div>
              </div>
              <div class="featured-icon-box style4 left-icon" (click)="
                  abrirVideo('https://www.youtube.com/embed/ZRlDncU4eoI')
                ">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                    <i class="ttm-num ti-info"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>
                      <a style="font-weight: 600; text-decoration: underline" (click)="
                          abrirVideo(
                            'https://www.youtube.com/embed/ZRlDncU4eoI'
                          )
                        ">
                        Red de agua potable
                      </a>
                    </h5>
                    <p><strong>URSEZA 2 - SECTOR 1</strong></p>
                  </div>
                </div>
              </div>
              <div class="featured-icon-box style4 left-icon" (click)="
                  abrirVideo('https://www.youtube.com/embed/bqArLigfPYo')
                ">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                    <i class="ttm-num ti-info"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>
                      <a style="font-weight: 600; text-decoration: underline" (click)="
                          abrirVideo(
                            'https://www.youtube.com/embed/bqArLigfPYo'
                          )
                        ">
                        Planta Potabilizadora "González Rubio"
                      </a>
                    </h5>
                    <p><strong>CONTROL DE CALIDAD DE AGUA</strong></p>
                  </div>
                </div>
              </div>
              <div class="featured-icon-box style4 left-icon" (click)="
                  abrirVideo('https://www.youtube.com/embed/C19ByrSaFQY')
                ">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                    <i class="ttm-num ti-info"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>
                      <a style="font-weight: 600; text-decoration: underline" (click)="
                          abrirVideo(
                            'https://www.youtube.com/embed/C19ByrSaFQY'
                          )
                        ">
                        Planta Potabilizadora de Agua SUR
                      </a>
                    </h5>
                    <p><strong>SISTEMA DE POTABILIZACIÓN DE AGUA</strong></p>
                  </div>
                </div>
              </div>
              <div class="featured-icon-box style4 left-icon" (click)="
                  abrirVideo('https://www.youtube.com/embed/81fUc3QN1HI')
                ">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                    <i class="ttm-num ti-info"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>
                      <a style="font-weight: 600; text-decoration: underline" (click)="
                          abrirVideo(
                            'https://www.youtube.com/embed/81fUc3QN1HI'
                          )
                        ">
                        Red de agua potable y guías domiciliarias
                      </a>
                    </h5>
                    <p><strong>CDLA. SANTA RITA</strong></p>
                  </div>
                </div>
              </div>
              <div class="featured-icon-box style4 left-icon" (click)="
                  abrirVideo('https://www.youtube.com/embed/P4m1x94AH68')
                ">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                    <i class="ttm-num ti-info"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>
                      <a style="font-weight: 600; text-decoration: underline" (click)="
                          abrirVideo(
                            'https://www.youtube.com/embed/P4m1x94AH68'
                          )
                        ">
                        Reforestación de 3 hectáreas de Mangle
                      </a>
                    </h5>
                    <p><strong>VIRGEN DEL CISNE</strong></p>
                  </div>
                </div>
              </div>
              <div class="featured-icon-box style4 left-icon" (click)="
                  abrirVideo('https://www.youtube.com/embed/HEw274qY48M')
                ">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                    <i class="ttm-num ti-info"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>
                      <a style="font-weight: 600; text-decoration: underline" (click)="
                          abrirVideo(
                            'https://www.youtube.com/embed/HEw274qY48M'
                          )
                        ">
                        Inclusión social y laboral de sectores vulnerables
                      </a>
                    </h5>
                    <p><strong>MATRIZ AGUAS MACHALA</strong></p>
                  </div>
                </div>
              </div>
              <div class="featured-icon-box style4 left-icon" (click)="
                  abrirVideo('https://www.youtube.com/embed/Yw3A9-SH724')
                ">
                <div class="featured-icon">
                  <div
                    class="ttm-icon ttm-icon_element-fill ttm-icon_element-background-color-grey ttm-icon_element-color-darkgrey ttm-icon_element-size-sm ttm-icon_element-style-rounded">
                    <i class="ttm-num ti-info"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>
                      <a style="font-weight: 600; text-decoration: underline" (click)="
                          abrirVideo(
                            'https://www.youtube.com/embed/Yw3A9-SH724'
                          )
                        ">
                        Campañas para la eliminación de la violencia de género
                      </a>
                    </h5>
                    <p><strong>MATRIZ AGUAS MACHALA</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="obras__img">
              <div class="obra__img" *ngFor="let id of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
                <img class="img-fluid" src="/assets/images/obras-flex/ob{{ id }}.jpg" alt="" (click)="
                    abrirImagen('/assets/images/obras-flex/ob' + id + '.jpg')
                  " />
              </div>
            </div>
            <!-- <div class="ttm_single_image-wrapper text-right">
              <img
                loading="lazy"
                class="img-fluid"
                src="/assets/images/optimizadas_machalaep/single-img-seven-home.jpg"
                alt=""
              />
            </div> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="section-title with-desc title-style-center_text clearfix">
            <div class="title-header">
              <h5>ENCUENTRA MÁS INFORMACIÓN MULTIMEDIA</h5>
              <h2 class="title">+ OBRAS PARA MACHALA</h2>
            </div>
            <div class="title-desc">
              Nuestra labor como empresa pública nos hace buscar la mejora
              continua.
              <br />
              Encuentra varios videos narrativos de estas obras que crean una
              mejor Machala día a día.
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="content-tab width-100">
        <div class="content-inner active">
          <div class="row multi-columns-row ttm-boxes-spacing-10px">
            <div class="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-12">
              <div class="featured-imagebox featured-imagebox-portfolio">
                <div class="featured-thumbnail">
                  <div class="ttm-post-format-video">
                    <iframe src="https://www.youtube.com/embed/81fUc3QN1HI"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-12">
              <div class="featured-imagebox featured-imagebox-portfolio">
                <div class="featured-thumbnail">
                  <div class="ttm-post-format-video">
                    <iframe src="https://www.youtube.com/embed/-o2ESfMzn3w"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-12">
              <div class="featured-imagebox featured-imagebox-portfolio">
                <div class="featured-thumbnail">
                  <div class="ttm-post-format-video">
                    <iframe src="https://www.youtube.com/embed/eIEQoK4BusA"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-12">
              <div class="featured-imagebox featured-imagebox-portfolio">
                <div class="featured-thumbnail">
                  <div class="ttm-post-format-video">
                    <iframe src="https://www.youtube.com/embed/KKva4dujzI0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-12">
              <div class="featured-imagebox featured-imagebox-portfolio">
                <div class="featured-thumbnail">
                  <div class="ttm-post-format-video">
                    <iframe src="https://www.youtube.com/embed/ZRlDncU4eoI"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-12">
              <div class="featured-imagebox featured-imagebox-portfolio">
                <div class="featured-thumbnail">
                  <div class="ttm-post-format-video">
                    <iframe src="https://www.youtube.com/embed/81fUc3QN1HI"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen=""></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="ttm-row services-section bg-layer bg-layer-equal-height break-991-colum res-991-mt-0 clearfix">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-12">
          <div class="col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-left-span">
            <div class="ttm-col-wrapper-bg-layer ttm-bg-layer">
              <div class="col-bg-img-thirteen"></div>
            </div>
            <div class="layer-content"></div>
          </div>
          <img loading="lazy" src="/assets/images/optimizadas_machalaep/col-bgimage-2.webp"
            class="ttm-equal-height-image" alt="bg-image" />
        </div>
        <div class="col-lg-7 col-md-12">
          <div class="about-content ttm-bg ttm-col-bgcolor-yes ttm-right-span ttm-bgcolor-darkgrey spacing-6">
            <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
            <div class="layer-content">
              <div class="section-title with-desc clearfix">
                <div class="title-header">
                  <h5>CIFRAS</h5>
                  <h2 class="title">NUESTRO TRABAJO EN NÚMEROS</h2>
                </div>
                <div class="title-desc">
                  Somos la Empresa Pública Municipal Aguas Machala,
                  administramos el servicio de agua potable del cantón Machala
                  desde Agosto de 2015. Llevamos +5 años al servicio de todos
                  los sectores de nuestra querida ciudad.
                </div>
              </div>
              <div class="separator clearfix">
                <div class="sep-line mt-10 mb-50"></div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="featured-icon-box left-icon icon-align-top">
                    <div class="featured-icon">
                      <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                        <i class="flaticon-handyman-tools"></i>
                      </div>
                    </div>
                    <div class="featured-content">
                      <div class="featured-title">
                        <h5>RESPONSABILIDAD</h5>
                      </div>
                      <div class="featured-desc">
                        <p>
                          Nuestra institución muestra responsabilidad
                          institucional día a día, para que los servicios de
                          agua potable y alcantarillado lleguen oportunamente a
                          todos los/as machaleños/as.
                        </p>
                      </div>
                      <a class="ttm-btn ttm-btn-size-sm ttm-icon-btn-right ttm-btn-color-white btn-inline mt-5 mb-20"
                        routerLink="/nosotros">NOSOTROS<i class="ti ti-angle-double-right"></i></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="featured-icon-box left-icon icon-align-top">
                    <div class="featured-icon">
                      <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                        <i class="flaticon-broken-cable-2"></i>
                      </div>
                    </div>
                    <div class="featured-content">
                      <div class="featured-title">
                        <h5>COMPROMISO</h5>
                      </div>
                      <div class="featured-desc">
                        <p>
                          Nuestra institución está comprometida en mejorar la
                          calidad de vida de los habitantes del cantón Machala,
                          mediante la prestación de servicios de agua potable y
                          saneamiento ambiental, que contribuyan al habitat y
                          buen vivir.
                        </p>
                      </div>
                      <a class="ttm-btn ttm-btn-size-sm ttm-icon-btn-right ttm-btn-color-white btn-inline mt-5 mb-20"
                        routerLink="/nosotros">NOSOTROS<i class="ti ti-angle-double-right"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="ttm-row fid-section mt_70 ttm-bg ttm-bgcolor-skincolor clearfix">
    <div class="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
    <div class="container">
      <div class="row ttm-facts-colum-sep">
        <div class="col-md-3">
          <div class="ttm-fid inside ttm-fid-view-topicon">
            <div class="ttm-fid-icon-wrapper"><i class="ti ti-star"></i></div>
            <div class="ttm-fid-contents">
              <h4><span>70.000</span></h4>
              <h3 class="ttm-fid-title">
                <span>Clientes<br /></span>
              </h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="ttm-fid inside ttm-fid-view-topicon">
            <div class="ttm-fid-icon-wrapper"><i class="ti ti-star"></i></div>
            <div class="ttm-fid-contents">
              <h4><span>280.000 </span></h4>
              <h3 class="ttm-fid-title">
                <span>Beneficiarios<br /></span>
              </h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="ttm-fid inside ttm-fid-view-topicon">
            <div class="ttm-fid-icon-wrapper"><i class="ti ti-star"></i></div>
            <div class="ttm-fid-contents">
              <h4><span>500.00 </span></h4>
              <h3 class="ttm-fid-title">
                <span>reclamos atendidos<br /></span>
              </h3>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="ttm-fid inside ttm-fid-view-topicon">
            <div class="ttm-fid-icon-wrapper"><i class="ti ti-star"></i></div>
            <div class="ttm-fid-contents">
              <h4><span>264 </span></h4>
              <h3 class="ttm-fid-title">
                <span>Colaboradores Activos<br /></span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="ttm-row bg-img9 multi-content-section clearfix">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="section-title with-desc clearfix">
            <div class="title-header">
              <h5>TRANSPARENCIA INSTITUCIONAL</h5>
              <h2 class="title">En nuestra gestión</h2>
            </div>
            <div class="title-desc">
              Somos una entidad pública que hace especial énfasis en la
              transparencia de todos nuestros procesos. Todos los años
              publicamos nuestra rendición de cuentas y Regimos nuestros
              procesos en la
              <strong><u><a class="ttm-textcolor-dark"
                    href="https://www.educacionsuperior.gob.ec/wp-content/uploads/downloads/2014/09/LOTAIP.pdf"
                    target="_blank">Ley Orgánica de Transparencia y Acceso a la Información
                    Pública.</a></u></strong>
              <br />
              Nuestros principios son:
            </div>
          </div>
          <div class="row pt-5">
            <div class="col-lg-6">
              <div class="featured-icon-box left-icon mb-5">
                <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                  <i class="flaticon-handyman-tools"></i>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>Integridad</h5>
                  </div>
                </div>
              </div>
              <div class="featured-icon-box left-icon mb-5">
                <div class="featured-icon">
                  <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                    <i class="flaticon-helmet"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>Rectitud</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="featured-icon-box left-icon mb-5">
                <div class="featured-icon">
                  <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                    <i class="flaticon-plumber"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>Entereza</h5>
                  </div>
                </div>
              </div>
              <div class="featured-icon-box left-icon mb-5">
                <div class="featured-icon">
                  <div class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-md">
                    <i class="flaticon-wall"></i>
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title">
                    <h5>Imparcialidad</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="accordion mt-40">
                <div class="toggle ttm-style-classic ttm-toggle-title-bgcolor-grey">
                  <div class="toggle-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne">Rendición de Cuentas 2020</a>
                  </div>
                  <div class="toggle-content">
                    <p>
                      Accede a los informes y documentos que generamos mes a mes
                      para que nuestra gestión muestre la transparencia con
                      nuestra ciudad necesita.
                    </p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15"
                      routerLink="/transparencia">Leer más<i class="ti ti-eye"></i></a>
                  </div>
                </div>

                <div class="toggle ttm-style-classic ttm-toggle-title-bgcolor-grey">
                  <div class="toggle-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">Rendición de Cuentas 2019</a>
                  </div>
                  <div class="toggle-content">
                    <p>
                      Revisa nuestro informe anual de actividades, presupuestos
                      y demás
                      <a href="https://aguasmachala.gob.ec/wp-content/uploads/2020/11/RENDICION-2019.pdf"
                        target="_blank">aquí</a>. O puedes revisar los documentos que generamos para cada
                      mes en el siguiente link.
                    </p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15"
                      routerLink="/transparencia">Leer más<i class="ti ti-eye"></i></a>
                  </div>
                </div>

                <div class="toggle ttm-style-classic ttm-toggle-title-bgcolor-grey">
                  <div class="toggle-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree">Rendición de Cuentas
                      2018</a>
                  </div>
                  <div class="toggle-content">
                    <p>
                      Revisa nuestro informe anual de actividades, presupuestos
                      y demás
                      <a href="https://aguasmachala.gob.ec/wp-content/uploads/2019/04/INFORME-DE-RENDICION-DE-CUENTAS-2018-AGUAS-MACHALA-1.pdf"
                        target="_blank">aquí</a>. O puedes revisar los documentos que generamos para cada
                      mes en el siguiente link.
                    </p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15"
                      routerLink="/transparencia">Leer más<i class="ti ti-eye"></i></a>
                  </div>
                </div>

                <div class="toggle ttm-style-classic ttm-toggle-title-bgcolor-grey">
                  <div class="toggle-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseFour">Rendición de Cuentas
                      2017</a>
                  </div>
                  <div class="toggle-content">
                    <p>
                      Revisa nuestro informe anual de actividades, presupuestos
                      y demás
                      <a href="https://aguasmachala.gob.ec/wp-content/uploads/2018/06/Rendicion-de-Cuentas-2017B.pdf"
                        target="_blank">aquí</a>. O puedes revisar los documentos que generamos para cada
                      mes en el siguiente link.
                    </p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15"
                      routerLink="/transparencia">Leer más<i class="ti ti-eye"></i></a>
                  </div>
                </div>

                <div class="toggle ttm-style-classic ttm-toggle-title-bgcolor-grey">
                  <div class="toggle-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseFive">Rendición de Cuentas
                      2016</a>
                  </div>
                  <div class="toggle-content">
                    <p>
                      Accede a los informes y documentos que generamos mes a mes
                      para que nuestra gestión muestre la transparencia con
                      nuestra ciudad necesita.
                    </p>
                    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-black mt-15"
                      routerLink="/transparencia">Leer más<i class="ti ti-eye"></i></a>
                  </div>
                </div>
                <br />
              </div>
              <div class="ttm-btn-box pr-20 pb-20">
                <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-black"
                  routerLink="/transparencia" title="">MÁS INFORMACIÓN<i class="ti ti-angle-double-right"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="ttm_single_image-wrapper mb-35 text-right">
            <img loading="lazy" class="img-fluid" src="/assets/images/optimizadas_machalaep/single-img-nine.webp"
              alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- TODO Agregar una peque;a seccion de vivienda -->
  <section class="ttm-row team-section-2 break-991-colum ttm-bgcolor-darkgrey clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="section-title with-desc title-style-center_text clearfix">
            <div class="title-header">
              <h5>Tu opinión es importante</h5>
              <h2 class="title">Opiniones de nuestros clientes</h2>
            </div>
            <div class="title-desc">
              Nuestros vecinos y vecinas confirman nuestro compromiso con la
              ciudad, esto como consecuencia de nuestro trabajo coordinado con
              todos los machaleños/as.
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="">
        <owl-carousel-o [options]="customOptionsTestimonios" class="wrap-team team-slide"
          *ngIf="customOptionsTestimonios">
          <ng-template carouselSlide>
            <div class="featured-imagebox featured-imagebox-team style2">
              <div class="featured-thumbnail">
                <img loading="lazy" class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/opiniones/Opinion 1.webp" alt="image" />
              </div>
              <div class="ttm-box-view-overlay ttm-team-box-view-overlay">
                <div class="featured-content featured-content-team">
                  <p class="category">
                    Gracias Sr. Alcalde, Dario Macas, por el agua que nos ha
                    dado aquí en la ciudadela Luz de América.
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="featured-imagebox featured-imagebox-team style2">
              <div class="featured-thumbnail">
                <img loading="lazy" class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/opiniones/Opinion 2.webp" alt="image" />
              </div>
              <div class="ttm-box-view-overlay ttm-team-box-view-overlay">
                <div class="featured-content featured-content-team">
                  <p class="category">
                    Gracias Aguas Machala, por el agua en Luz de América.
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="featured-imagebox featured-imagebox-team style2">
              <div class="featured-thumbnail">
                <img loading="lazy" class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/opiniones/Opinion 3.webp" alt="image" />
              </div>
              <div class="ttm-box-view-overlay ttm-team-box-view-overlay">
                <div class="featured-content featured-content-team">
                  <p class="category">
                    Gracias Sr. Alcalde, por el agua, una gran ayuda para
                    nosotros.
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="featured-imagebox featured-imagebox-team style2">
              <div class="featured-thumbnail">
                <img loading="lazy" class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/opiniones/Opinion 4.webp" alt="image" />
              </div>
              <div class="ttm-box-view-overlay ttm-team-box-view-overlay">
                <div class="featured-content featured-content-team">
                  <p class="category">
                    Gracias Ing. Dario Macas, Alcalde de nuestra ciudad, por las
                    gestiones para que tengamos aguita potable.
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="featured-imagebox featured-imagebox-team style2">
              <div class="featured-thumbnail">
                <img loading="lazy" class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/opiniones/Opinion 5.webp" alt="image" />
              </div>
              <div class="ttm-box-view-overlay ttm-team-box-view-overlay">
                <div class="featured-content featured-content-team">
                  <p class="category">
                    El agua que hemos visto aqui en el pozo, como en las casas,
                    esta cristalina.
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="featured-imagebox featured-imagebox-team style2">
              <div class="featured-thumbnail">
                <img loading="lazy" class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/opiniones/Opinion 6.webp" alt="image" />
              </div>
              <div class="ttm-box-view-overlay ttm-team-box-view-overlay">
                <div class="featured-content featured-content-team">
                  <p class="category">
                    Revisamos el agua del pozo, y en las casas y muestra alta
                    calidad en su sabor.
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="featured-imagebox featured-imagebox-team style2">
              <div class="featured-thumbnail">
                <img loading="lazy" class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/opiniones/Opinion 7.webp" alt="image" />
              </div>
              <div class="ttm-box-view-overlay ttm-team-box-view-overlay">
                <div class="featured-content featured-content-team">
                  <p class="category">
                    Gracias a Aguas Machala, les agradezco su trabajo, luego de
                    años tenemos agua corriente.
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="featured-imagebox featured-imagebox-team style2">
              <div class="featured-thumbnail">
                <img loading="lazy" class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/opiniones/Opinion 8.webp" alt="image" />
              </div>
              <div class="ttm-box-view-overlay ttm-team-box-view-overlay">
                <div class="featured-content featured-content-team">
                  <p class="category">
                    Aguas Machala nos abrió las puertas, confiando en nuestras
                    capacidades, porque merecemos una oportunidad laboral en
                    igualdad de condiciones.
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="featured-imagebox featured-imagebox-team style2">
              <div class="featured-thumbnail">
                <img loading="lazy" class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/opiniones/Opinion 9.webp" alt="image" />
              </div>
              <div class="ttm-box-view-overlay ttm-team-box-view-overlay">
                <div class="featured-content featured-content-team">
                  <p class="category">
                    Muchas gracias por el agua, que tanta falta nos hacia. Dios
                    les bendiga.
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="featured-imagebox featured-imagebox-team style2">
              <div class="featured-thumbnail">
                <img loading="lazy" class="img-fluid"
                  src="/assets/images/optimizadas_machalaep/opiniones/Opinion 0.webp" alt="image" />
              </div>
              <div class="ttm-box-view-overlay ttm-team-box-view-overlay">
                <div class="featured-content featured-content-team">
                  <br />
                  <p class="category">
                    Muchisimas gracias al Alcalde por el agua potable que ahora
                    disponemos.
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="mt-50 text-center">
            <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-white"
              routerLink="/contacto">DANOS TU OPINIÓN<i class="ti ti-angle-double-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="ttm-row desc-only-section ttm-bgcolor-skincolor text-center clearfix">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h6>
            Puedes darnos tu opinión vía telefónica :
            <a href="tel:+59373702010">+593 (7) 3702010</a> <br />
            o vía e-mail&nbsp;<a href="mailto:info@aguasmachala.gob.ec">info@aguasmachala.gob.ec</a>
          </h6>
        </div>
      </div>
    </div>
  </section>

  <section class="ttm-row testimonial2-section bg-img6 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
    <div class="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title title-style-center_text pb-4 clearfix">
            <div class="title-header">
              <h5>COMPROMISO</h5>
              <h2 class="title">Nuestras Autoridades</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="col-bg-img-four ttm-col-bgimage-yes pb-10 autoridades-container">
            <div class="filter-blue">
              <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
            </div>

            <div class="layer-content ttm-textcolor-white" *ngIf="customOptionsAutoridades">
              <owl-carousel-o [options]="customOptionsAutoridades" class="testimonial-slide mt_60">
                <ng-template carouselSlide>
                  <div style="width: 100%">
                    <div class="testimonials style2">
                      <div class="testimonial-content" style="margin: 5% auto">
                        <div class="testimonial-avatar">
                          <div class="testimonial-img">
                            <img loading="lazy" class="img-center"
                              src="/assets/images/optimizadas_machalaep/autoridad-01.1.webp" alt="testimonial-img"
                              style="width: 25%; height: 25%" />
                          </div>
                        </div>
                        <div class="ttm-ratting-star">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </div>
                        <blockquote style="color: white">
                          “Demostramos con hechos de que bajo una administración
                          honesta, responsable y empática, el sueño de dotar a
                          todos los hogares de la cuidad de Machala con el
                          servicio de agua potable, será una realidad !!”
                        </blockquote>
                        <div class="testimonial-caption">
                          <h6>Ing Darío Macas Salvatierra</h6>
                          <label>Alcalde de Machala</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template carouselSlide>
                  <div style="width: 100%">
                    <div class="testimonials style2">
                      <div class="testimonial-content" style="margin: 5% auto">
                        <div class="testimonial-avatar">
                          <div class="testimonial-img">
                            <img loading="lazy" class="img-center"
                              src="/assets/images/optimizadas_machalaep/autoridad-02.2.webp" alt="testimonial-img"
                              style="width: 25%; height: 25%" />
                          </div>
                        </div>
                        <div class="ttm-ratting-star">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </div>
                        <blockquote style="color: white">
                          “El pasado año 2020, ha sido de grandes cambios, obras
                          y proyectos. Recibimos este 2021 con entusiasmo, con
                          mejoras a la ciudad. Esta administración que avanza
                          con el Ing. Dario Macas, reafirma su cariño y
                          compromiso de trabajo con la ciudad. ¡Aguas Machala
                          trabaja por ti!”
                        </blockquote>
                        <div class="testimonial-caption">
                          <h6>Ing. Goretty González Aguilar</h6>
                          <label>Gerente General Aguas Machala</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <app-slideepaliada></app-slideepaliada>
    </div>
  </section>
</div>
