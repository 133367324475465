import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  BASE_URL = 'https://www.aguasmachala.com:3000/api/v1';
  httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };

  colors = [];

  constructor(private http: HttpClient) {}

  concatURL(subURL: string): string {
    return this.BASE_URL + subURL;
  }

  makePostRequest(
    URL: string,
    data: any,
    headers = this.httpOptions
  ): Observable<any> {
    return this.http.post(URL, data, headers);
  }

  makeGetRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.http.get(URL, headers);
  }

  makeDelRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.http.delete(URL, headers);
  }

  makePutRequest(
    URL: string,
    data: any,
    headers = this.httpOptions
  ): Observable<any> {
    return this.http.put(URL, data, headers);
  }

  payment(
    nroAccount: number,
    total: number,
    card: string,
    lastBillNumber: string,
    idPago: string,
    cardNumber: string
  ): Observable<any> {
    const path = this.concatURL('/payment');
    return this.makePostRequest(path, {
      NUMERO_CUENTA: nroAccount,
      VALOR_PAGAR: total,
      FORMA_PAGO: card,
      ULTIMA_FACTURA: lastBillNumber,
      ID_PAGO: idPago,
      NUMERO_TARJETA: cardNumber
    });
  }

  checkCashOpen(): Promise<any> {
    const path = this.concatURL('/payment/cash-open');
    return this.makeGetRequest(path).toPromise();
  }
}
