import { Deserializable } from "./deserializable.model";


export class ConsultaDeuda {
  anio:string;
  mes:string;
  monto:string;
  

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

