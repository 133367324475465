import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { NewReclamoDomicilio } from 'src/app/model/new-reclamo-domicilio/new-reclamo-domicilio.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewReclamoDomicilioService {
  postId: String;
  constructor(private httpClient: HttpClient) { }
  public postNewReclamoDom(newReclamoD: NewReclamoDomicilio) {//: Observable<NewReclamoDomicilio>


    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(newReclamoD);
    // var api_uri = environment.API_URL.toString() + `nuevo_reclamo_domicilio`;
    var api_uri = 'https://www.aguasmachala.com:3000/api/v1/reclamos';
    // var api_uri = 'http://localhost:3000/api/v1/reclamos';
    // var api_uri = '/api/v1/reclamos';
    return this.httpClient.post(api_uri, body, { 'headers': headers })

  }

}
