import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { NewReclamoExterno } from 'src/app/model/new-reclamo-externo/new-reclamo-externo.model';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewReclamoExternoService {
  postId: String;
  constructor(private httpClient: HttpClient) { }
  public postNewReclamoExt(newReclamoE: NewReclamoExterno) {//: Observable<NewReclamoExterno>


    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(newReclamoE);
    // console.log(body);
    // var api_uri = environment.API_URL.toString() + `nuevo_reclamo_ciudad`;
     var api_uri = 'https://www.aguasmachala.com:3000/api/v1/reclamos';
    // var api_uri = 'http://localhost:3000/api/v1/reclamos';
    // var api_uri = '/api/v1/reclamos';
    return this.httpClient.post(api_uri, body, { 'headers': headers })
  }

  public reverseGeocoding(lat: string, lng: string) {//: Observable<NewReclamoExterno>


    const headers = { 'content-type': 'application/json' };
    // console.log(body);
    // var api_uri = environment.API_URL.toString() + `nuevo_reclamo_ciudad`;
    // var api_uri = 'https://machalaep-server.herokuapp.com/api/v1/reclamos';
    var url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`;
    // var api_uri = '/api/v1/reclamos';
    return this.httpClient.get(url, { 'headers': headers }).toPromise();
  }

}
