import { PaymentResponseData } from './../../model/payment-response';
import { PaymentService } from './../../service/service/payment.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatafastService } from 'src/app/service/service/datafast.service';
import { MachalaEpService } from 'src/app/service/service/machala-ep.service';

@Component({
  selector: 'app-post-payment',
  templateUrl: './post-payment.component.html',
  styleUrls: ['./post-payment.component.scss'],
})
export class PostPaymentComponent implements OnInit {
  successPayment: boolean = false;
  erroRegisterPayment: boolean = false;
  errorDataPayment: boolean = false;
  dataPayment: any;
  messages = {
    '02': 'Problemas con la tarjeta llame al centro de autorización de su banco.',
    '03': 'Establecimiento inválido. Verificar si el comercio tiene asignado el tipo de crédito reportado, si todo está bien entonces llamar a DF y consultar.',
    '04': 'La tarjeta puede haber sido reportada como robada o está utilizando una T.C. no válida.',
    '05': 'Transacción rechazada por el banco.',
    '12': 'Transacción invalida, puede ser por que la tarjeta no tiene permitido pagos por internet.',
    '13': 'Monto inválido.',
    '14': 'Error en el número de tarjeta.',
    '15': 'Error en el número de tarjeta.',
    '19': 'Transacción rechazada, favor reintente.',
    '41': 'Tarjeta pérdida.',
    '43': 'Tarjeta robada.',
    '51': 'Fondos insuficientes.',
    '54': 'Tarjeta expirada.',
    '57': 'Transacción inválida o no permitida.',
    '61': 'Monto excede el crédito disponible.',
    '62': 'Tarjeta restringida.',
    '76': 'Cuenta Inválida',
    '79': 'Tarjeta expirada.',
    '88': 'Transacción rechazada, favor reintente.',
    FORMAT_ERROR: 'Numero de cedula invalido.',
    ER: 'Montos menor a un dolar, no puede ser procesados.',
  };

  testData = {
    "id": "8ac7a4a17dd78ba5017dd8e815471ff0",
    "paymentType": "DB",
    "paymentBrand": "DISCOVER",
    "amount": "14.08",
    "currency": "USD",
    "descriptor": "7511.2853.4692 AGUASMACHALA",
    "merchantTransactionId": "3957158-27",
    "result": {
      "code": "800.100.176",
      "description": "transaction declined (account temporarily not available. Please try again later)"
    },
    "resultDetails": {
      "ConnectorTxID1": "8ac7a4a17dd78ba5017dd8e815471ff0",
      "RiskFraudStatusCode": "ACCEPT",
      "RequestId": "350565382453",
      "ReferenceNbr": "211220_000092",
      "EXTERNAL_SYSTEM_LINK": "https://csi-stage.redworldwide.com/index.red#transactiondetail/000537210268XAG20211220123348796",
      "BatchNo": "211220",
      "TotalAmount": "14.08",
      "OrderId": "751128534692",
      "ExtendedDescription": "Por favor reintente la transaccion",
      "RiskStatusCode": "PENDING",
      "Response": "19",
      "clearingInstituteName": "Datafast",
      "RiskResponseCode": "0100",
      "AcquirerResponse": "19",
      "action": "created",
      "RiskOrderId": "000537210268XAG20211220123348796",
      "ReferenceNo": "000092"
    },
    "card": {
      "bin": "655732",
      "binCountry": "EC",
      "last4Digits": "3713",
      "holder": "AGUAMACHALA",
      "expiryMonth": "10",
      "expiryYear": "2030"
    },
    "customer": {
      "givenName": "Christian",
      "surname": "Collaguazo Malla",
      "middleName": "Xavier",
      "merchantCustomerId": "84140",
      "phone": "0978709809",
      "email": "christian.collaguazom@gmail.com",
      "identificationDocType": "IDCARD",
      "identificationDocId": "0791727251",
      "ip": "191.100.28.115",
      "ipCountry": "EC"
    },
    "billing": {
      "street1": "URB. PORTAL DEL RIO # .;MZ. J SL. 10-11 ESQ.",
      "postcode": "070151",
      "country": "EC"
    },
    "shipping": {
      "street1": "URB. PORTAL DEL RIO # .;MZ. J SL. 10-11 ESQ.",
      "country": "EC"
    },
    "customParameters": {
      "SHOPPER_VAL_BASEIMP": "0",
      "SHOPPER_EndToEndIdentity": "a0a3d2022cd0aa560962939e4c0ea3bf88ec812e944abf6b773457079c8c13ed",
      "CTPE_DESCRIPTOR_TEMPLATE": "",
      "SHOPPER_ECI": "0103910",
      "SHOPPER_MID": "1000000505",
      "SHOPPER_PSERV": "17913101",
      "SHOPPER_VAL_BASE0": "14.08",
      "SHOPPER_TID": "PD100406",
      "SHOPPER_VAL_IVA": "0",
      "SHOPPER_VERSIONDF": "2"
    },
    "risk": { "score": "0", "parameters": { "USER_DATA2": "AGUASMACHALA" } },
    "cart": {
      "items": [
        {
          "name": "Pago de consumo de agua",
          "description": "Pago de consumo de agua. Fecha de la transacción: 2021-12-20T05:00:00.000Z",
          "quantity": "1",
          "price": "14.08"
        }
      ]
    },
    "buildNumber": "d32f3f32d2e4068242e61427fea24aeac7a91bbc@2021-12-20 12:03:32 +0000",
    "timestamp": "2021-12-20 17:33:50+0000",
    "ndc": "7B364991CD4A3E984154A0E722D2BFC7.uat01-vm-tx03"
  };


  message = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    private datafastService: DatafastService,
    private paymentService: PaymentService,
    private router: Router,
    private machalaEp: MachalaEpService
  ) {}

  ngOnInit(): void {
    // this.saveTransaction(this.testData);
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log(params);
      if (params && params.id) {
        this.datafastService.getResourcePath(params.id).subscribe(
          (resp: PaymentResponseData) => {
            this.saveTransaction(resp);
          },
          (err) => {
            console.log(err);
            this.router.navigateByUrl('/consultadeudas');
          }
        );
      }
    });
  }

  async saveTransaction(resp: PaymentResponseData) {
    try {
      // console.log(JSON.stringify(resp));

      if (resp.resultDetails.Response.toLocaleLowerCase() === '00') {
        this.dataPayment = resp;
        await this.paymentService
          .payment(
            parseInt(resp.customer.merchantCustomerId, 10),
            parseFloat(resp.amount),
            resp.paymentBrand,
            resp.merchantTransactionId.split('-')[0],
            resp.id,
            resp.card.last4Digits
          )
          .toPromise();
        try {
          await this.machalaEp.updateTransaction(
            resp.merchantTransactionId.split('-')[1],
            {
              estado: 'pagado',
              tarjeta: resp.paymentBrand,
              id_pago: resp.id,
              numero_tarjeta: resp.card.last4Digits,
            }
          );
        } catch (error) {
          // console.log('Error al cambiar el registro: pagado');
        }
        this.successPayment = true;
      } else {
        this.dataPayment = resp;
        const message = this.messages[resp.resultDetails.Response];
        if (message) {
          this.message = message;
        } else {
          this.message =
            'Revisa la información ingresada y vuelve a intentar, si el problema persiste póngase en contacto con su banco.';
        }
        this.errorDataPayment = true;
      }
    } catch (error) {
      console.log(error);

      try {
        await this.machalaEp.updateTransaction(
          resp.merchantTransactionId.split('-')[1],
          {
            estado: 'no-procesado',
            tarjeta: resp.paymentBrand,
            id_pago: resp.id,
            numero_tarjeta: resp.card.last4Digits,
          }
        );
      } catch (error) {
        console.log('Error al cambiar el registro: no-registrado');
      }
      this.erroRegisterPayment = true;
    }
  }
}
