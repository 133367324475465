import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { Noticia } from 'src/app/model/noticia/noticia.model';
import { Noticias } from 'src/app/model/noticias/noticias.model';
import { NoticiaService } from 'src/app/service/noticia/noticia.service';
import { MachalaEpService } from 'src/app/service/service/machala-ep.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  // public noticias: Noticias[];
  // public categorias;
  // public urls;

  noticia: Noticia;

  public noticiasLast: Noticias[];
  public categorias;
  public urls_imagenes;
  public urls_videos;
  public mesesList = [
    'En',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];
  public mesS;
  public diaS;
  constructor(
    private router: Router,
    private noticiaService: NoticiaService,
    private spinner: NgxSpinnerService,
    private machalaEp: MachalaEpService
  ) {}

  ngOnInit(): void {
    this.getNoticia();
  }
  customUrlsNews: OwlOptions = {
    dots: false,
    margin: 25,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  customImgsNew: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 3000,
    dots: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    margin: 25,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
  customTagsNews: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 4000,
    dots: false,
    loop: true,
    items: 2,
    margin: 1,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  customNews: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 7000,
    dots: false,
    loop: true,
    items: 1,
    margin: 25,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  public getNoticia() {
    this.spinner.show();

    this.machalaEp.obtenerUltimaNoticia().subscribe((resp) => {
      if (resp) {
        this.noticia = resp[0];
        // console.log(this.noticia);
      }
    });
    this.spinner.hide();
  }
}
