export class Transparencia {
    anio: string;
    mes: string;


    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
