import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ConsultaReclamo } from 'src/app/model/consulta-reclamo/consulta-reclamo.model';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConsultaReclamoService {

  constructor(private httpClient: HttpClient) { }

  public getAllReclamos(tipoid: String, id: String): Observable<ConsultaReclamo[]> {
    // var api_uri = environment.API_URL.toString() + `get_consulta_reclamo/${tipoid}/${id}`;
    var api_uri = `https://www.aguasmachala.com:3000/api/v1/reclamos?tipoid=${tipoid}&id=${id}`;
    // var api_uri = `/api/v1/reclamos?tipoid=${tipoid}&id=${id}`;
    return this.httpClient.get<ConsultaReclamo[]>(api_uri).pipe(
      map(data => data.map(data => new ConsultaReclamo().deserialize(data)))
    );
  }
}
