<app-header></app-header>
<main class="mt-115">
  <router-outlet></router-outlet>
  <div class="fab__container">
    <div class="fab__icon-holder fab-btn btn">
      <i class="fas fa-user-friends"></i>
      <span class="d-none d-md-inline"> Servicios</span>
    </div>
    <ul class="fab__options">
      <li>
        <a target="_blank" href="http://181.39.20.163:82/cw_client_login">
          <span class="fab__label d-inline">Consulta y Pago de Deudas</span>
        </a>
        <a target="_blank" href="http://181.39.20.163:82/cw_client_login">
          <div class="fab__icon-holder">
            <i class="fas fa-file-invoice-dollar"></i>
          </div>
        </a>
      </li>
      <li >
        <a href="http://181.39.20.163:82/cw_client_login" target="_blank" rel="noopener noreferrer">
          <span class="fab__label d-inline">Realizar Denuncia</span>
        </a>
        <a href="http://181.39.20.163:82/cw_client_login" target="_blank" rel="noopener noreferrer">
          <div class="fab__icon-holder">
            <i class="fas fa-comments"></i>
          </div>
        </a>
      </li>
      <li >
        <a href="http://181.39.20.163:82/cw_client_login" target="_blank" rel="noopener noreferrer">
          <span class="fab__label d-inline">Realizar Reclamo</span>
        </a>
        <a href="http://181.39.20.163:82/cw_client_login" target="_blank" rel="noopener noreferrer">
          <div class="fab__icon-holder">
            <i class="far fa-id-badge"></i>
          </div>
        </a>
      </li>
    </ul>
  </div>
</main>
<app-footer></app-footer>
