import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewMensaje } from '../../model/new-mensaje/new-mensaje.model';

@Injectable({
  providedIn: 'root'
})
export class NewMensajeService {
  constructor(private httpClient: HttpClient) { }
  public postNewMensaje(newMensaje:NewMensaje){
    const headers = { 'content-type': 'application/json'}
    const body=JSON.stringify(newMensaje);
    var api_uri = 'https://www.aguasmachala.com:3000/api/v1/email/send';
    // var api_uri = '/api/v1/email/send';
    return this.httpClient.post(api_uri, body,{'headers':headers})

  }
}
