<ngx-spinner></ngx-spinner>
<div class="ttm-page-title-row">
    <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="title-box ttm-textcolor-white">
                    <div class="page-title-heading">
                        <h1 class="title">Contacto</h1>
                    </div>
                    <div class="breadcrumb-wrapper">
                        <span>
                            <a title="Homepage" routerLink="/"><i class="ti ti-home"></i></a>
                        </span>
                        <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
                        <span><span style="color: #0095D9;font-weight: bold;">Contacto</span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="site-main single">
    <div class="sidebar ttm-sidebar-right ttm-bgcolor-white break-991-colum clearfix">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 content-area">
                    <div class="ttm-blog-classic-box-comment">
                        <div id="comments" class="comments-area">
                            <div class="comment-respond">
                                <h3 class="comment-reply-title" style="text-align: center;">Comunícate con nosotros</h3>
                                <form action="#" method="post" id="commentform" class="comment-form">
                                    <p class="comment-form-comment">
                                        <textarea id="ipt_mensaje" placeholder="Mensaje" name="comment" cols="45" rows="8" aria-required="true"></textarea>
                                    </p>
                                    <p class="comment-form-author">
                                        <input id="ipt_autor" placeholder="Nombre (requerido)" name="author" type="text" value="" size="30" aria-required="true">
                                    </p>
                                    <p class="comment-form-email">
                                        <input id="ipt_email" placeholder="Email (requerido)" name="email" type="text" value="" size="30" aria-required="true">
                                    </p>
                                    <p class="form-submit">
                                        <input name="submit" type="submit" id="btn_enviar" class="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-bgcolor-skincolor" value="Enviar" (click)="btnEnviarContacto()">
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="ttm-row about3-section ttm-bgcolor-grey pb-60">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div id="mapContact" style="height: 100vh"></div>
                </div>
                <div class="col-md-6 col-sm-12 pb-20 pt-20 pr-20 pl-60">
                    <h3 style="text-align: center;">Datos de Contacto</h3>
                    <p> Te proveemos todos los medios por los cuales puedes comunicarte con nosotros. O puedes acudir presencialmente a nuestras oficinas (las encuentras en el mapa).</p>
                    <h5>
                        Correo Electrónico:
                    </h5>
                    <p>
                        <a href="mailto:info@aguasmachala.gob.ec">
                            info@aguasmachala.gob.ec
                        </a>
                    </p>
                    <h5>
                        Dirección:
                    </h5>
                    <p>
                        Av. 9 de Mayo e/. Manuel Serrano y Arízaga.
                        <br>
                        <a href="https://www.google.com/maps/place/AGUAS+MACHALA+EP/@-3.2624441,-79.9629331,18.75z/data=!4m13!1m7!3m6!1s0x90330e58cf8376ab:0x1620c4d05b5ae54a!2sGral.+Manuel+Serrano+%26+Av.+9+de+Mayo,+Machala!3b1!8m2!3d-3.2625387!4d-79.9620593!3m4!1s0x0:0xb5e040575d850266!8m2!3d-3.2619839!4d-79.961814"
                            target="_blank">Ver en Google Maps.</a>
                    </p>
                    <h5>
                        Celular:
                    </h5>
                    <p>
                        +593 (7) 3702010
                    </p>
                    <div style=" text-align: center; ">
                        <div style="  justify-content: center;">
                            <ul class="social-icons">
                                <li style="margin: 1em;"><a href="https://www.facebook.com/aguasmachalaep" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li style="margin: 1em;"><a href="https://twitter.com/aguas_ep" target="_blank"><i
                                            class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li style="margin: 1em;"><a href="https://www.youtube.com/channel/UCZjLjxrrb6OGH-BUP9PESdw" target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i></a></li>
                                <li style="margin: 1em;"><a href="https://www.instagram.com/aguasmachala/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- <section>
    <app-payment-button></app-payment-button>
</section> -->

<section class="ttm-row services2-section bg-img4  pb-120  clearfix">
    <div class="container">
        <div class="section-title clearfix" style="margin-top: 5%;">
            <div class="title-header">
                <h5>CONOCE</h5>
                <h2 class="title">Empresas Públicas Aliadas</h2>
            </div>
        </div>
        <div class="container">
            <app-slideepaliada></app-slideepaliada>
        </div>
    </div>
</section>

