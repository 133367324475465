export class NewReclamoExterno {
  nombre: string;
  tipoid: string;
  id: string;
  email: string;
  tmovil: string;
  direccion: string;
  descripcion: string;
  latlong: string;
  categoria: string;
  tiporeclamo: string;
  tiposolicitante: string;
  constructor(
    nombre: string,
    tipoid: string,
    id: string,
    email: string,
    tmovil: string,
    direccion: string,
    descripcion: string,
    latlong: string,
    categoria: string,
    tiporeclamo: string,
    tiposolicitante: string
  ) {
    this.nombre = nombre;
    this.tipoid = tipoid;
    this.id = id;
    this.email = email;
    this.tmovil = tmovil;
    this.direccion = direccion;
    this.descripcion = descripcion;
    this.latlong = latlong;
    this.categoria = categoria;
    this.tiporeclamo = tiporeclamo;
    this.tiposolicitante = tiposolicitante;
  }
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
