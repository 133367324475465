<div class="container-fluid py-5 my-5" style="min-height: 180vh;">
    <div class="row d-flex justify-content-center ">
        <div class="col-md-8 col-11 text-center px-1 ">
            <a href={{announcement.titulo_url}} target="_blank" rel="noopener noreferrer" title="Descargar Archivo">
                <h3>{{announcement.titulo}}</h3>
            </a>
        </div>
         <div class="col-md-8 col-11 text-center mt-4 ">
            <div class="row d-flex justify-content-center  align-items-center">
                <div class="col-md-10 col-12">
                    <h6>REGLAS PARA LAS PERSONAS QUE POSTULEN A LOS
                        CONCURSOS DE MÉRITO Y OPOSICIÓN</h6>

                </div>
                <div class="col-md-9 col-11 d-flex px-0 ">
                    <div class="row">
                        <div class="col-12 " title="Ver reglas" role="button" data-toggle="collapse"
                            data-target="#collapseRule" aria-expanded="false" aria-controls="collapseRule">
                            <h6><b>Ver Detalles <i class="fa fa-plus " aria-hidden="true"></i></b></h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-10 col-12">
                    <div class="collapse" id="collapseRule">
                        <div class="card card-body">
                            <ol>
                                <li>
                                    <p><i class="fa fa-circle" style="scale: 0.5;" aria-hidden="true"></i> El postulante
                                        podrá aplicar exclusivamente a un puesto por convocatoria y deberá elegir
                                        correctamente la instrucción formal y experiencia requerida para el puesto.</p>
                                </li>
                                <li>
                                    <p><i class="fa fa-circle" style="scale: 0.5;" aria-hidden="true"></i> Una vez que
                                        aplique a un puesto, el postulante no podrá modificar el registro "Hoja de Vida"
                                        para la vacante que ha postulado y, para efectos del concurso de méritos y
                                        oposición.</p>
                                </li>
                                <li>
                                    <p><i class="fa fa-circle" style="scale: 0.5;" aria-hidden="true"></i> El postulante
                                        es el único responsable de la veracidad y exactitud de la información y de sus
                                        declaraciones constantes en el registro "Hoja de Vida".</p>
                                </li>
                                <li>
                                    <p><i class="fa fa-circle" style="scale: 0.5;" aria-hidden="true"></i> Es
                                        responsabilidad de la o el postulante el monitorear su participación durante
                                        todo el
                                        concurso en la página web de AGUAS MACHALA EP.</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div class="col-md-6 col-11 mt-4 text-center">
            <div class="alert alert-primary" role="alert">
                <div class="row">
                    <div class="col-12">
                        LA POSTULACIÓN DEBE SER ENVIADA A LA DIRECCIÓN ELECTRÓNICA
                    </div>
                    <div class="col-12">
                        <a title="Enviar Correo" href="mailto:{{announcement.correo}}">
                            {{announcement.correo}}
                        </a>
                    </div>
                    <div class="col-12 px-5">
                        <p style="color:black">
                            <b>*Nota: </b>{{announcement.nota}}
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
     <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-5 col-11 text-center px-1 borderColor mt-3">
            <h4>LA POSTULACIÓN NO TIENE COSTO</h4>
        </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-8 col-11 text-center mt-4">
            <a href={{announcement.hoja_de_vida_url}} target="_blank" role="button" class="linkRules"
                title="Descargar Formato de hoja de vida">
                <h6><i class="fa fa-file-pdf-o" aria-hidden="true"></i> FORMATO HOJA DE VIDA</h6>
            </a>
        </div>
    </div>
     <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-8 col-11 text-center mt-4">
            <a href={{announcement.cronograma_url}} target="_blank" role="button" class="linkRules"
                title="Descargar cronograma de mérito y oposición">
                <h6><i class="fa fa-file-pdf-o" aria-hidden="true"></i> CRONOGRAMA DE CONCURSO DE MÉRITO Y OPOSICIÓN
                </h6>
            </a>
        </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-8 col-11 text-center mt-4">
            <a href={{announcement.manual_descripcion_url}} target="_blank" role="button" class="linkRules"
                title="Descargar manual de descripción, valoración y clasificación de puestos.">
                <h6><i class="fa fa-file-pdf-o" aria-hidden="true"></i> MANUAL DE DESCRIPCIÓN, VALORACIÓN Y CLASIFICACIÓN DE PUESTOS.
                </h6>
            </a>
        </div>
    </div>

     <div class="row d-flex justify-content-center align-items-center">
        <div class="col-md-8 col-11 text-start px-md-4 px-4 borderColor ">
            <h3>Cargos</h3>
        </div>
    </div>
    
    <div class="row d-flex justify-content-center align-items-center my-5">
        <div class="col-md-8 col-11">
            <div class="row">
                <div class="col-md-6 col-11 text-start  px-5 my-3" *ngFor="let item of announcement.charges">
                    <div class="row">
                        <div class="col-12  ">
                            <h6 class="borderButtonColor">{{item.nombre}}</h6>
                        </div>
                        <div class="col-md-5 col-12">
                            <div role="button" data-toggle="collapse" [attr.data-target]="'#collapse_' + item.id_cargo"
                                aria-expanded="false" aria-controls="collapse">
                                <h6><b>Ver Detalles <i class="fa fa-plus " aria-hidden="true"></i></b></h6>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="collapse mt-1" [attr.id]="'collapse_' + item.id_cargo">
                                <div class="card card-body">
                                    <div class="row">
                                        <div class="col-12 clearfix" *ngFor="let file of item.files">
                                            <div class="row">
                                                <div class="col-12 mt-1" *ngIf="verifyTitle(file.nombre)">
                                                    <h2 class="linea"><span> {{viewTitle(file.nombre)}}</span></h2>
                                                </div>

                                             

                                                <div class="col-10">
                                                    <span><i class="fa fa-empire"
                                                            aria-hidden="true"></i>{{viewSubTitle(deleteLetter(file.nombre))}}</span>
                                                </div>
                                                <div class="col d-flex justify-content-end align-items-center">
                                                    <a href={{file.url_descarga}} target="_blank" role="button"
                                                        class="linkIcon" title="Descargar documento">
                                                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                                <div class="col-12 "
                                                    *ngIf="viewSubTitle(deleteLetter(file.nombre)) === ' Resultados Pruebas Técnicas'">
                                                    <hr>
                                                </div>
                                                <div class="col-12 "
                                                    *ngIf="viewSubTitle(deleteLetter(file.nombre)) === ' Entrevista'">
                                                    <hr>
                                                </div>

                                            </div>
                                        </div>

                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</div>