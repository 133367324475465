import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Noticia } from '../../model/noticia/noticia.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment.prod';//"src/environments/environment.prod";

import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NoticiaService {

  constructor(private httpClient: HttpClient) { }

  public getNoticia(id:string): Observable<Noticia> {
    // var api_uri = environment.API_URL.toString() + `get_noticia/${id}`;
    var api_uri = `https://www.aguasmachala.com:3000/api/v1/noticias/${id}`;
    // var api_uri = `/api/v1/noticias/${id}`;
    return this.httpClient.get<Noticia[]>(api_uri).pipe(
      map(data => new Noticia().deserialize(data))
    );
  }
}
