import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Announcement } from 'src/app/controller/announcemet.interface';
import { AnnouncemetHttpService } from 'src/app/service/announcemet/announcemet.http.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnnouncemetResolver implements Resolve<Announcement> {

  constructor(private readonly announcementHttp: AnnouncemetHttpService){}

  resolve(route: ActivatedRouteSnapshot): Observable<Announcement> {
    const id: number = route.params['id'];
    return this.announcementHttp.getId(id)
    .pipe(map(next => next.announcement));
  }
}
