import { DatafastService } from './../../service/service/datafast.service';
import { Component, Input, OnInit } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-payment-button',
  templateUrl: './payment-button.component.html',
  styleUrls: ['./payment-button.component.scss'],
})
export class PaymentButtonComponent implements OnInit {
  value: string = '00.00';
  url="http://localhost:4201/pagoenlinea";
  id="";

  constructor(
    private datafast: DatafastService,
    private render: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    const idTemp = localStorage.getItem('id');
    if(idTemp) {
      localStorage.clear();
      this.datafast.getResourcePath(idTemp).subscribe((resp) => {
        // console.log(JSON.stringify(resp));
      })
    }
  }

  payment(): void {
    this.setValue = '5';
    if (this.value && this.value !== '00,00') {
      this.datafast.getCheckoutId(null).subscribe((data) => {
        // console.log(JSON.stringify(data));
        //* Agrega de forma dinamica el script de widget
        const script = this.render.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://oppwa.com/v1/paymentWidgets.js?checkoutId=${data.id}`;
        this.render.appendChild(this.document.body, script);
        //* Almacena el id en el localStorage para que no se borre al momento de recargar o redireccionar despues del pago
        localStorage.setItem('id', data.id);
      });
    }
  }

  set setValue(value: string) {
    this.value = value;
  }
}
