<br />
<br />

<div class="row">
  <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
    <div
      class="featured-imagebox featured-imagebox-post"

    >
      <div class="featured-thumbnail center-image">
        <img
          loading="lazy"
          class="img-fluid"
          src="/assets/images/optimizadas_machalaep/gestion-click/consulta-deudas.webp"
          alt="Consulta de deudas por servicios"
        />
      </div>
      <div class="featured-content featured-content-post">
        <div class="post-title featured-title">
          <h5><a>Consulta de deudas y pago en línea de servicios</a></h5>
        </div>
        <div class="post-meta">
          <span class="ttm-meta-line">
            <p>
              Accede a nuestros renovados servicios de consulta de saldos
              pendientes en línea y realiza tus pagos en línea.
            </p>
            <a
              class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
              href="http://177.234.239.179:82/cw_client_login" target="_blank" rel="noopener noreferrer"
              >MÁS DETALLES<i class="ti ti-angle-double-right"></i
            ></a>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
    <div
      class="featured-imagebox featured-imagebox-post"

    >
      <div class="featured-thumbnail center-image">
        <img
          loading="lazy"
          class="img-fluid"
          src="/assets/images/optimizadas_machalaep/gestion-click/consulta-reclamos.webp"
          alt="Consulta de estado de reclamos"
        />
      </div>
      <div class="featured-content featured-content-post">
        <div class="post-title featured-title">
          <h5><a>Consulta de estado de reclamos</a></h5>
        </div>
        <div class="post-meta">
          <span class="ttm-meta-line">
            <p>
              Revisa los avances de los reclamos presencial, telefónico, escrito
              u online que hayas realizado.
            </p>
            <a
              class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
              href="http://177.234.239.179:82/cw_client_login" target="_blank" rel="noopener noreferrer"
              >MÁS DETALLES<i class="ti ti-angle-double-right"></i
            ></a>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
    <div class="featured-imagebox featured-imagebox-post" >
      <div class="featured-thumbnail center-image">
        <img
          loading="lazy"
          class="img-fluid"
          src="/assets/images/optimizadas_machalaep/gestion-click/denuncia-domicilio.webp"
          alt="Reclamos por servicios en domicilio"
        />
      </div>
      <div class="featured-content featured-content-post">
        <div class="post-title featured-title">
          <h5><a>Reclamos por servicio en domicilio</a></h5>
        </div>
        <div class="post-meta">
          <span class="ttm-meta-line">
            <p>
              Realiza reclamos por problemas de cobros o inconvenientes con
              servicio de agua potable u alcantarillado en tu domicilio.
            </p>
            <div class="ttm-btn-box pr-20 pb-20">
              <a
                class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                href="http://177.234.239.179:82/cw_client_login" target="_blank" rel="noopener noreferrer"
                >MÁS DETALLES<i class="ti ti-angle-double-right"></i
              ></a>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
    <div
      class="featured-imagebox featured-imagebox-post"

    >
      <div class="featured-thumbnail center-image">
        <img
          loading="lazy"
          class="img-fluid"
          src="/assets/images/optimizadas_machalaep/gestion-click/denuncia-ciudad.webp"
          alt="Denuncia por servicios en ciudad"
        />
      </div>
      <div class="featured-content featured-content-post">
        <div class="post-title featured-title">
          <h5><a>Denuncias por servicios en Ciudad</a></h5>
        </div>
        <div class="post-meta">
          <span class="ttm-meta-line">
            <p>
              Notifica cualquier problema en lugares públicos (parques, plazas,
              calles) relacionado con el servicio de agua potable o
              alcantarillado.
            </p>
            <div class="ttm-btn-box pr-20 pb-20">
              <a
                class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                href="http://177.234.239.179:82/cw_client_login" target="_blank" rel="noopener noreferrer"
                >MÁS DETALLES<i class="ti ti-angle-double-right"></i
              ></a>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
    <div
      class="featured-imagebox featured-imagebox-post"
      href="http://www.aguasmachala.com/clientes/"
    >
      <div class="featured-thumbnail center-image">
        <img
          loading="lazy"
          class="img-fluid"
          src="/assets/images/optimizadas_machalaep/gestion-click/facturacion-electronica.webp"
          alt="Facturacion electronica"
        />
      </div>
      <div class="featured-content featured-content-post">
        <div class="post-title featured-title">
          <h5><a>Facturación Electrónica</a></h5>
        </div>
        <div class="post-meta">
          <span class="ttm-meta-line">
            <p>
              Revisa y descarga tu factura electrónica mediante nuestros
              servicios online.
            </p>
            <a
              class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
              href="http://177.234.239.179:82/cw_client_login" target="_blank" rel="noopener noreferrer"
              >MÁS DETALLES<i class="ti ti-angle-double-right"></i
            ></a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<!--

<owl-carousel-o
  [options]="customOptionsServices"
  class="post-slide mt-15"
  *ngIf="customOptionsServices"
>
  <ng-template carouselSlide>
    <div
      class="featured-imagebox featured-imagebox-post"
      routerLink="/consultadeudas"
    >
      <div class="featured-thumbnail center-image">
        <img
          loading="lazy"
          class="img-fluid"
          src="/assets/images/optimizadas_machalaep/gestion-click/consulta-deudas.webp"
          alt="Consulta de deudas por servicios"
        />
      </div>
      <div class="featured-content featured-content-post">
        <div class="post-title featured-title">
          <h5><a>Consulta de deudas por servicios</a></h5>
        </div>
        <div class="post-meta">
          <span class="ttm-meta-line">
            <p>
              Accede a nuestros renovados servicios de consulta de saldos
              pendientes en línea.
            </p>
            <a
              class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
              routerLink="/consultadeudas"
              >MÁS DETALLES<i class="ti ti-angle-double-right"></i
            ></a>
          </span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template carouselSlide>
    <div
      class="featured-imagebox featured-imagebox-post"
      routerLink="/consultareclamos"
    >
      <div class="featured-thumbnail center-image">
        <img
          loading="lazy"
          class="img-fluid"
          src="/assets/images/optimizadas_machalaep/gestion-click/consulta-reclamos.webp"
          alt="Consulta de estado de reclamos"
        />
      </div>
      <div class="featured-content featured-content-post">
        <div class="post-title featured-title">
          <h5><a>Consulta de estado de reclamos</a></h5>
        </div>
        <div class="post-meta">
          <span class="ttm-meta-line">
            <p>
              Revisa los avances de los reclamos presencial, telefónico, escrito
              u online que hayas realizado.
            </p>
            <a
              class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
              routerLink="/consultareclamos"
              >MÁS DETALLES<i class="ti ti-angle-double-right"></i
            ></a>
          </span>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="featured-imagebox featured-imagebox-post" routerLink="/reclamo">
      <div class="featured-thumbnail center-image">
        <img
          loading="lazy"
          class="img-fluid"
          src="/assets/images/optimizadas_machalaep/gestion-click/denuncia-domicilio.webp"
          alt="Reclamos por servicios en domicilio"
        />
      </div>
      <div class="featured-content featured-content-post">
        <div class="post-title featured-title">
          <h5><a>Reclamos por servicio en domicilio</a></h5>
        </div>
        <div class="post-meta">
          <span class="ttm-meta-line">
            <p>
              Realiza reclamos por problemas de cobros o inconvenientes con
              servicio de agua potable u alcantarillado en tu domicilio.
            </p>
            <div class="ttm-btn-box pr-20 pb-20">
              <a
                class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                routerLink="/reclamo"
                >MÁS DETALLES<i class="ti ti-angle-double-right"></i
              ></a>
            </div>
          </span>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div
      class="featured-imagebox featured-imagebox-post"
      routerLink="/denunciaciudad"
    >
      <div class="featured-thumbnail center-image">
        <img
          loading="lazy"
          class="img-fluid"
          src="/assets/images/optimizadas_machalaep/gestion-click/denuncia-ciudad.webp"
          alt="Denuncia por servicios en ciudad"
        />
      </div>
      <div class="featured-content featured-content-post">
        <div class="post-title featured-title">
          <h5><a>Denuncias por servicios en Ciudad</a></h5>
        </div>
        <div class="post-meta">
          <span class="ttm-meta-line">
            <p>
              Notifica cualquier problema en lugares públicos (parques, plazas,
              calles) relacionado con el servicio de agua potable o
              alcantarillado.
            </p>
            <div class="ttm-btn-box pr-20 pb-20">
              <a
                class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
                routerLink="/denunciaciudad"
                >MÁS DETALLES<i class="ti ti-angle-double-right"></i
              ></a>
            </div>
          </span>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div
      class="featured-imagebox featured-imagebox-post"
      href="http://www.aguasmachala.com/clientes/"
    >
      <div class="featured-thumbnail center-image">
        <img
          loading="lazy"
          class="img-fluid"
          src="/assets/images/optimizadas_machalaep/gestion-click/facturacion-electronica.webp"
          alt="Facturacion electronica"
        />
      </div>
      <div class="featured-content featured-content-post">
        <div class="post-title featured-title">
          <h5><a>Facturación Electrónica</a></h5>
        </div>
        <div class="post-meta">
          <span class="ttm-meta-line">
            <p>
              Revisa y descarga tu factura electrónica mediante nuestros
              servicios online.
            </p>
            <a
              class="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-bgcolor-skincolor"
              target="_blank"
              href="http://www.aguasmachala.com/clientes/"
              >MÁS DETALLES<i class="ti ti-angle-double-right"></i
            ></a>
          </span>
        </div>
      </div>
    </div>
  </ng-template>
</owl-carousel-o>
-->
