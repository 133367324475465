<div class="ttm-page-title-row">
  <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title-box ttm-textcolor-white">
          <div class="page-title-heading">
            <h1 class="title">Políticas de Privacidad</h1>
          </div>
          <div class="breadcrumb-wrapper">
            <span>
              <a title="Homepage" routerLink="home"
                ><i class="ti ti-home"></i
              ></a>
            </span>
            <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
            <span
              ><span style="color: #0095d9; font-weight: bold"
                >Vivienda</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="site-main">
  <section class="ttm-row">
    <div class="container p-4" style="margin-bottom: 2rem">
      <h4 class="text-center" style="margin-bottom: 1rem">
        Políticas de Privacidad para la utilización del canal botón de pagos de
        Aguas Machala
      </h4>
      <p>
        El siguiente es un documento legal que explica las políticas de
        privacidad que se toman en cuenta cuando se hace uso del “BOTÓN DE
        PAGOS”.
      </p>
      <h6 class="font-weight-bold">1. CONFIDENCIALIDAD</h6>
      <p>
        La utilización del canal de recaudación Botón de Pagos pone a
        disposición del “CLIENTE” es de exclusiva responsabilidad del usuario la
        cual se considera como información privada.
        <br />
        <br />
        El “BOTÓN DE PAGOS” no se hace responsable por el mal manejo de la
        información proporcionada al “CLIENTE”.
        <br />
        <br />
      </p>
      <h6 class="font-weight-bold">2. POLITICA DE PRIVACIDAD</h6>
      <p>
        Por la prestación de este servicio, el “BOTÓN DE PAGOS” podrá recopilar
        información de registro, información que pasará a terceros cuando ésta
        sea requerida por la ley o por acciones legales para las cuales ésta
        información es relevante, como cuando se trate de una orden judicial o a
        propósito para prevenir un delito o fraude. En cuyo caso se entenderá
        que el “CLIENTE” ha dado su permiso para revelar la información
        constante por la ejecución del servicio.
        <br />
        <br />
      </p>

      <h6 class="font-weight-bold">3. PROPIEDAD</h6>
      <p>
        El “CLIENTE” acepta que el “BOTÓN DE PAGOS” es el dueño y propietario de
        los derechos personales y reales sobre la Base de Datos que se
        proporcionará en este servicio de recaudación.
      </p>
    </div>
  </section>
</div>
