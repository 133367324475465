import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NewCorreoService } from 'src/app/service/new-correo/new-correo.service';
import { NewCorreo } from 'src/app/model/new-correo/new-correo.model';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  public year: number = new Date().getFullYear();

  constructor(
    private newCorreoService: NewCorreoService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private scrollToService: ScrollToService
  ) {}

  ngOnInit(): void {}
  public suscribir() {
    var ipt_email = (<HTMLInputElement>(
      document.getElementById('ipt_email_footer')
    )).value;
    let EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (ipt_email == '') {
      this.alertsErrores('Ingresa un correo válido');
    }
    if (
      ipt_email != '' &&
      (ipt_email.length <= 5 || !EMAIL_REGEXP.test(ipt_email))
    ) {
      (<HTMLInputElement>document.getElementById('ipt_email_footer')).value =
        '';
      this.alertsErrores('Ingresa un correo válido');
    } else {
      this.spinner.show();
      this.postCorreo(ipt_email);
      (<HTMLInputElement>document.getElementById('ipt_email_footer')).value =
        '';
      setTimeout(() => {
        this.spinner.hide();
      }, 1500);
    }
  }

  public postCorreo(ipt_email: string) {
    var newCorreo = new NewCorreo(ipt_email);
    this.spinner.show();
    this.newCorreoService.postNewCorreo(newCorreo).subscribe((data) => {
      this.spinner.hide();
      if (data['message'] == 'Email guardado') {
        this.alertsSuccess('Te has suscrito a nuestro boletín');
      } else {
        this.alertsErrores(
          'La suscripción no se pudo procesar, intenta seleccionando nuevamente el boton enviar, o recarga la pagina'
        );
      }
    });
  }
  public alertsErrores(mensaje: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: mensaje,
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
    });
  }
  public alertsSuccess(mensaje: string) {
    Swal.fire({
      icon: 'success',
      text: mensaje,
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
    });
  }
  public abrirImagen(url: string) {
    Swal.fire({
      confirmButtonText: `Cerrar`,
      confirmButtonColor: '#182333',
      imageUrl: url,
      imageAlt: 'Obras',
    });
  }

  verObras(): void {
    if(this.router.url.includes('/nosotros')) {
      this.triggerScrollTo();
    } else {
      this.router.navigate(["/nosotros"], {fragment: 'obras-machala'})
    }

  }

  triggerScrollTo() {

    const config: ScrollToConfigOptions = {
      target: 'obras-machala',
      offset: -115
    };

    this.scrollToService.scrollTo(config);
  }
}
