export class ConsultaReclamo {
  IDReclamo: string;
  tipo: string;
  descripcion: string;
  fecha: string;
  estado: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
