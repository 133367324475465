import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-single-service',
  templateUrl: './single-service.component.html',
  styleUrls: ['./single-service.component.scss'],
})
export class SingleServiceComponent implements OnInit {
  public jsonServicio = [
    [
      'Copia de cédula del propietario.',
      'Copia de cédula del beneficiario.',
      'Copia de la escritura pública del predio debidamente inscrita en el Registro de la propiedad. En caso de que la escritura no sea de fecha actual se pedirá Certificado de Avalúos y Catastro municipal o pago del predio actual.',
      'Autorización por parte del propietario (en caso de solicitudes realizadas por terceros).',
    ],
    ['Copia de cédula.', 'Oficio solicitando documentación '],
    [
      'Oficio solicitando el corte definitivo por parte del propietario.',
      'Copia de la cédula.',
      'Número de Celular.',
      'El usuario debe estar al día en sus pagos.',
      'Predio deshabitado.',
    ],
    [
      'Oficio solicitando corte provisional por parte del propietario.',
      'Copia de cédula.',
      'Número de Celular.',
      'El usuario debe estar al día en sus pagos o haber realizado un convenio de pagos.',
      'Predio deshabitado.',
    ],
    [
      'Copia de cédula.',
      'Copia de la Escritura Pública del Predio debidamente inscrita en el Registro de la Propiedad. En caso de que la Escritura no sea de fecha actual se pedirá un Certificado de Avalúos y Catastro Municipal actualizado o pago del Predio actual.',
      'Número de teléfono.',
      'Croquis de ubicación.',
      'Correo electrónico.',
    ],
    [
      'Carta del Promotor o Urbanizador solicitando creación de cuenta para la urbanización.',
      'Copia de cédula de identidad o RUC',
      'Copia del plano de loteo e implantación del proyecto georreferenciados',
      'Copia de la escritura ',
      'Carta del Promotor o Urbanizador solicitando factibilidad del servicio de infraestructura hidro-sanitaria para la urbanización.',
      'Carta del director de Urbanismo del Gobierno Autónomo Descentralizado de Machala, donde certifica que la urbanización existe y está aprobada o en proceso de aprobación. ',
      'Copia del plano de loteo e implantación del proyecto (para ubicación al momento de la inspección de verificación.',
    ],
    ['Copia de cédula.', 'Oficio solicitando limpieza ', 'Número de teléfono'],
    [
      'Copia de cédula.',
      'Oficio solicitando la instalación del servicio',
      'Copia de la Escritura Pública del Predio debidamente inscrita en el Registro de la Propiedad. En caso de que la escritura no sea de fecha actual, se pedirá Certificado de Avalúos y Catastro Municipal actualizado. ',
      'Número de teléfono. ',
      'Croquis de ubicación (opcional).',
      'Foto del predio.',
      'Pago predial, actualizado.',
      'Correo electrónico. ',
    ],
    [
      'Copia de cédula',
      'Número de teléfono',
      'Dirección de Correo Electrónico',
    ],
    [
      'Copia de cédula',
      'Número de teléfono',
      'Dirección de Correo Electrónico',
    ],
    ['Número de teléfono', 'Dirección de correo electrónico'],
    [
      'Copia de cédula',
      'Oficio solicitando la reconexión del servicio ',
      'Número de teléfono',
      'Dirección de correo electrónico',
    ],
    [
      'Copia de cédula de ciudadanía y certificado de votación.',
      'Cuando se trate de personas jurídicas copia del nombramiento que justifique la intervención del representante legal.',
      'Certificado de no adeudar al GAD Municipal de Máchala;',
      'Certificado actualizado del Registro Municipal de la Propiedad del bien para el cual se está solicitando el servicio.',
      'En caso de que se requiera el servicio en un solar en el que se vaya a edificar, se deberá presentar la copia del permiso de construcción otorgado por la Dirección',
      'encargada del urbanismo del GAD Municipal de Máchala.',
      'Una vez receptada la solicitud, Aguas Machala - EP , determinará los materiales que deberán utilizarse en la conexión e informará al interesado que deberá cancelar mínimo el 50% de la liquidación provisional por costo de instalación del servicio de alcantarillado, determinado por los técnicos de la Empresa Pública',
    ],
    ['Número de teléfono.', 'Oficio solicitando el requerimiento '],
    [
      'Copia de cédula.',
      'Copia de escrituras o la solicitud de instalación del urbanizador (esto sólo en el caso de urbanizaciones).',
    ],
    [
      'Formulario de Registro.',
      'Copia de cédula.',
      'Copia del carnet de discapacidad (CONADIS). ',
    ],
  ];

  public listNames = [
    'ACTUALIZACIÓN DE DATOS (CAMBIO DE PROPIETARIO, CAMBIO DE BENEFICIARIO)',
    'CERTIFICACIONES',
    'SUSPENSIÓN DE SERVICIO DEFINITIVO',
    'SUSPENSIÓN SERVICIO PROVISIONAL',
    'CREACIÓN DE CUENTA',
    'ESPECIFICACIONES TECNICAS APP Y ALL, FACTIBILIDAD DE SERVICIO Y FISCALIZACIÓN DE PROYECTOS',
    'EXTRACCIÓN DE POZO SEPTICO COMERCIAL, INDUSTRIAL, RESIDENCIAL, RURAL',
    'INSTALACIÓN DE GUIA EN TERRENO NATURAL, EN PAVIMETO Y EN ACERA',
    'LIMPIEZA DE TRAMPA DE GRASA EN CHIFAS Y ASADEROS.',
    'LIMPIEZA DE TRAMPAS DE GRASAS Y SEDIMENTOS',
    'MANTENIMIENTO DE RED TERCIARIA DE ALCANTARILLADO',
    'RECONEXIÓN DE SERVICIO POR SUSPENSIÓN PROVISIONAL',
    'SOLICITUD DE SERVICIO DE ALCANTARILLADO',
    'SOLICITUD DE LIMPIEZA DE ALCANTARILLADO',
    'INSTALACIÓN DE MEDIDOR',
    'EXONERACIÓN POR TERCERA EDAD O DISCAPACIDAD',
  ];

  public id = '';
  constructor(private spinner: NgxSpinnerService, private router: Router) {
    var current_url = router.url.split('/');
    this.id = current_url[current_url.length - 1];
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
        var current_url = event.url.split('/');
        this.id = current_url[current_url.length - 1];
      }
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
  }
}
