import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare let $: any;

@Component({
  selector: 'app-slideepaliada',
  templateUrl: './slideepaliada.component.html',
  styleUrls: ['./slideepaliada.component.scss']
})
export class SlideepaliadaComponent implements OnInit {
  customOptionsEmpresas: OwlOptions

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
  ngAfterViewInit(): void {
    let tmpThis = this;
    setTimeout(() => {
      this.customOptionsEmpresas = {
        autoplay: true,
        autoplayTimeout: 3000,
        dots: false,
        stagePadding: 50,
        center: true,
        margin: 25,
        loop: true,
        navText: ['', ''],
        nav: false,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 2
          },
          1000: {
            items: 6
          }
        }
      }
    }, 100)
    $(document).ready(function () {
      $('.toggle').eq(0).addClass('active').find('.toggle-content').css('display', 'block');
      $('.accordion .toggle-title').click(function () {
        $(this).siblings('.toggle-content').slideToggle('fast');
        $(this).parent().toggleClass('active');
        $(this).parent().siblings().children('.toggle-content:visible').slideUp('fast');
        $(this).parent().siblings().children('.toggle-content:visible').parent().removeClass('active');
      });
    });
  }

}


