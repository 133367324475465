import { Component, OnInit } from '@angular/core';
import { NewReclamoDomicilioService } from 'src/app/service/new-reclamo-domicilio/new-reclamo-domicilio.service';
import { NewReclamoDomicilio } from 'src/app/model/new-reclamo-domicilio/new-reclamo-domicilio.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { OwlOptions } from 'ngx-owl-carousel-o';
import Swal from 'sweetalert2';
import { ReclamoDomicilio } from '../../controller/reclamo-domicilio.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MachalaEpService } from '../../service/service/machala-ep.service';

@Component({
  selector: 'app-reclamo',
  templateUrl: './reclamo.component.html',
  styleUrls: ['./reclamo.component.scss'],
})
export class ReclamoComponent implements OnInit {
  form: FormGroup;
  public reclamoDomicilio: NewReclamoDomicilio;
  beneficiario = 'B';
  public tipoId = [
    { nombre: 'CED', value: 1 },
    { nombre: 'RUC', value: 2 },
    { nombre: 'NRO', value: 3 },
  ];

  tipoReclamo = [
    { id: '1', tipo: 'Servicio de agua potable' },
    { id: '4', tipo: 'Problemas en Medidor' },
    { id: '2', tipo: 'Servicios de alcantarillado' },
    { id: '3', tipo: 'Servicios de facturación' },
  ];
  selectedOptionTI: string;
  constructor(
    private newReclamoDomicilioService: NewReclamoDomicilioService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private machalaEp: MachalaEpService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      typeId: ['NRO', Validators.required],
      id: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      phonePri: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      phoneAdd: ['', [Validators.pattern('^[0-9]+$')]],
      details: ['', [Validators.required]],
      typeClaim: ['1', Validators.required],
      petitioner: ['B', Validators.required],
    });

    window.scrollTo(0, 0);

    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
  }
  customOptionsServices: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 2500,
    loop: true,
    dots: false,
    margin: 25,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };

  public guiaNroCuenta() {
    Swal.fire({
      title: '¿Dónde veo mi número de cuenta?',
      text:
        'Revisa tu factura/guía de pago, encuentras tu Nro. Cuenta en la parte superior derecha.',
      imageUrl: '/assets/images/optimizadas_machalaep/nrocuenta.webp',
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
      imageAlt: 'Ver número de cuenta',
    });
  }
  public alertsErrores(mensaje: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: mensaje,
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
    });
  }
  public alertsSuccess(mensaje: string) {
    Swal.fire({
      icon: 'success',
      text: mensaje,
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
    }).then((result) => {
      if (result.isConfirmed) {
        location.reload();
      }
    });
  }
  public validarTelefonos(ipt_TelFijo: string, ipt_TelMovil: string) {
    if (ipt_TelFijo.length == 0 && ipt_TelMovil.length == 0) {
      return false;
    } else {
      return true;
    }
  }
  public getRadioButtonSelect(name: string) {
    var off_payment_method = document.getElementsByName(name);
    var ischecked_method = '';
    for (var i = 0; i < off_payment_method.length; i++) {
      const temp = off_payment_method[i] as HTMLInputElement;
      if (temp.checked) {
        ischecked_method = temp.value;
        break;
      }
    }
    return ischecked_method;
  }

  public validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }

  public validarCedula(cedula: string) {
    if (cedula.length === 10) {
      const digitoRegion = cedula.substring(0, 2);
      if (digitoRegion >= String(0) && digitoRegion <= String(24)) {
        const ultimoDigito = Number(cedula.substring(9, 10));
        const pares =
          Number(cedula.substring(1, 2)) +
          Number(cedula.substring(3, 4)) +
          Number(cedula.substring(5, 6)) +
          Number(cedula.substring(7, 8));
        let numeroUno: any = cedula.substring(0, 1);
        numeroUno = numeroUno * 2;
        if (numeroUno > 9) {
          numeroUno = numeroUno - 9;
        }
        let numeroTres: any = cedula.substring(2, 3);
        numeroTres = numeroTres * 2;
        if (numeroTres > 9) {
          numeroTres = numeroTres - 9;
        }
        let numeroCinco: any = cedula.substring(4, 5);
        numeroCinco = numeroCinco * 2;
        if (numeroCinco > 9) {
          numeroCinco = numeroCinco - 9;
        }
        let numeroSiete: any = cedula.substring(6, 7);
        numeroSiete = numeroSiete * 2;
        if (numeroSiete > 9) {
          numeroSiete = numeroSiete - 9;
        }
        let numeroNueve: any = cedula.substring(8, 9);
        numeroNueve = numeroNueve * 2;
        if (numeroNueve > 9) {
          numeroNueve = numeroNueve - 9;
        }
        const impares =
          numeroUno + numeroTres + numeroCinco + numeroSiete + numeroNueve;
        const sumaTotal = pares + impares;
        const primerDigitoSuma = String(sumaTotal).substring(0, 1);
        const decena = (Number(primerDigitoSuma) + 1) * 10;
        let digitoValidador = decena - sumaTotal;
        if (digitoValidador === 10) {
          digitoValidador = 0;
        }
        if (digitoValidador === ultimoDigito) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  public validarNroCuenta(nrocuenta: string) {
    if (nrocuenta.length == 6) {
      return true;
    } else {
      return false;
    }
  }
  public validarRUC(ruc: string) {
    if (ruc.length == 13) {
      return true;
    } else {
      return false;
    }
  }

  public inputNumberValidator(event: any) {
    //console.log(event.target.value);
    const pattern = /^[0-9]*$/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
      // invalid character, prevent input
    }
  }

  guardarReclamo() {
    if (this.form.valid) {
      const reclamo: ReclamoDomicilio = {
        nombre: this.form.get('name').value,
        tipoid: this.form.get('typeId').value,
        id: this.form.get('id').value,
        email: this.form.get('email').value,
        telefono1: this.form.get('phonePri').value,
        telefono2: this.form.get('phoneAdd').value,
        descripcion: this.form.get('details').value,
        categoria: this.form.get('typeClaim').value,
        tiporeclamo: '1',
        tiposolicitante: this.form.get('petitioner').value,
      };

      let idType = this.tipoId.filter(
        (value) => value.nombre === reclamo.tipoid
      )[0];

      this.machalaEp
        .verificarSiExiteUsuario(reclamo.tipoid, reclamo.id)
        .subscribe(
          (resp) => {
            this.machalaEp.guardarReclamoDomicilio(reclamo).subscribe(
              (resp) => {
                this.alertsSuccess('Reclamo creado exitosamente');
              },
              (err) => {
                this.alertsErrores(err.error.message);
              }
            );
          },
          (err) => {
            this.alertsErrores(
              'Error debe tener una cuenta de cliente de Aguas Machala'
            );
          }
        );
    } else {
      this.validForm();
    }
  }

  get invalidName(): boolean {
    return this.form.get('name').invalid && this.form.get('name').touched;
  }

  get invalidEmail(): boolean {
    return this.form.get('email').invalid && this.form.get('email').touched;
  }
  get invalidId(): boolean {
    return this.form.get('id').invalid && this.form.get('id').touched;
  }
  get invalidPriPhone(): boolean {
    return (
      this.form.get('phonePri').invalid && this.form.get('phonePri').touched
    );
  }

  get invaliPhoneAdd(): boolean {
    return (
      this.form.get('phoneAdd').invalid && this.form.get('phoneAdd').touched
    );
  }

  get invalidDetails(): boolean {
    return this.form.get('details').invalid && this.form.get('details').touched;
  }

  get invalidTypeClaim(): boolean {
    return (
      this.form.get('typeClaim').invalid && this.form.get('typeClaim').touched
    );
  }
  get invalidPetitioner(): boolean {
    return (
      this.form.get('petitioner').invalid && this.form.get('petitioner').touched
    );
  }

  validForm(): void {

    if (this.form.invalid) {
      return Object.values(this.form.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }
  }
}
