export class NewMensaje {
    correo: string;
    nombre: string;
    mensaje: string;

    constructor(correo:string, nombre:string, mensaje:string){
        this.correo=correo;
        this.nombre=nombre;
        this.mensaje=mensaje;
        
    }
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
