<ngx-spinner></ngx-spinner>
<div class="ttm-page-title-row">
  <div class="ttm-page-title-row-bg-layer ttm-bg-layer"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="title-box ttm-textcolor-white">
          <div class="page-title-heading">
            <h1 class="title">Noticias</h1>
          </div>
          <div class="breadcrumb-wrapper">
            <span>
              <a title="Homepage" routerLink="home"
                ><i class="ti ti-home"></i
              ></a>
            </span>
            <span class="ttm-bread-sep">&nbsp; / &nbsp;</span>
            <span
              ><span style="color: #0095d9; font-weight: bold"
                >Noticias</span
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="site-main">
  <div class="ttm-row pb-110 ttm-bgcolor-grey clearfix">
    <div class="container" *ngIf="noticia">
      <div class="row">
        <div class="col-12">
          <article class="post ttm-blog-classic">
            <header class="entry-header">
              <span class="ttm-meta-line cat-links">
                <a>Aguas Machala EP</a>,
                <a>Alcaldía de Machala</a>
              </span>
              <h2 class="entry-title">
                <a>{{ noticia.titulo }}</a>
              </h2>
            </header>
            <div class="post-featured-wrapper">
              <div class="post-featured">
                <!-- <div *ngFor="let url of urls_imagenes">
                                    <img loading="lazy" [src]="url.url" class="img-fluid">
                                </div> -->
                <owl-carousel-o [options]="customImgsNew">
                  <ng-container *ngFor="let url of noticia.imagenes">
                    <ng-template carouselSlide>
                      <img loading="lazy" [src]="url" class="img-fluid" />
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </div>
              <div class="ttm-box-post-date">
                <span class="ttm-entry-date">
                  <time class="entry-date" datetime="2019-01-16T07:07:55+00:00"
                    >{{ noticia.fecha | date: "dd"
                    }}<span class="entry-month entry-year">{{
                      noticia.fecha | date: "MMM"
                    }}</span></time
                  >
                </span>
              </div>
            </div>
            <div class="ttm-blog-classic-box-content">
              <div class="entry-content">
                <div class="ttm-entry-meta-wrapper">
                  <div class="ttm-entry-meta">
                    <span class="ttm-meta-line byline">
                      <img
                        loading="lazy"
                        src="/assets/images/optimizadas_machalaep/favicon.webp"
                        alt=""
                        class="avatar"
                      />
                      <span class="author"
                        ><a style="font-weight: 800; text-decoration: underline"
                          >Dept. Comunicación</a
                        ></span
                      >
                    </span>
                  </div>
                </div>
                <div class="ttm-box-desc-text">
                  <br />
                  <p>
                    <strong>{{ noticia.subtitulo }}</strong>
                  </p>
                  <p [innerHTML]="noticia.resumen"></p>
                </div>
                <div class="ttm-blogbox-desc-footer">
                  <div class="ttm-blogbox-footer-readmore">
                    <a routerLink="/noticia/{{ noticia.id }}">LEER MÁS</a>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div
            class="section-title with-desc title-style-center_text mb-55 clearfix"
          >
            <div class="title-header">
              <h2 class="title">Encuentra más noticias a continuación</h2>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
    <div class="container">
      <app-slidenews></app-slidenews>
    </div>
  </div>
</div>
