import { Component, OnInit } from '@angular/core';
import { Logro } from 'src/app/model/logro/logro.model';
import { LogroService } from '../../service/logro/logro.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import {
  ScrollToConfigOptions,
  ScrollToService,
} from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  public logros: Logro[];
  public anioActual: string;
  public diferenciaAnios: string;
  customOptionsLogros: OwlOptions;
  customOptionsMain: OwlOptions;

  constructor(
    private logroService: LogroService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private scrollToService: ScrollToService
  ) {
    this.anioActual = new Date().getFullYear().toString();
    this.diferenciaAnios = (new Date().getFullYear() - 2015 - 1).toString();

    this.getLogros();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      if (this.router.url.toString().includes('#obras-machala')) {
        this.triggerScrollTo();
      }
    }, 1500);

    setTimeout(() => {
      this.customOptionsLogros = {
        autoplay: true,
        autoplayTimeout: 4000,
        dots: false,
        margin: 15,
        loop: true,
        nav: false,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      };
      this.customOptionsMain = {
        autoplay: true,
        autoplayTimeout: 3000,
        dots: false,
        loop: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 1,
          },
        },
      };
    }, 300);
  }

  public getLogros() {
    this.logroService.getAllLogros().subscribe((logros) => {
      logros.forEach((logro) => {
        if (logro.unidad == 'u') {
          logro.unidad = '';
        }
      });
      this.logros = logros;
    });
  }
  public abrirVideo(url: string) {
    var htmlV =
      '<iframe width="100%" height="300" src="' +
      url +
      '" frameborder="0"></iframe>';
    Swal.fire({
      title: 'Aguas Machala EP',
      confirmButtonText: `Entendido`,
      confirmButtonColor: '#182333',
      html: htmlV,
      footer:
        '<p style="text-align: center;">¿Quieres ver más videos? <br><a style="font-weight:bold; text-decoration:underline;" target="_blank" href="https://www.youtube.com/channel/UCZjLjxrrb6OGH-BUP9PESdw">Revisa nuestro canal de Youtube</a></p>',
    });
  }

  public abrirImagen(url: string) {
    Swal.fire({
      title: 'Aguas Machala EP',
      confirmButtonText: `Cerrar`,
      confirmButtonColor: '#182333',
      html: `<div><img class="img-fluid" src="${url}"></div>`,
    });
  }
  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: 'obras-machala',
      offset: -115,
    };

    this.scrollToService.scrollTo(config);
  }
}
