import { Component, OnInit, AfterViewInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import * as L from 'leaflet';
import { Router } from '@angular/router';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, AfterViewInit {
  public map;
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private scrollToService: ScrollToService
    ) { }
  customOptionsServices: OwlOptions = {
    dots: false,
    margin: 25,
    loop: true,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if(this.router.url.toString().includes('#canales-pagos')) {
        this.triggerScrollTo();
      }
  }, 500);

  }
  ngOnInit(): void {
    this.map = L.map("mapService").setView([-3.25861, -79.96053], 13);
    var bMachalaIcon = L.icon({iconUrl:"/assets/images/optimizadas_machalaep/puntos_pago/banco_machala.webp", iconSize:[45,60],iconAnchor:[45,60]})
    var marker_BM1 = L.marker([-3.2597021000000033, -79.9593705153444], {icon:bMachalaIcon}).addTo(this.map);
    var marker_BM2 = L.marker([-3.260026586152884, -79.95539083199108], {icon:bMachalaIcon}).addTo(this.map);
    var marker_BM3 = L.marker([-3.253459997631332, -79.97068198966325], {icon:bMachalaIcon}).addTo(this.map);
    var marker_BM4 = L.marker([-3.264942760176385, -79.96299660285281], {icon:bMachalaIcon}).addTo(this.map);
    var bMachala="<b>Banco Machala</b><br>Punto de Pago";
    marker_BM1.bindPopup(bMachala).openPopup();
    marker_BM2.bindPopup(bMachala).openPopup();
    marker_BM3.bindPopup(bMachala).openPopup();
    marker_BM4.bindPopup(bMachala).openPopup();

    var bGuayaquilIcon = L.icon({iconUrl:"/assets/images/optimizadas_machalaep/puntos_pago/banco_guayaquil.webp", iconSize:[45,60],iconAnchor:[45,60]})
    var marker_BG1 = L.marker([-3.2610940952853866, -79.95736927484165], {icon:bGuayaquilIcon}).addTo(this.map);
    var marker_BG2 = L.marker([-3.271632068101696, -79.94232792696475 ], {icon:bGuayaquilIcon}).addTo(this.map);
    var marker_BG3 = L.marker([-3.2588950956969067, -79.96016636596813 ], {icon:bGuayaquilIcon}).addTo(this.map);
    var bGuayaquil="<b>Banco Guayaquil</b><br>Punto de Pago";
    marker_BG1.bindPopup(bGuayaquil).openPopup();
    marker_BG2.bindPopup(bGuayaquil).openPopup();
    marker_BG3.bindPopup(bGuayaquil).openPopup();

    var bPichinchaIcon = L.icon({iconUrl:"/assets/images/optimizadas_machalaep/puntos_pago/banco_pichincha.webp", iconSize:[45,60],iconAnchor:[45,60]})
    var marker_BP1 = L.marker([-3.2586805044806635, -79.96025535090413 ], {icon:bPichinchaIcon}).addTo(this.map);
    var marker_BP2 = L.marker([-3.279864753734091, -79.93296304311093 ], {icon:bPichinchaIcon}).addTo(this.map);
    var bPichincha="<b>Banco Pichincha</b><br>Punto de Pago";
    marker_BP1.bindPopup(bPichincha).openPopup();
    marker_BP2.bindPopup(bPichincha).openPopup();

    var cStaRosaIcon = L.icon({iconUrl:"/assets/images/optimizadas_machalaep/puntos_pago/santa_rosa.webp", iconSize:[45,60],iconAnchor:[45,60]})
    var marker_SR1 = L.marker([-3.2605402850870457, -79.95161299396692 ], {icon:cStaRosaIcon}).addTo(this.map);
    var marker_SR2 = L.marker([-3.2688964795522657, -79.96375937940367 ], {icon:cStaRosaIcon}).addTo(this.map);
    var bStaRosa="<b>Cooperativa Sta Rosa</b><br>Punto de Pago";
    marker_SR1.bindPopup(bStaRosa).openPopup();
    marker_SR2.bindPopup(bStaRosa).openPopup();

    var bProdubancoIcon = L.icon({iconUrl:"/assets/images/optimizadas_machalaep/puntos_pago/produbanco.webp", iconSize:[45,60],iconAnchor:[45,60]})
    var marker_BPD = L.marker([-3.2546711801634722, -79.96094316692928 ], {icon:bProdubancoIcon}).addTo(this.map);
    var bProdubanco="<b>Produbanco</b><br>Punto de Pago";
    marker_BPD.bindPopup(bProdubanco).openPopup();

    var bPacificoIcon = L.icon({iconUrl:"/assets/images/optimizadas_machalaep/puntos_pago/pacifico.webp", iconSize:[45,60],iconAnchor:[45,60]})
    var marker_BPa1 = L.marker([-3.263067768085601, -79.99750708961443 ], {icon:bPacificoIcon}).addTo(this.map);
    var marker_BPa2 = L.marker([-3.251152877384432, -79.94578652526948 ], {icon:bPacificoIcon}).addTo(this.map);
    var marker_BPa3 = L.marker([-3.279450223120618, -79.93220185935924 ], {icon:bPacificoIcon}).addTo(this.map);
    var marker_BPa4 = L.marker([-3.2752513979222115, -79.9340043038249 ], {icon:bPacificoIcon}).addTo(this.map);
    var marker_BPa5 = L.marker([-3.2688245905641655, -79.94627809232915 ], {icon:bPacificoIcon}).addTo(this.map);
    var marker_BPa6 = L.marker([ -3.264797103629315, -79.94602060026263], {icon:bPacificoIcon}).addTo(this.map);
    var marker_BPa7 = L.marker([-3.2522860838570367, -79.94619226164032 ], {icon:bPacificoIcon}).addTo(this.map);
    var marker_BPa8 = L.marker([-3.259569915656822, -79.96112680149862 ], {icon:bPacificoIcon}).addTo(this.map);
    var marker_BPa9 = L.marker([-3.261883592374509, -79.96215676976472 ], {icon:bPacificoIcon}).addTo(this.map);
    var bPacifico="<b>Banco Pacifico</b><br>Punto de Pago";
    marker_BPa1.bindPopup(bPacifico).openPopup();
    marker_BPa2.bindPopup(bPacifico).openPopup();
    marker_BPa3.bindPopup(bPacifico).openPopup();
    marker_BPa4.bindPopup(bPacifico).openPopup();
    marker_BPa5.bindPopup(bPacifico).openPopup();
    marker_BPa6.bindPopup(bPacifico).openPopup();
    marker_BPa7.bindPopup(bPacifico).openPopup();
    marker_BPa8.bindPopup(bPacifico).openPopup();
    marker_BPa9.bindPopup(bPacifico).openPopup();


    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
  }

  triggerScrollTo() {

    const config: ScrollToConfigOptions = {
      target: 'canales-pagos',
      offset: -115
    };

    this.scrollToService.scrollTo(config);
  }

}
