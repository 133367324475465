<ngx-spinner></ngx-spinner>
<section class="error-404 bg-img3">
    <div class="ttm-big-icon ttm-textcolor-skincolor">
        <i class="fa fa-thumbs-o-down"></i>
    </div>
    <header class="page-header">
        <h1 class="page-title">Error :(</h1>
    </header>
    <div class="page-content">
        <p>Lo sentimos, el URL no esta bajo los dominios de AGUAS MACHALA EP. <br> Intenta nuevamente ingresar la URL correcta o pulsa "Vamos al inicio".</p>
    </div>
    <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-black mb-15" routerLink="/">Vamos al Inicio</a>
</section>