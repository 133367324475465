<section class="container" *ngIf="show">
  <h3>Información del Cliente</h3>
  <div class="row">
    <div class="col-12 col-md-4">
      <div class="data-payment">
        <span>Nro. Transacción:</span>
        {{ this.dataPayment.merchantTransactionId }}
      </div>
    </div>
    <div class="col-12 col-md-4">
      <div class="data-payment"><span>Fecha: </span> {{ fecha | date }}</div>
    </div>
    <div class="col-12 col-md-4">
      <div class="data-payment">
        <span>Nro Cuenta: </span> {{ paymentAccount.cuenta }}
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="data-payment">
        <span>Dirección: </span> {{ paymentAccount.direccion }}
      </div>
    </div>
  </div>
  <h3 class="mt-4">Información del Pago</h3>
  <div class="row">
    <div class="col-12 col-md-4">
      <div class="data-payment">
        <span>Total:</span> {{ this.dataPayment.cart.items[0].price }} $
      </div>
    </div>
    <div class="col-12 col-md-8">
      <div class="data-payment">
        <span>Descripción:</span>
        {{ this.dataPayment.cart.items[0].description }}
      </div>
    </div>
  </div>
  <!-- Fomulario de información complementaria del pago -->
  <h3 class="mt-4">Información complementaria requerida</h3>
  <div class="alert alert-success" role="alert">
    Compruebe la información antes de enviar el formulario.
  </div>
  <form
    class="place-order"
    (ngSubmit)="payment()"
    [formGroup]="formDataPayment"
  >

    <div class="row">
      <div class="form-group col-12 col-sm-6 col-xl-4">
        <label for="givenName">Primer Nombre*</label>
        <input
          type="text"
          class="form-control"
          id="givenName"
          placeholder="Primer nombre"
          formControlName="givenName"
          [class.is-invalid]="invalidGivenName"
        />
        <small class="form-text text-danger" *ngIf="invalidGivenName"
          >Campo requerido.</small
        >
      </div>
      <div class="form-group col-12 col-sm-6 col-xl-4">
        <label for="middleName">Segundo Nombre*</label>
        <input
          type="text"
          class="form-control"
          id="middleName"
          placeholder="Segundo nombre"
          formControlName="middleName"
          [class.is-invalid]="invalidMiddleName"
        />
        <small class="form-text text-danger" *ngIf="invalidMiddleName"
          >Campo requerido.</small
        >
      </div>
      <div class="form-group col-12 col-sm-6 col-xl-4">
        <label for="surname">Apellidos*</label>
        <input
          type="text"
          class="form-control"
          id="surname"
          placeholder="Apellidos"
          formControlName="surname"
          [class.is-invalid]="invalidSurname"
        />
        <small class="form-text text-danger" *ngIf="invalidSurname"
          >Campo requerido.</small
        >
      </div>
      <div class="form-group col-12 col-sm-6 col-xl-4">
        <label for="email">Dirección de Correo Electrónico. *</label>
        <input
          type="email"
          class="form-control"
          id="email"
          placeholder="Correo Electrónico"
          formControlName="email"
          [class.is-invalid]="invalidEmail"
        />
        <small class="form-text text-danger" *ngIf="invalidEmail"
          >Campo requerido.</small
        >
      </div>

      <div class="form-group col-12 col-sm-6 col-xl-4">
        <label for="customerPhone">Número de teléfono. *</label>
        <input
          type="phone"
          class="form-control"
          id="customerPhone"
          placeholder="Numero de Teléfono"
          formControlName="phone"
          [class.is-invalid]="invalidPhone"
        />
        <small class="form-text text-danger" *ngIf="invalidPhone"
          >Campo requerido.</small
        >
      </div>

      <div class="col-12" *ngIf="!hiddenBotton">
        <button type="submit" class="button">Continuar con el pago</button>
      </div>
    </div>
  </form>

  <!-- End - Fomulario de información complementaria del pago -->
</section>
<section class="container text-center" *ngIf="loading">
  <div class="loading-consulta">
    <div class="i-l">
      <img src="assets/images/load.gif" alt="" />
    </div>
  </div>
  <h5>Espere por favor.</h5>
</section>

<section #paymentForm class="container form-payment">
  <form
    [action]="url"
    class="paymentWidgets"
    data-brands="VISA MASTER DISCOVER DINERS"
  ></form>
</section>
