<header id="masthead" class="header ttm-header-style-classic fixed-top">
  <div class="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
    <div class="container">
      <div class="ttm-topbar-content">
        <ul class="top-contact ttm-highlight-left text-left">
          <li>
            <i class="fa fa-phone"></i><strong>Servicio al cliente:</strong>
            <span class="tel-no">
              <a href="tel:+59373702010" target="_blank">+593 (7) 3702010</a></span>
          </li>
        </ul>
        <div class="topbar-right text-right">
          <ul class="top-contact">
            <li>
              <i class="fa fa-envelope-o"></i><strong>Email: </strong><a href="mailto:info@aguasmachala.gob.ec"
                target="_blank">info@aguasmachala.gob.ec</a>
            </li>
          </ul>
          <div class="ttm-social-links-wrapper list-inline">
            <ul class="social-icons">
              <li>
                <a href="https://www.facebook.com/aguasmachalaep" target="_blank"><i class="fa fa-facebook"
                    aria-hidden="true"></i></a>
              </li>
              <li>
                <a href="https://twitter.com/aguas_ep" target="_blank"><i class="fa fa-twitter"
                    aria-hidden="true"></i></a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCZjLjxrrb6OGH-BUP9PESdw" target="_blank"><i
                    class="fa fa-youtube" aria-hidden="true"></i></a>
              </li>
              <li>
                <a href="https://www.instagram.com/aguasmachala/" target="_blank"><i class="fa fa-instagram"
                    aria-hidden="true"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ttm-header-wrap">
    <div id="ttm-stickable-header-w" class="ttm-stickable-header-w clearfix">
      <div id="site-header-menu" class="site-header-menu">
        <div class="site-header-menu-inner ttm-stickable-header fixed-header ">
          <div class="containerfluid  px-md-0 px-3">
            <div class="site-branding ">
              <a class="home-link " routerLink="/" title="Boldman" rel="home">
                <img loading="lazy" id="logo-img" class="img-center" src="/assets/images/machalaep/img-logo-2.png"
                  alt="logo-img" />
              </a>
            </div>
            <div id="site-navigation" class="site-navigation bg-info">
              <div class="ttm-menu-toggle">
                <input type="checkbox" id="menu-toggle-form" />
                <label for="menu-toggle-form" class="ttm-menu-toggle-block">
                  <span class="toggle-block toggle-blocks-1"></span>
                  <span class="toggle-block toggle-blocks-2"></span>
                  <span class="toggle-block toggle-blocks-3"></span>
                </label>
              </div>

              <nav id="menu" class="menu">
                <ul class="dropdown ">
                  <li [routerLinkActive]="['active']">
                    <a class="nav-route" style="font-size: 12px;" routerLink="/home">INICIO</a>
                  </li>
                  <li [routerLinkActive]="['active']">
                    <a class="nav-route" style="font-size: 12px;" routerLink="servicios">SERVICIOS</a>
                  </li>
                  <li [routerLinkActive]="['active']">
                    <a class="nav-route" style="font-size: 12px;" routerLink="noticias">NOTICIAS</a>
                  </li>
                  <li class="nav-item" role="button">
                    <a class="dropdown-toggle" style="font-size: 12px;" role="button">TRANSPARENCIA</a>
                    <ul class="dropdown">
                      <li>
                        <a (click)="verLotaip()">LOTAIP</a>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item" style="font-size: 12px;" role="button">
                    <a class="dropdown-toggle" style="font-size: 12px;" role="button">TRABAJA CON NOSOTROS</a>
                    <ul class="dropdown">
                      <li>
                        <a  routerLink="convocatoria/info-table">PLANIFICACIÓN DE CONCURSOS 2023</a>
                      </li>
                      <li class="nav-item" *ngFor="let item of announData$ | async">
                        <!-- <a *ngIf="viewHeader(item.titulo)" (click)="viewAnnouncemet(item.id_convocatoria)">{{item.titulo}}</a> -->
                        <a  (click)="viewAnnouncemet(item.id_convocatoria)">{{item.titulo}}</a>
                      </li>
                     
                    </ul>
                  </li>
                  <li role="button">
                    <a class="nav-route" style="font-size: 12px;" (click)="verCuentasRen()">RENDICIÓN DE CUENTAS</a>
                  </li>
                  <!-- <li class="nav-item dropdown" [routerLinkActive]="['active']">
                    <a class="nav-link  dropdown-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      >TRANSPARENCIA</a>
                      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" routerLink="transparencia">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">Something else here</a>
                      </div>
                  </li> -->
                  <!-- <li [routerLinkActive]="['active']"><a class="nav-route" routerLink="licitacion">LICITACION</a>
                                    </li> -->
                  <li [routerLinkActive]="['active']">
                    <a class="nav-route" style="font-size: 12px;" routerLink="nosotros">NOSOTROS</a>
                  </li>
                  <li [routerLinkActive]="['active']">
                    <a class="nav-route" style="font-size: 12px;" routerLink="vivienda">VIVIENDA</a>
                  </li>
                  <li [routerLinkActive]="['active']">
                    <a class="nav-route" style="font-size: 12px;" routerLink="contacto">CONTACTO</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>