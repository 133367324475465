import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
declare let $: any;

@Component({
  selector: 'app-bidding',
  templateUrl: './bidding.component.html',
  styleUrls: ['./bidding.component.scss']
})
export class BiddingComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1500);
  }

  ngAfterViewInit(): void {
    $(document).ready(function () {
      $('.toggle').eq(0).addClass('active').find('.toggle-content').css('display', 'block');
      $('.accordion .toggle-title').click(function () {
        $(this).siblings('.toggle-content').slideToggle('fast');
        $(this).parent().toggleClass('active');
        $(this).parent().siblings().children('.toggle-content:visible').slideUp('fast');
        $(this).parent().siblings().children('.toggle-content:visible').parent().removeClass('active');
      });
    });

  }

  customOptionsObras: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 3000,
    dots: false,
    margin: 25,
    loop: true,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  }

  customOptionsEmpresas: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 4000,
    dots: false,
    margin: 25,
    loop: true,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 4
      }
    }
  }
}
