import { DataPayment } from './../../model/data-payment';
import { DatosDeuda, Deuda, Usuario } from './../../model/user-data';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatafastService } from './../../service/service/datafast.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  ScrollToConfigOptions,
  ScrollToService,
} from '@nicky-lenaers/ngx-scroll-to';
import { MachalaEpService } from 'src/app/service/service/machala-ep.service';
import { newArray } from '@angular/compiler/src/util';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, AfterViewInit {
  @ViewChild('paymentForm') paymentFormElement: ElementRef<HTMLElement>;
  @Output('error') errorAlert: EventEmitter<string> = new EventEmitter();
  value: string = '00.00';
  //! Esta variable es importante cambiar en produccion
  url = 'https://www.aguasmachala.gob.ec/pagoenlinea';
  //! -------------------------------------------------
  id = '';
  //* Variable para mostrar el Spinner de loading
  loading = false;
  //* Datos del usuario
  @Input() usuario: Usuario = undefined;
  //* Fecha actual
  fecha: Date = new Date();
  //* Oculta el boton de enviar el formulario
  hiddenBotton: boolean = false;
  //* Sirve para mostrar o ocultar el menu
  show = false;

  observer = null;
  //* Form group que guarda la informacion requerida para el pago
  formDataPayment: FormGroup;
  //* Datos importantes para efectuar un pago
  dataPayment: DataPayment;

  @Input() paymentList: Deuda[] = [];
  @Input() paymentAccount: Deuda;
  @Output() cancel = new EventEmitter<Boolean>();

  constructor(
    private datafast: DatafastService,
    private render: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private scrollToService: ScrollToService,
    private formBuilder: FormBuilder,
    private machalaEp: MachalaEpService
  ) {}
  ngAfterViewInit() {
    this.observer = new MutationObserver((mutations) => {
      this.loading = false;
    });
    var config = { attributes: true, childList: true, characterData: true };

    this.observer.observe(this.paymentFormElement.nativeElement, config);
  }
  ngOnInit(): void {
    this.formDataPayment = this.formBuilder.group({
      email: ['', Validators.required],
      phone: ['', Validators.required],
      givenName: ['', Validators.required],
      middleName: ['', Validators.required],
      surname: ['', Validators.required],
    });
  }

  payment(): void {
    this.loading = true;

    if (this.formDataPayment.valid) {
      this.dataPayment.customer.email = this.formDataPayment.get('email').value;
      this.dataPayment.customer.phone = this.formDataPayment.get('phone').value;
      this.dataPayment.customer.givenName =
        this.formDataPayment.get('givenName').value;
      this.dataPayment.customer.middleName =
        this.formDataPayment.get('middleName').value;
      this.dataPayment.customer.surname =
        this.formDataPayment.get('surname').value;
      // this.dataPayment.amount = 1;
      // this.dataPayment.customParameters.SHOPPER_VAL_BASE0 = 1;
      // this.dataPayment.cart.items[0].price = 1;
      this.datafast.getCheckoutId(this.dataPayment).subscribe(
        (data) => {
          console.group('INFORMACION DEL PAGO')
          /* console.log(data);
          console.log(this.dataPayment.customer.merchantCustomerId); */
          console.groupEnd();


          //* Agrega de forma dinamica el script de widget
          this.triggerScrollTo();
          const script = this.render.createElement('script');
          script.type = 'text/javascript';
          script.src = `https://oppwa.com/v1/paymentWidgets.js?checkoutId=${data.id}`;
          this.render.appendChild(this.document.body, script);

          const script_validation = this.render.createElement('script');
          script_validation.type = 'text/javascript';
          script_validation.src = 'assets/js/validation.js'
          this.render.appendChild(this.document.body, script_validation)

          const script_additional_validations = this.render.createElement('script');
          script_additional_validations.type = 'text/javascript';
          script_additional_validations.src = 'https://www.datafast.com.ec/js/dfAdditionalValidations1.js'
          this.render.appendChild(this.document.body, script_additional_validations)

          this.hiddenBotton = !this.hiddenBotton;
          //* Almacena el id en el localStorage para que no se borre al momento de recargar o redireccionar despues del pago
          localStorage.setItem('id', data.id);
          this.show = false;
        },
        (err) => {
          console.log(err);

          this.loading = false;
        }
      );
    } else {
      this.loading = false;
      this.validForm();
    }
  }

  set setValue(value: string) {
    this.value = value;
  }

  cancelPay(message = '') {
    if(message) {
      this.errorAlert.emit(message);
    }
    this.cancel.emit(true);
  }

  capitalize(data: string) {
    if(data) {
      return data.charAt(0).toUpperCase() + data.substring(1).toLowerCase();
    }
    return '';
  }

  async setDataDebt(usuario: Usuario, paymentAccount: Deuda) {
    this.paymentAccount = paymentAccount;

    usuario.APELLIDOS = usuario.APELLIDOS ? usuario.APELLIDOS.split(' ').map(data => this.capitalize(data)).join(' ') : '';
    usuario.NOMBRE = usuario.NOMBRE ? usuario.NOMBRE.split(' ').map(data => this.capitalize(data)).join(' ') : '';
    usuario.NOMBRE_COMPLETO = usuario.NOMBRE_COMPLETO ? usuario.NOMBRE_COMPLETO.split(' ').map(data => this.capitalize(data)).join(' ') : '';

    this.usuario = usuario;

    try {
      const { ip } = await this.machalaEp.getIpUser().toPromise();
      const { fecha, id_transaccion } = await this.machalaEp
        .createTransaction({
          nombre: this.usuario.NOMBRE ? this.usuario.NOMBRE.split(' ')[0] : this.usuario.NOMBRE_COMPLETO,
          apellidos: this.usuario.APELLIDOS,
          total: this.paymentAccount.valor,
          estado: 'no-pago',
          nro_cuenta: this.paymentAccount.cuenta,
          identificacion: this.usuario.IDENTIFICACION,
          fecha: this.fecha,
          ultima_factura: this.paymentAccount.numero_ultima_factura,
        })
        .toPromise();
      // console.log(ip);
      const identificacion = this.usuario.IDENTIFICACION;
      this.dataPayment = {
        entityId: '8ac9a4cd7e718a7b017e734f992220f8',
        amount: this.paymentAccount.valor,
        currency: 'USD',
        paymentType: 'DB',
        merchantTransactionId: `${this.paymentAccount.numero_ultima_factura}-${id_transaccion}`,
        customer: {
          givenName: '',
          middleName: '',
          surname: '',
          ip: ip,
          merchantCustomerId: `${this.paymentAccount.cuenta}`,
          email: '',
          identificationDocType: 'IDCARD',
          identificationDocId:
            identificacion.length >= 10
              ? identificacion.substring(0, 10)
              : `${Array(10 - identificacion.length)
                  .fill('0')
                  .join('')}${identificacion}`,
          phone: '',
        },
        billing: {
          street1: this.paymentAccount.direccion,
          country: 'EC',
          postcode: '070151',
        },
        shipping: {
          street1: this.paymentAccount.direccion,
          country: 'EC',
        },
        risk: {
          USER_DATA2: 'AGUASMACHALA',
        },
        customParameters: {
          SHOPPER_MID: '4100003338',
          SHOPPER_TID: 'BP385186',
          SHOPPER_VAL_BASE0: this.paymentAccount.valor,
          SHOPPER_VAL_BASEIMP: 0.0,
          SHOPPER_VAL_IVA: 0.0,
          SHOPPER_ECI: '0103910',
          SHOPPER_PSERV: '17913101',
          SHOPPER_VERSIONDF: 2,
        },
        cart: {
          items: [
            {
              name: 'Pago de consumo de agua',
              description: `Pago de consumo de agua. Fecha de la transacción: ${fecha}`,
              price: this.paymentAccount.valor,
              quantity: 1,
            },
          ],
        },
      };
      // console.log(this.dataPayment);
      const givenName = usuario.NOMBRE ? usuario.NOMBRE.split(' ')[0] : '';
      const middleName = usuario.NOMBRE && usuario.NOMBRE.split(' ').length > 1 ? usuario.NOMBRE.split(' ')[1] : '';
      const surname = usuario.NOMBRE ? (usuario.APELLIDOS ? usuario.APELLIDOS : '') : '';
      this.formDataPayment
        .get('givenName')
        .setValue(givenName ? givenName : '');
      this.formDataPayment
        .get('middleName')
        .setValue(middleName ? middleName : '');
      this.formDataPayment.get('surname').setValue(surname ? surname : '');
      this.validatePaymentDetails(this.dataPayment);
      this.show = true;
    } catch (error) {
      console.log(error);
      console.log(error.message);

      if (
        error &&
        error.message &&
        error.message.indexOf('custom-check:') >= 0
      ) {
        let { message } = error;
        message = message.substring('custom-check: '.length);

        this.cancelPay(message);
      }
    }
  }

  validatePaymentDetails(dataPayment: DataPayment) {
    return (
      this.dataPayment.amount &&
      this.dataPayment.entityId &&
      this.dataPayment.billing.postcode &&
      this.dataPayment.customParameters.SHOPPER_VAL_BASE0 &&
      this.dataPayment.cart.items[0].price &&
      this.checkMinMax(
        'merchantTransactionId',
        this.dataPayment.merchantTransactionId,
        8,
        255
      ) &&
      this.checkMinMax('ip', this.dataPayment.customer.ip, 1, 255) &&
      this.checkMinMax(
        'merchantCustomerId',
        this.dataPayment.customer.merchantCustomerId,
        1,
        16
      ) &&
      // this.checkMinMax('email',this.dataPayment.customer.email              , 8, 255) &&
      this.checkMinMax(
        'identificationDocId',
        this.dataPayment.customer.identificationDocId,
        10,
        10
      ) &&
      // this.checkMinMax('phone', this.dataPayment.customer.phone              , 7, 25) &&
      this.checkMinMax(
        'billing.street1',
        this.dataPayment.billing.street1,
        1,
        100
      ) &&
      this.checkMinMax(
        'billing.country',
        this.dataPayment.billing.country,
        2,
        2
      ) &&
      this.checkMinMax(
        'shipping.street1',
        this.dataPayment.shipping.street1,
        1,
        100
      ) &&
      this.checkMinMax(
        'shipping.country',
        this.dataPayment.shipping.country,
        2,
        2
      ) &&
      this.checkMinMax('name', this.dataPayment.cart.items[0].name, 1, 255) &&
      this.checkMinMax(
        'description',
        this.dataPayment.cart.items[0].description,
        1,
        255
      )
    );
  }

  checkMinMax(name: string, value: string, min: number, max: number): boolean {
    const flag = value && value.length >= min && value.length <= max;
    if (flag) {
      return flag;
    } else {
      if (name === 'ip') {
        throw new Error(
          `custom-check: La dirección IP del usuario no pudo ser obtenida, intente nuevamente.`
        );
      } else if (!value) {
        throw new Error(`custom-check: La variable ${name} es null`);
      } else {
        throw new Error(`custom-check: La variable ${name} no cumple con una logitud mínima de ${min} y máxima de ${max}`);
      }
    }
  }
  checkValue(value: string, valueDefault: string) {
    return value === valueDefault;
  }
  validForm(): void {
    if (this.formDataPayment.invalid) {
      return Object.values(this.formDataPayment.controls).forEach((control) =>
        control.markAllAsTouched()
      );
    }
  }

  triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: 'consultDebts',
      offset: -146,
    };

    this.scrollToService.scrollTo(config);
  }

  //* Validators
  get invalidEmail() {
    return (
      this.formDataPayment.get('email').invalid &&
      this.formDataPayment.get('email').touched
    );
  }

  get invalidPhone() {
    return (
      this.formDataPayment.get('phone').invalid &&
      this.formDataPayment.get('phone').touched
    );
  }
  get invalidGivenName() {
    return (
      this.formDataPayment.get('givenName').invalid &&
      this.formDataPayment.get('givenName').touched
    );
  }
  get invalidMiddleName() {
    return (
      this.formDataPayment.get('middleName').invalid &&
      this.formDataPayment.get('middleName').touched
    );
  }
  get invalidSurname() {
    return (
      this.formDataPayment.get('surname').invalid &&
      this.formDataPayment.get('surname').touched
    );
  }
}
