import { Component, OnInit } from '@angular/core';
import { Transparencia } from '../../model/transparencia/transparencia.model';
import { Mes } from '../../model/transparencia/mes.model';
import { Anio } from '../../model/transparencia/anio.model';
import { TransparenciaService } from '../../service/transparencia/transparencia.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { YearAccountability } from '../../controller/accountability.interface';
import { MachalaEpService } from 'src/app/service/service/machala-ep.service';
import { Router } from '@angular/router';
import {
  ScrollToConfigOptions,
  ScrollToService,
} from '@nicky-lenaers/ngx-scroll-to';

// declare let $: any;

@Component( {
  selector: 'app-transparency',
  templateUrl: './transparency.component.html',
  styleUrls: [ './transparency.component.scss' ],
} )
export class TransparencyComponent implements OnInit {
  public insumosoptions: [ "POA", "PAC" ];
  public transparencias: Transparencia[];
  public meses: Mes[];
  public anios: Anio[];
  public aniosL;
  accountabilities: YearAccountability[] = [];
  selectedOptionM: string;
  selectedOptionA: string;
  printedOption: string;
  constructor(
    private transparenciaService: TransparenciaService,
    private spinner: NgxSpinnerService,
    private machalaEp: MachalaEpService,
    private router: Router,
    private scrollToService: ScrollToService
  ) {
    this.getAnioTransparencia();
  }

  ngOnInit(): void {
    this.getAccountabilities();
    window.scrollTo( 0, 0 );

    this.spinner.show();
    setTimeout( () => {
      this.spinner.hide();
      if ( this.router.url.toString().includes( '#cuentas-ren' ) ) {
        this.triggerScrollToB();
      }
      if ( this.router.url.toString().includes( '#lotaip' ) ) {
        this.triggerScrollTo();
      }
    }, 1500 );
  }

  public getDocsTransparencia( anioS: string, mesS: string ) {
    this.spinner.show();
    this.transparenciaService
      .getDocsTransparencia( mesS, anioS )
      .subscribe( ( transparencias ) => ( this.transparencias = transparencias ) );
    this.spinner.hide();
  }

  public getMesTransparencia() {
    this.spinner.show();
    var slt_anio = ( <HTMLInputElement>document.getElementById( 'selectAnioD' ) )
      .value;
    this.transparenciaService
      .getMesTransparencia( slt_anio )
      .subscribe( ( meses ) => ( this.meses = meses ) );
    this.spinner.hide();
  }
  public getAnioTransparencia() {
    this.spinner.show();

    this.transparenciaService.getAnioTransparencia().subscribe( ( anios ) => {
      this.anios = anios;
      this.anios.unshift( new Anio( '-Seleccione un año-' ) );
    } );
    this.spinner.hide();
  }

  public ordenarArray( array: any[] ): string[] {
    const order = [ "POA 2023", "PRESUPUESTO 2023", "Plan de Trabajo 2023-2027", "PAC 2023" ];
    return array.sort( ( a, b ) => {
      console.log( array );
      return order.indexOf( a.nombre ) - order.indexOf( b.nombre );
    } );

  }

  public verificarArray( array: any[] ): boolean {

    if ( !array ) {
      return false;
    } else {
      return array.length > 0;
    }
  }

  public verificarPrefijoA( archivo: string ): boolean {
    return archivo.startsWith( "1-1" );
  }

  public verificarPrefijoB( archivo: string ): boolean {
    return archivo.startsWith( "1.2" );
  }

  public verificarPrefijoC( archivo: string ): boolean {
    return archivo.startsWith( "1.4" );
  }

  public verificarPrefijoD( archivo: string ): boolean {
    return archivo.startsWith( "2-1" );
  }

  public verificarPrefijoE( archivo: string ): boolean {
    return archivo.startsWith( "3-" );
  }
  public verificarPrefijoF( archivo: string ): boolean {
    return archivo.startsWith( "4-" );
  }
  public verificarPrefijoG( archivo: string ): boolean {
    return archivo.startsWith( "5-" );
  }
  public verificarPrefijoH( archivo: string ): boolean {
    return archivo.startsWith( "6-" );
  }
  public verificarPrefijoI( archivo: string ): boolean {
    return archivo.startsWith( "7-" );
  }
  public verificarPrefijoJ( archivo: string ): boolean {
    return archivo.startsWith( "8-" );
  }
  public verificarPrefijoK( archivo: string ): boolean {
    return archivo.startsWith( "9-" );
  }
  public verificarPrefijoL( archivo: string ): boolean {
    return archivo.startsWith( "10-" );
  }
  public verificarPrefijoM( archivo: string ): boolean {
    return archivo.startsWith( "11-" );
  }
  public verificarPrefijoO( archivo: string ): boolean {
    return archivo.startsWith( "12-" );
  }
  public verificarPrefijoP( archivo: string ): boolean {
    return archivo.startsWith( "13-" );
  }
  public verificarPrefijoQ( archivo: string ): boolean {
    return archivo.startsWith( "14-" );
  }
  public verificarPrefijoR( archivo: string ): boolean {
    return archivo.startsWith( "15-" );
  }
  public verificarPrefijoS( archivo: string ): boolean {
    return archivo.startsWith( "16-" );
  }
  public verificarPrefijoT( archivo: string ): boolean {
    return archivo.startsWith( "17-" );
  }
  public verificarPrefijoU( archivo: string ): boolean {
    return archivo.startsWith( "18-" );
  }
  public verificarPrefijoV( archivo: string ): boolean {
    return archivo.startsWith( "19-" );
  }
  public verificarPrefijoW( archivo: string ): boolean {
    return archivo.startsWith( "20-" );
  }
  public verificarPrefijoX( archivo: string ): boolean {
    return archivo.startsWith( "21-" );
  }
  public verificarPrefijoY( archivo: string ): boolean {
    return archivo.startsWith( "23-" );
  }
  public verificarPrefijoZ( archivo: string ): boolean {
    return archivo.startsWith( "24-" );
  }

  public verificarPrefijoArt29A( archivo: string ): boolean {
    return archivo.startsWith( "art29-1" );
  }
  public verificarPrefijoArt29B( archivo: string ): boolean {
    return archivo.startsWith( "art29-2" );
  }
  public verificarPrefijoArt29C( archivo: string ): boolean {
    return archivo.startsWith( "art29-3" );
  }

  public verificarPrefijoArt23A( archivo: string ): boolean {
    return archivo.startsWith( "1-Act" ) || archivo.startsWith( "2-Act" ) || archivo.startsWith( "3-Act" );
  }
  public verificarPrefijoArt23B( archivo: string ): boolean {
    return archivo.startsWith( "1-Pas" ) || archivo.startsWith( "2-Pas" ) || archivo.startsWith( "3-Pas" );
  }
  public verificarPrefijoArt23C( archivo: string ): boolean {
    return archivo.startsWith( "1-Foc" ) || archivo.startsWith( "2-Foc" ) || archivo.startsWith( "3-Foc" );
  }
  public verificarPrefijoArt23D( archivo: string ): boolean {
    return archivo.startsWith( "1-Col" ) || archivo.startsWith( "2-Col" ) || archivo.startsWith( "3-Col" );
  }

  public filterArt23( array: any[], name: string ) {

    return array.filter( ( value ) => value.nombre == name );
  }

  public filter2023( array: any[] ) {
    return array.filter( ( value ) => ( value.nombre == "POA 2023" || value.nombre == "PRESUPUESTO 2023" || value.nombre == "PAC 2023" || value.nombre == "Plan de Trabajo 2023-2027" ) );
  }

  public filter2023B( array: any[] ) {
    return array.filter( ( value ) => ( value.nombre == "Informe Rendición Cuentas 2023 AMEP" ) );
  }

  public filter2023C( array: any[] ) {
    const desiredOrder = [
      "Publicación Informe RC 2023",
      "Invitación a Deliberación",
      "Registro de Asistencia Deliberación",
      "Actas Sugerencias y Recomendaciones Ciudadanas"
    ];
  
    return array
      .filter(value => desiredOrder.includes(value.nombre))
      .sort((a, b) => desiredOrder.indexOf(a.nombre) - desiredOrder.indexOf(b.nombre));
  }

  public filterAnios( array: any[] ) {
    return array.filter( ( value ) => ( value.nombre == "POA" || value.nombre == "PAC" || value.nombre == "Presupuesto 2022" ) );
  }

  public onlyDocuments( array: any[] ) {
    return array.filter( ( value ) => ( value.nombre == "Informe Rendición de Cuentas 2022" || value.nombre == "Formulario RC 2022" ) );
  }

  public filterVerification( array: any[] ) {
    return array.filter( ( value ) => ( value.nombre == "Balance general" || value.nombre == "Ejecución de presupuesto" ) );
  }

  public filterInformation( array: any[] ) {
    return array.filter( ( value ) => ( value.nombre == "Informe Rendición de Cuentas 2022" || value.nombre == "Formulario RC 2022" ) );
  }

  public filterOnly( array: any[] ) {
    return array.filter( ( value ) => ( value.nombre == "Informe Rendición de Cuentas 2022" || value.nombre == "Formulario RC 2022" ) );
  }

  public filterFS0( array: any[] ) {
    return array.filter( ( value ) => ( value.nombre == "Conformación Equipo Tecnico" ) );
  }
  public filterFS1( array: any[] ) {
    return array.filter( ( value ) => ( value.nombre == "Invitación Actores Ciudadanos para Proceso Rendición de Cuentas" ) );
  }
  public filterFS2( array: any[] ) {
    return array.filter( ( value ) => ( value.nombre == "Aprobación de Informe Rendición de Cuentas" ) );
  }
  public filterFS3( array: any[] ) {
    return array.filter( ( value ) => ( value.nombre == "Informe Rendición de Cuentas 2022" || value.nombre == "Invitación a Deliberación Publica" || value.nombre == "ACTA DE SUGERENCIAS Y RECOMENDACIONES DE MESAS TEMATICAS" ) );
  }
  public filterFS4( array: any[] ) {
    return array.filter( ( value ) => ( value.nombre == "1 Plan de Trabajo RC 2022" || value.nombre == "Entrega de Plan de Trabajo Actores Ciudadanos RC 2022" || value.nombre == "Publicación de Informe de Rendición de Cuentas" ) );
  }


  public buscarDocumentos() {
    var anioS = ( <HTMLInputElement>document.getElementById( 'selectAnioD' ) )
      .value;
    var mesS = ( <HTMLInputElement>document.getElementById( 'selectMesD' ) ).value;
    var mesesList = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    var indexMes = mesesList.indexOf( mesS ) + 1;
    this.transparencias = [];
    this.spinner.show();
    this.getDocsTransparencia( anioS, indexMes.toString() );
    this.spinner.hide();
  }
  public openPDFFile( url: string ) {
    window.open( url, '_blank' );
  }
  public downloadPDFFile( url: string ) {
    var urlArray = url.split( '/' );
    var filename = urlArray[ urlArray.length - 1 ];
    fetch( url ).then( function ( t ) {
      return t.blob().then( ( b ) => {
        var a = document.createElement( 'a' );
        a.href = URL.createObjectURL( b );
        a.setAttribute( 'download', filename );
        a.click();
      } );
    } );
  }
  getAccountabilities(): void {
    this.machalaEp.obtenerRendiciondeCuentas().subscribe( ( resp ) => {
      this.accountabilities = resp;
    } );
  }

  triggerScrollToB() {

    const config: ScrollToConfigOptions = {
      target: 'cuentas-ren',
      offset: -115
    };

    this.scrollToService.scrollTo( config );
  }

  triggerScrollTo() {

    const config: ScrollToConfigOptions = {
      target: 'lotaip',
      offset: -115
    };

    this.scrollToService.scrollTo( config );
  }
}
