import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Noticia } from 'src/app/model/noticia/noticia.model';
import { Noticias } from 'src/app/model/noticias/noticias.model';
import { NoticiaService } from '../../service/noticia/noticia.service';
import { NoticiasService } from '../../service/noticias/noticias.service';
import { NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-single-news',
  templateUrl: './single-news.component.html',
  styleUrls: ['./single-news.component.scss'],
})
export class SingleNewsComponent implements OnInit {
  public noticias: Noticia;
  public noticiasLast: Noticias[];
  public categorias;
  public urls_imagenes;
  public urls_videos;
  public mesesList = [
    'En',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];
  public mesS;
  public diaS;

  constructor(
    private router: Router,
    private noticiaService: NoticiaService,
    private noticiasService: NoticiasService,
    private spinner: NgxSpinnerService
  ) {
    var current_url = router.url.split('/');
    var id = current_url[current_url.length - 1];
    this.getNoticia(id);
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
        var current_url = event.url.split('/');
        var id = current_url[current_url.length - 1];
        this.getNoticia(id);
      }
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }

  customOptionsNews: OwlOptions = {
    dots: true,
    margin: 25,
    navText: [
      "<i class='fa fa-chevron-left'></i>",
      "<i class='fa fa-chevron-right'></i>",
    ],
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  customImgsNew: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 7000,
    dots: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    margin: 25,
    nav: true,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
    items: 1,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  customTagsNews: OwlOptions = {
    autoplay: false,
    autoplayTimeout: 5000,
    dots: false,
    items: 2,
    margin: 5,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  customVideoNew: OwlOptions = {
    autoplay: false,
    autoplayTimeout: 5000,
    dots: false,
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    margin: 25,
    navText: ['', ''],
    nav: false,
    items: 1,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
  public getNoticia(id: string) {
    this.spinner.show();

    this.noticiaService.getNoticia(id).subscribe((noticias) => {
      this.noticias = noticias;
      var fechaTemp = new Date(this.noticias.fecha);
      this.diaS = fechaTemp.getDate();
      this.mesS = this.mesesList[fechaTemp.getMonth()];
      this.noticias.fecha =
        fechaTemp.getDate() +
        '/' +
        String(fechaTemp.getMonth() + 1) +
        '/' +
        fechaTemp.getFullYear();
      var categoriaStr = this.noticias.tags;

      var urlStr = this.noticias.imagenes;

      var categoriaList = categoriaStr.toString().split(',');
      var urlList = urlStr.toString().split(',');

      if (this.noticias.videos.length > 0) {
        var urlVideoStr = this.noticias.videos;
        var urlVideoList = urlVideoStr.toString().split(',');
        var urlVideoTemp = [];
        urlVideoList.forEach(function (value) {
          urlVideoTemp.push({ url: value });
        });
        this.urls_videos = urlVideoTemp;
      }

      var categoriasTemp = [];
      var urlTemp = [];
      categoriaList.forEach(function (value) {
        categoriasTemp.push({ nombre: value });
      });
      urlList.forEach(function (value) {
        urlTemp.push({ url: value });
      });

      this.categorias = categoriasTemp;
      this.urls_imagenes = urlTemp;
    });
    this.spinner.hide();
  }
}
