export class Logro {
    descripcion: string;
    cantidad: string;
    unidad: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
