<ngx-spinner></ngx-spinner>
<footer class="footer widget-footer clearfix">
  <div class="first-footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div
            class="ttm-footer-cta-wrapper ttm-textcolor-dark ttm-bgcolor-white"
          >
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 widget-area">
                <div
                  class="featured-icon-box iconalign-before-heading ttm-icon_element-size-lg"
                >
                  <div class="featured-content">
                    <div class="featured-icon">
                      <div
                        class="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg"
                      >
                        <i class="ti ti-email"></i>
                      </div>
                    </div>
                    <div class="featured-title">
                      <h5>Mantengámonos en contacto</h5>
                      <h6>
                        Suscribete a nuestro boletín para enterarte de nuevas
                        noticias, obras y acontecimientos.
                        <strong>¡Trabajamos por ti!</strong>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 widget-area">
                <form
                  id="subscribe-form"
                  class="newsletter-form"
                  method="post"
                  action="#"
                  data-mailchimp="true"
                >
                  <div
                    class="mailchimp-inputbox clearfix"
                    id="subscribe-content"
                  >
                    <p>
                      <input
                        type="email"
                        name="email"
                        placeholder="Correo Electrónico"
                        required=""
                        id="ipt_email_footer"
                      />
                    </p>
                    <p>
                      <input
                        type="submit"
                        value="SUSCRIBIRME"
                        id="btnSuscribir"
                        (click)="suscribir()"
                      />
                    </p>
                  </div>
                  <div id="subscribe-msg"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="second-footer ttm-textcolor-white">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
          <div class="widget clearfix">
            <div class="footer-logo">
              <img
                loading="lazy"
                id="footer-logo-img"
                src="/assets/images/logo.png"
                alt="logo de aguas de machala"
              />
            </div>
            <p>
              Aguas Machala-EP es una empresa pública, comprometida en mejorar
              la calidad de vida de los habitantes del cantón Machala, a través
              de la prestación de servicios de agua potable y saneamiento
              ambiental, que contribuyan al habitad y buen vivir, así como
              estructurar, gestionar y promover proyectos de vivienda, buscando
              aportar soluciones al déficit habitacional.
            </p>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
          <div class="widget widget_nav_menu clearfix">
            <h3 class="widget-title">Instituciones Amigas</h3>
            <ul id="menu-footer-services">
              <li>
                <a href="https://www.machala.gob.ec" target="_blank"
                  >GAD MACHALA</a
                >
              </li>
              <li>
                <a href="https://www.emamep.gob.ec/portal/" target="_blank"
                  >ASEO EP</a
                >
              </li>
              <li>
                <a href="http://www.mercadosmachala.gob.ec" target="_blank"
                  >MERCADOS EP</a
                >
              </li>
              <li>
                <a href="http://www.movilidadmachala.gob.ec" target="_blank"
                  >MOVILIDAD EP</a
                >
              </li>
              <li>
                <a href="https://saludmachala.gob.ec" target="_blank"
                  >SALUD EP</a
                >
              </li>
              <li>
                <a href="https://www.bomberosmachala.gob.ec" target="_blank"
                  >BOMBEROS</a
                >
              </li>
              <li>
                <a
                  href="http://www.terminalterrestremachala.gob.ec"
                  target="_blank"
                  >TERMINAL TERRESTRE</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
          <div class="widget widget_text clearfix">
            <h3 class="widget-title">Contacto</h3>
            <div class="textwidget widget-text">
              <p>
                Dirección:
                <a
                  href="http://maps.google.com/?q=Av. 9 de Mayo e/. Manuel Serrano y Arízaga, Machala, Ecuador"
                  >Av. 9 de Mayo e/. Manuel Serrano y Arízaga</a
                >
              </p>
              <p>Teléfonos: <a href="tel:+59373702010">+593 (7) 3702010</a></p>
              <p>
                E-mail:
                <a href="mailto:info@aguasmachala.gob.ec"
                  >info@aguasmachala.gob.ec</a
                >
              </p>
              <div class="widget widget_text clearfix">
                <h3 class="widget-title">Horario Laboral</h3>
                <div class="textwidget widget-text">
                  <div class="ttm-pricelistbox-wrapper">
                    <div class="ttm-timelist-block-wrapper">
                      <ul class="ttm-timelist-block">
                        <li>
                          Lunes - Viernes
                          <span class="service-time"
                            ><strong>08h00 - 17h00</strong></span
                          >
                        </li>
                        <li>
                          Sábados (Solo en Mercado de Mi Ciudad)
                          <span class="service-time"
                            ><strong>08h00 - 16h30</strong></span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
          <div class="widget flicker_widget clearfix">
            <h3 class="widget-title">Obras</h3>
            <div class="ttm-flicker-widget-wrapper">
              <a
                (click)="
                  abrirImagen(
                    '/assets/images/optimizadas_machalaep/obras-footer/big/1_ob_pozo12.webp'
                  )
                "
                ><img
                  loading="lazy"
                  src="/assets/images/optimizadas_machalaep/obras-footer/pozo12.webp"
                  alt="Pozo 12"
                  title="themetech-one"
              /></a>
              <a
                (click)="
                  abrirImagen(
                    '/assets/images/optimizadas_machalaep/obras-footer/big/2_ob_urseza1.webp'
                  )
                "
                ><img
                  loading="lazy"
                  src="/assets/images/optimizadas_machalaep/obras-footer/urseza1.webp"
                  alt="Urseza"
                  title="themetech-two"
              /></a>
              <a
                (click)="
                  abrirImagen(
                    '/assets/images/optimizadas_machalaep/obras-footer/big/3_ob_pozogonzrub1.webp'
                  )
                "
                ><img
                  loading="lazy"
                  src="/assets/images/optimizadas_machalaep/obras-footer/pozogonzrub1.webp"
                  alt="Pozo Gonzales Rubio"
                  title="themetech-three"
              /></a>
              <a
                (click)="
                  abrirImagen(
                    '/assets/images/optimizadas_machalaep/obras-footer/big/4_ob_urseza2.webp'
                  )
                "
                ><img
                  loading="lazy"
                  src="/assets/images/optimizadas_machalaep/obras-footer/urseza2.webp"
                  alt="Urseza"
                  title="themetech-four"
              /></a>
              <a
                (click)="
                  abrirImagen(
                    '/assets/images/optimizadas_machalaep/obras-footer/big/5_ob_campo.webp'
                  )
                "
                ><img
                  loading="lazy"
                  src="/assets/images/optimizadas_machalaep/obras-footer/campo.webp"
                  alt="Inauguracion"
                  title="themetech-five"
              /></a>
              <a
                (click)="
                  abrirImagen(
                    '/assets/images/optimizadas_machalaep/obras-footer/big/6_ob_pozo18.webp'
                  )
                "
                ><img
                  loading="lazy"
                  src="/assets/images/optimizadas_machalaep/obras-footer/pozo18.webp"
                  alt="Pozo"
                  title="themetech-six"
              /></a>
              <!-- <a (click)="abrirImagen('/assets/images/optimizadas_machalaep/obras-footer/big/7_ob_pozogonzrub2.webp')"><img
                  loading="lazy" src="/assets/images/optimizadas_machalaep/obras-footer/pozogonzrub2.webp"
                  alt="Pozo Gonzales Rubio" title="themetech-seven"></a> -->
              <a
                (click)="
                  abrirImagen(
                    '/assets/images/optimizadas_machalaep/obras-footer/big/8_ob_urseza3.webp'
                  )
                "
                ><img
                  loading="lazy"
                  src="/assets/images/optimizadas_machalaep/obras-footer/urseza3.webp"
                  alt="Urseza"
                  title="themeteh-eight"
              /></a>
              <a
                (click)="
                  abrirImagen(
                    '/assets/images/optimizadas_machalaep/obras-footer/big/9_ob_pozosur.webp'
                  )
                "
                ><img
                  loading="lazy"
                  src="/assets/images/optimizadas_machalaep/obras-footer/pozosur.webp"
                  alt="Pozo Sur"
                  title="themetech-nine"
              /></a>
              <a
                (click)="
                  abrirImagen(
                    '/assets/images/optimizadas_machalaep/obras-footer/big/obra_1.jpeg'
                  )
                "
                ><img
                  loading="lazy"
                  src="/assets/images/optimizadas_machalaep/obras-footer/obra_1.jpg"
                  alt="Pozo Sur"
                  title="themetech-ten"
              /></a>
              <a
                (click)="
                  abrirImagen(
                    '/assets/images/optimizadas_machalaep/obras-footer/big/obra_2.jpeg'
                  )
                "
                ><img
                  loading="lazy"
                  src="/assets/images/optimizadas_machalaep/obras-footer/obra_2.jpg"
                  alt="Pozo Sur"
                  title="themetech-eleven"
              /></a>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="text-center">
                  <a
                    class="ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-icon-btn-right ttm-btn-style-border ttm-btn-color-white"
                    (click)="verObras()"
                    >Ver más &nbsp;<i class="ti ti-angle-double-right"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-footer-text ttm-textcolor-white">
    <div class="container">
      <div class="row copyright">
        <div class="col-md-8 ttm-footer2-left">
          <span>Desarrollado por <a href="https://www.eiteck.com" target="_blank" >EITECK © {{ year }}</a></span>
        </div>
        <div class="col-md-4 ttm-footer2-right">
          <div class="social-icons">
            <ul class="list-inline">
              <li>
                <a
                  href="https://www.facebook.com/aguasmachalaep"
                  target="_blank"
                  ><i class="fa fa-facebook" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a href="https://twitter.com/aguas_ep" target="_blank"
                  ><i class="fa fa-twitter" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCZjLjxrrb6OGH-BUP9PESdw"
                  target="_blank"
                  ><i class="fa fa-youtube" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/aguasmachala/"
                  target="_blank"
                  ><i class="fa fa-instagram" aria-hidden="true"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-4 terms text-white text-center">
    Aquí puedes ver nuestros <a class="link" routerLink="/terminos">Términos y Condiciones</a> y actualizaciones de nuestras <a class="link" routerLink="/privacidad">políticas de privacidad</a>
  </div>
</footer>
