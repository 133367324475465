import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnnouncemetResponse, AnnouncemetsResponse } from './interfaces/announcemet.response';

@Injectable({
  providedIn: 'root'
})
export class AnnouncemetHttpService {
  private readonly path = environment.API_AGUAS_MACHALA;

  constructor(private readonly http: HttpClient) { }


  public getAll(): Observable<AnnouncemetsResponse>{
    const path: string = `${this.path}/api/v1/convocatoria`;
    return this.http.get<AnnouncemetsResponse>(path);
  }

  public getId(id: number): Observable<AnnouncemetResponse> {
    const path: string = `${this.path}/api/v1/convocatoria/get_by_id/${id}`;
    return this.http.get<AnnouncemetResponse>(path);
  }

}
