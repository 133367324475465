<ngx-spinner></ngx-spinner>
<style>
    .cat-list {
        display: flex;
        justify-content: between;
    }
</style>
<div class="site-main single">
    <div *ngIf="noticias">
        <div class="sidebar ttm-sidebar-right ttm-bgcolor-white break-991-colum clearfix">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 content-area">
                        <article class="post ttm-blog-classic">
                            <div class="post-featured-wrapper">
                                <owl-carousel-o [options]="customImgsNew">
                                    <ng-container *ngFor="let url of urls_imagenes">
                                        <ng-template carouselSlide>
                                            <img loading="lazy" [src]="url.url" class="img-fluid">
                                        </ng-template>
                                    </ng-container>
                                </owl-carousel-o>
                                <div class="ttm-box-post-date">
                                    <span class="ttm-entry-date">
                                        <time class="entry-date" datetime="2019-01-16T07:07:55+00:00">{{diaS}}<span
                                                class="entry-month entry-year">{{mesS}}</span></time>
                                    </span>
                                </div>
                            </div>
                            <div class="ttm-blog-classic-box-content">
                                <div class="entry-content">
                                    <div class="ttm-entry-meta-wrapper">
                                        <div class="ttm-entry-meta">
                                            <span class="ttm-meta-line byline">
                                                <img loading="lazy"
                                                    src="/assets/images/optimizadas_machalaep/favicon.webp" alt=""
                                                    class="avatar">
                                                <span class="author"><a
                                                        style="font-weight: 800;text-decoration: underline;">Dept.
                                                        Comunicación</a></span>
                                            </span>
                                            <span class="ttm-meta-line"><i class="fa fa-calendar"></i>
                                                {{noticias.fecha}}</span>
                                        </div>
                                    </div>
                                    <div class="ttm-box-desc-text">
                                        <h4>{{noticias.titulo}}</h4>
                                        <p><strong>{{noticias.subtitulo}}</strong></p>
                                        <div class="ttm-box-view-left-image">
                                            <div class="featured-content ttm-box-desc">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="cat-list">
                                                            <ng-container class="cat" *ngFor="let categoria of categorias">

                                                                <span class="category">
                                                                    <a style="pointer-events: none;">
                                                                        {{categoria.nombre}}
                                                                    </a>
                                                                    <span>&nbsp;</span>
                                                                </span>
    
                                                            </ng-container>
                                                        </div>
                                                        

                                                    </div>
                                                    <div class="col-5"></div>
                                                    <div class="col-3"></div>
                                                </div>

                                            </div>
                                        </div>
                                        <p [innerHTML]="noticias.resumen"></p>
                                        <div *ngFor="let url of urls_videos">
                                            <a href="{{url.url}}" target="_blank"
                                                style="font-weight: bold; text-decoration-line: underline;">Ver video
                                                informativo</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="ttm-row services2-section bg-img4 clearfix pb-130">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="pr-10 res-991-plr-0">
                    <div class="section-title clearfix">
                        <div class="title-header">
                            <h2 class="title">Otras noticias</h2>
                        </div>
                    </div>
                    <app-slidenews></app-slidenews>
                </div>
            </div>
        </div>
    </div>
</section>