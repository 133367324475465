import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Logro } from '../../model/logro/logro.model';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LogroService {

  constructor(private httpClient: HttpClient) { }
  public getAllLogros(): Observable<Logro[]> {
    // var api_uri = environment.API_URL.toString() + `get_logros`;
    var api_uri = `https://www.aguasmachala.com:3000/api/v1/logros`;
    // var api_uri = `/api/v1/logros`;
    return this.httpClient.get<Logro[]>(api_uri).pipe(
      map(data =>
        data.map(data => new Logro().deserialize(data))
      )
    );
  }
}
