import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './view/header/header.component';
import { FooterComponent } from './view/footer/footer.component';
import { HomeComponent } from './view/home/home.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { Page404Component } from './view/page404/page404.component';
import { NewsComponent } from './view/news/news.component';
import { SingleNewsComponent } from './view/single-news/single-news.component';
import { ServicesComponent } from './view/services/services.component';
import { SingleServiceComponent } from './view/single-service/single-service.component';
import { AboutUsComponent } from './view/about-us/about-us.component';
import { ContactComponent } from './view/contact/contact.component';
import { TransparencyComponent } from './view/transparency/transparency.component';
import { BiddingComponent } from './view/bidding/bidding.component';
import { ReclamoComponent } from './view/reclamo/reclamo.component';
import { ConsultDebtsComponent } from './view/consult-debts/consult-debts.component';
import { ConsultClaimsComponent } from './view/consult-claims/consult-claims.component';
import { DenunciaciudadComponent } from './view/denunciaciudad/denunciaciudad.component';
import { SliderservicesComponent } from './view/sliderservices/sliderservices.component';
import { SlideepaliadaComponent } from './view/slideepaliada/slideepaliada.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SlidenewsComponent } from './view/slidenews/slidenews.component';
import { NgxSpinnerModule } from 'ngx-spinner';
// import { CounterModule } from 'ngx-counter';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SlidertramitesComponent } from './view/slidertramites/slidertramites.component';
import { ViviendaComponent } from './view/vivienda/vivienda.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { WelcomeComponent } from './view/welcome/welcome.component';

import { PaymentButtonComponent } from './view/payment-button/payment-button.component';
import { PaymentComponent } from './view/payment/payment.component';
import { PostPaymentComponent } from './view/post-payment/post-payment.component';

import { TermsComponent } from './view/information/terms/terms.component';
import { PrivacyComponent } from './view/information/privacy/privacy.component';
import { AnnouncemetComponent } from './view/announcemet/announcemet.component';
import { TableInfoComponent } from './view/announcemet/table-info/table-info.component';
// import {ngCountTo} from '';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    Page404Component,
    NewsComponent,
    SingleNewsComponent,
    ServicesComponent,
    SingleServiceComponent,
    AboutUsComponent,
    ContactComponent,
    TransparencyComponent,
    BiddingComponent,
    ReclamoComponent,
    ConsultDebtsComponent,
    ConsultClaimsComponent,
    DenunciaciudadComponent,
    SliderservicesComponent,
    SlideepaliadaComponent,
    SlidenewsComponent,
    SlidertramitesComponent,
    ViviendaComponent,
    WelcomeComponent,
    PaymentButtonComponent,
    PaymentComponent,
    PostPaymentComponent,
    TermsComponent,
    PrivacyComponent,
    AnnouncemetComponent,
    TableInfoComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CarouselModule,
    NgxPaginationModule,
    LeafletModule,
    NgxSpinnerModule,
    ScrollToModule.forRoot(),
    // CounterModule.forRoot(),
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
