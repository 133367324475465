import { Transaction } from './../../model/transaction';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Denuncia } from 'src/app/controller/denuncia-externa.inteface';

import { ReclamoDomicilio } from '../../controller/reclamo-domicilio.interface';

@Injectable({
  providedIn: 'root',
})
export class MachalaEpService {
  BASE_URL = 'https://www.aguasmachala.com:3000/api/v1';
  httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };

  colors = [];

  constructor(private http: HttpClient) {}

  concatURL(subURL: string): string {
    return this.BASE_URL + subURL;
  }

  makePostRequest(
    URL: string,
    data: any,
    headers = this.httpOptions
  ): Observable<any> {
    return this.http.post(URL, data, headers);
  }

  makeGetRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.http.get(URL, headers);
  }

  makeDelRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.http.delete(URL, headers);
  }

  makePutRequest(
    URL: string,
    data: any,
    headers = this.httpOptions
  ): Observable<any> {
    return this.http.put(URL, data, headers);
  }

  guardarReclamoDomicilio(reclamo: ReclamoDomicilio): Observable<any> {
    const PATH = this.concatURL('/reclamos');

    return this.makePostRequest(PATH, reclamo);
  }

  guardarDenuncia(reclamo: Denuncia): Observable<any> {
    const PATH = this.concatURL('/reclamos');

    return this.makePostRequest(PATH, reclamo);
  }

  obtenerUltimaNoticia() {
    const PATH = this.concatURL('/noticias?offset=0&limit=1');

    return this.makeGetRequest(PATH);
  }

  obtenerRendiciondeCuentas(): Observable<any> {
    const PATH = this.concatURL('/rendicionCuentas');
    return this.makeGetRequest(PATH);
  }

  verificarSiExiteUsuario(tipoId: string, id: string): Observable<any> {
    const PATH = this.concatURL(`/oracle/clientes?tipoid=${tipoId}&id=${id}`);
    return this.makeGetRequest(PATH);
  }

  obtenerNoticias(): Observable<any> {
    const PATH = this.concatURL('/noticias');
    return this.makeGetRequest(PATH);
  }

  getIpUser(): Observable<any> {
    const PATH = "https://api.ipify.org/?format=json";
    return this.makeGetRequest(PATH);
  }

  createTransaction(transaction: Transaction): Observable<any> {
    const PATH = this.concatURL('/transaccion');
    return this.makePostRequest(PATH, transaction)
  }

  updateTransaction(id: string, transaction: any): Promise<any> {
    const path = this.concatURL('/transaccion/'+id);
    return this.makePutRequest(path, transaction).toPromise();
  }
}
